import React from 'react';
import { RestaurantContext } from '../../../context/RestaurantProvider';
import { RestaurantTab } from '../../../lib';
import CommandButton from './CommandButton';
import ListItem from './ListItem';

export default function TabBar() {
  const { setActiveTab } = React.useContext(RestaurantContext);

  return (
    <div role="tablist" className="tabs tabs-lifted ">
      {RestaurantTab.map((tab) => (
        <React.Fragment key={tab}>
          <input
            type="radio"
            name="restaurant_tabs"
            role="tab"
            className="tab"
            aria-label={tab}
            defaultChecked={tab === 'Table'}
            onClick={() => setActiveTab(tab)}
          />
          <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
            <div className="text-center mt-5 font-bold text-xl flex flex-col gap-4">
              <CommandButton />
              <ListItem />
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
