import PropTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';
import { AuthenticatedFetch } from '../lib';

export const CustomerContext = createContext({});

export function CustomerProvider({ children }) {
  const [tables, setTables] = React.useState([]);
  const [waitingLists, setWaitingLists] = React.useState([]);
  const [waitingListModal, setWaitingListModal] = React.useState(null);
  const [tableModal, setTableModal] = React.useState(null);
  const [deleteWaitingListModal, setDeleteWaitingListModal] = React.useState(null);
  const [waitingListBoxPicked, setWaitingListBoxPicked] = React.useState(null);
  const [orderModal, setOrderModal] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const [tablesResponse, waitingListsResponse] = await Promise.all([
        AuthenticatedFetch(`table`),
        AuthenticatedFetch(`waiting-list?date=${new Date()}`)
      ]);

      const [tablesData, waitingListsData] = await Promise.all([
        tablesResponse.json(),
        waitingListsResponse.json()
      ]);
      
      setTables(tablesData);
      setWaitingLists(waitingListsData.slice().sort((a, b) => a.id - b.id));
    }
    
    fetchData();
    const interval = setInterval(fetchData, 1000 * (process.env.REACT_APP_CUSTOMER_INTERVAL_DURATION_IN_SECOND || 5));
    return () => clearInterval(interval);
  }, []);

  return (
    <CustomerContext.Provider
      value={{
        tables,
        waitingLists,
        setTables,
        setWaitingLists,
        waitingListModal,
        setWaitingListModal,
        tableModal,
        setTableModal,
        deleteWaitingListModal,
        setDeleteWaitingListModal,
        waitingListBoxPicked,
        setWaitingListBoxPicked,
        orderModal, 
        setOrderModal
      }}>
      {children}
    </CustomerContext.Provider>
  );
}

CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired
};
