import React from 'react';
import { RestaurantContext } from '../../../context/RestaurantProvider';
import { AuthContext } from '../../../context/AuthProvider';
import { scopesEnum } from '../../../lib';
import { triggerToast } from '../../../utils';

export default function CommandButton() {
  const { activeTab, setDeleteModal, setUpdateModal } = React.useContext(RestaurantContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  function decideScopesEnum() {
    if (activeTab === 'Table') {
      return scopesEnum.CREATE_TABLE;
    } else if (activeTab === 'Lokasi Printer') {
      return scopesEnum.CREATE_KITCHEN;
    } else {
      return scopesEnum.CREATE_PAYMENT_METHOD;
    }
  }

  return (
    <button
      className="btn flex ml-auto w-fit font-bold text-xs sm:text-sm md:text-base"
      onClick={() => {
        if (!decideIfRoleHasAccess(decideScopesEnum())) {
          triggerToast('error', 'You are not allowed to access this functionality');
          return;
        }

        setDeleteModal({
          show: false,
          name: '',
          link: ''
        });
        setUpdateModal({
          name: '',
          printerLocationId: 0,
          capacity: 0,
          update: false
        });
        document.getElementById('modal-restaurant').showModal();
      }}>
      Tambah {activeTab}
    </button>
  );
}
