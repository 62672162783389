import React, { useContext, useEffect, useState } from 'react';
import { OperationContext } from '../../context/OperationContext';
import { SelectedTableContext } from '../../pages/admin/operation/OperationPage';
import { triggerToast } from '../../utils/toast';
import { AuthenticatedFetch } from '../../lib';

export default function MoveTableModal() {
    const { tables, setTables } = useContext(OperationContext);
    const { selectedTable, setSelectedTable } = useContext(SelectedTableContext);
    const [selectedPersonId, setSelectedPersonId] = useState(selectedTable?.Order?.[0]?.id);
    const [secondSelectedTable, setSecondSelectedTable] = useState({});

    useEffect(() => {
        setSelectedPersonId(selectedTable?.Order?.[0]?.id);
    }, [selectedTable]);

    return (
        <dialog id="move_table_modal" className="modal">
            <div className="modal-box form-control">
                <h3 className="font-bold text-lg">Pindah Meja</h3>
                <div className='my-4'>
                    <label htmlFor="table1">Table: <span>{selectedTable?.tableNumber}</span></label>
                </div>
                <div>
                    <label htmlFor="person">Order : </label>
                    <select onChange={(e) => {
                        setSelectedPersonId(e.target.value);
                    }} className='' name="person" id="person">
                        <option value="">Pilih Pelanggan</option>
                        {(selectedTable ? selectedTable.Order : []).map((order, idx) => (
                            <option selected={idx == 0} key={idx} value={order.id}>{order.customerName} - {order.id}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="table2">Table: </label>
                    <select onChange={(e) => {
                        const table = tables.find(table => table.tableNumber === +e.target.value);
                        console.log(table);
                        setSecondSelectedTable(table);
                    }} className='' name="table2" id="table2">
                        <option value="">Pilih Meja</option>
                        {(!selectedTable ? tables : tables.filter(t => t.tableNumber !== selectedTable.tableNumber)).map((table, idx) => (
                            <option key={idx} value={table.tableNumber}>
                                Meja {table.tableNumber}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="modal-action">
                    <button onClick={async () => {
                        if (!selectedPersonId || !secondSelectedTable?.tableNumber) {
                            triggerToast("error", "Please select a person and a table");
                            return;
                        }
                        if (
                            secondSelectedTable
                                ?.Order
                                ?.reduce((acc, order) => acc + order?.numberOfPeople, 0) && 0
                            > secondSelectedTable.paxs) {
                            triggerToast("error", "Table is couldn't accomodate that many people");
                        }
                        try {
                            await AuthenticatedFetch(`order/${selectedPersonId}/move`, {
                                method: "PATCH",
                                body: JSON.stringify({
                                    tableNumber: secondSelectedTable.tableNumber,
                                }),
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            })
                            setTables(tables.map(table => {
                                if (table.id === secondSelectedTable.id) {
                                    table.Order = [...table.Order, selectedTable.Order.find(order => order.id === selectedPersonId)];
                                }
                                if (table.id === selectedTable.id) {
                                    table.Order = table.Order.filter(order => order.id !== selectedPersonId);
                                }
                                return table;
                            }));
                            triggerToast("success", "Table moved successfully");
                            setSelectedTable(null);
                            setSelectedPersonId("");
                            document.getElementById("move_table_modal").close();
                        } catch {
                            triggerToast("error", "Failed to move table");
                        }
                    }} className='btn btn-primary'>Submit</button>
                    <form method="dialog">
                        <button className="btn">Close</button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}
