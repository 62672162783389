import React from 'react';
import { CashierContext } from '../../../context/CashierProvider';

export default function OrderInfo() {
  const { selectedOrder, menus } = React.useContext(CashierContext);

  function getMenuName(menuId) {
    const menu = menus.find((menu) => menu.id == menuId);

    return menu.name;
  }

  function getAddonOptionName(addonOptionId) {
    const addonOption = menus.reduce((result, menu) => {
      const foundOption = menu.addons.reduce((res, addon) => {
        return res || addon.options.find((option) => option.id === addonOptionId);
      }, null);

      return result || foundOption;
    }, null);

    return addonOption?.name;
  }

  return (
    <div style={{height:'45vh', overflow:'auto'}}>
      {selectedOrder &&
        selectedOrder.orderItems.length > 0 &&
        selectedOrder.orderItems.map((orderItem) => (
          <React.Fragment key={`order-items-${orderItem.id}`}>
            <div
              className="menu flex flex-row gap-2 justify-start md:-ml-2 -ml-7 py-0"
              key={`order-items-${orderItem.id}`}>
              <li className="flex flex-col text-[1rem]">
                <p className="font-semibold" onClick={() => {}}>
                  {getMenuName(orderItem.menuId)}
                </p>
                <ul>
                  {orderItem.OrderAddon.map((orderAddon) => (
                    <li key={`order-addon-${orderAddon.addonId}-${orderAddon.optionId}`}>
                      {getAddonOptionName(orderAddon.optionId)}
                    </li>
                  ))}
                </ul>
                <ul>
                  <p className="-ml-2">{orderItem.type == 'DINE_IN' ? 'Dine In' : 'Take Away'}</p>
                </ul>
                {orderItem && <ul><p className="-ml-2">{orderItem.notes}</p></ul>}
              </li>
              <div className="flex flex-row ml-auto px-4 py-2 font-semibold text-[1rem]">
                <p className="mr-2">x{orderItem.quantity}</p>
                <p>Rp. {orderItem.price}</p>
              </div>
            </div>
            <div className="flex w-full justify-center py-2">
              <div className="divider m-0 text-center w-2/5"></div>
            </div>
          </React.Fragment>
        ))}
    </div>
  );
}
