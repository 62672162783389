import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import { AuthenticatedFetch } from '../lib';

export const DashboardContext = createContext({});

export function DashboardProvider({ children }) {
  const [orders, setOrders] = React.useState([]);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [payments, setPayments] = React.useState([]);
  const [selectedStartDate, setSelectedStartDate] = React.useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = React.useState(undefined);
  const [todayOrders, setTodayOrders] = React.useState([]);

  function getDate() {
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
  }

  useEffect(() => {
    async function fetchData() {
      const today = getDate();
      const [todayOrdersResponse, ordersResponse, paymentsResponse] = await Promise.all([
        AuthenticatedFetch(`order/?startDate=${today}&endDate=${today}&status=${'PAID'}`),
        AuthenticatedFetch(`order/?startDate=${today}&endDate=${today}&status=${'PAID'}`),
        AuthenticatedFetch(`payment`)
      ]);

      const [todayOrdersData, ordersData, paymentsData] = await Promise.all([
        todayOrdersResponse.json(),
        ordersResponse.json(),
        paymentsResponse.json()
      ]);

      console.log(todayOrdersData);
      setTodayOrders(todayOrdersData);
      setOrders(ordersData);
      setSelectedOrder(ordersData.length > 0 ? ordersData[0] : null);
      setPayments(paymentsData);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedStartDate == undefined && selectedEndDate == undefined) return;

    async function fetchData() {
      const res = await AuthenticatedFetch(
        `order/placed?startDate=${selectedStartDate}&endDate=${selectedEndDate}&status=${'PAID'}`
      );
      const data = await res.json();

      setOrders(data);
    }

    fetchData();
  }, [selectedStartDate, selectedEndDate]);

  const ordersUpdated = useMemo(() => {
    return orders.map((order) => {
      const totalPrice = order.orderItems.reduce((acc, curr) => {
        return acc + curr.price;
      }, 0);

      return { ...order, totalPrice };
    });
  }, [orders]);

  return (
    <DashboardContext.Provider
      value={{
        orders: ordersUpdated,
        setOrders,
        selectedOrder,
        setSelectedOrder,
        selectedStartDate,
        selectedEndDate,
        setSelectedStartDate,
        setSelectedEndDate,
        todayOrders,
        payments
      }}>
      {children}
    </DashboardContext.Provider>
  );
}

DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired
};
