import React, { useContext, useState } from 'react';
import './Navbar.css';
import { CiMenuBurger } from 'react-icons/ci';
import { AuthContext } from '../../context/AuthProvider';
import { RemoveCookieToken, scopesEnum } from '../../lib';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { isLoggedIn, setJwtScopes, setUserId, decideIfRoleHasAccess } = useContext(AuthContext);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
        <nav className={`navbars`}>
          <div className="burger-icon" onClick={toggleNavbar} style={{ margin: 10 }}>
            <CiMenuBurger color="white" />
          </div>
          <ul className={`nav-links ${isOpen ? 'show' : 'hide'}`}>
            <div style={{ margin: 10 }}></div>
            {!isLoggedIn && (
              <>
                <li>
                  <a href="/login">Login</a>
                </li>
              </>
            )}
            {isLoggedIn && (
              <>
                {decideIfRoleHasAccess(scopesEnum.CASHIER) && (
                  <li>
                    <a href="/cashier">Cashier & Order</a>
                  </li>
                )}
                {decideIfRoleHasAccess(scopesEnum.CUSTOMER) && (
                  <li>
                    <a href="/customer">Customer</a>
                  </li>
                )}
                {
                  decideIfRoleHasAccess(scopesEnum.OPERATION) && (
                    <li>
                      <a href="/operation">Operation</a>
                    </li>
                  )
                }
                {decideIfRoleHasAccess(scopesEnum.RESTAURANT_MANAGEMENT) && (
                  <li>
                    <a href="/restaurant-management">Restaurant Management</a>
                  </li>
                )}
                {decideIfRoleHasAccess(scopesEnum.MENU_MANAGEMENT) && (
                  <li>
                    <a href="/menu">Menu Management</a>
                  </li>
                )}
                {decideIfRoleHasAccess(scopesEnum.VIEW_REPORT) && (
                  <li>
                    <a href="/dashboard">Dashboard</a>
                  </li>
                )}
                {decideIfRoleHasAccess(scopesEnum.ACCESS_CONTROL_LIST) && (
                  <li>
                    <a href="/acl">ACL</a>
                  </li>
                )}
                <li>
                  <a href="/printer">Printer</a>
                </li>
                <li>
                  <a
                    href="/login"
                    onClick={() => {
                      setUserId(null);
                      setJwtScopes([]);
                      RemoveCookieToken();
                    }}>
                    Logout
                  </a>
                </li>
              </>
            )}
          </ul>
        </nav>
    </>
  );
};

export default Navbar;
