import React, { useContext } from "react";
import { FaPenAlt, FaTrashAlt } from "react-icons/fa";
import { UpdateMenuContext } from "../../contexts/MenuContext";
import { AuthContext } from "../../context/AuthProvider";
import { scopesEnum } from "../../lib";
import { triggerToast } from "../../utils/toast";

export default function CategoryItem({ category }) {
    const { setCategory } = useContext(UpdateMenuContext);
    const { decideIfRoleHasAccess } = React.useContext(AuthContext);

    return (
        <tr key={category.id}>
            <td>
                <div className="font-bold">{category.name}</div>
            </td>
            <td>
                <div className="font-bold text-center">{category.rank}</div>
            </td>
            <td>
                <button
                    disabled={!decideIfRoleHasAccess(scopesEnum.UPDATE_CATEGORY)}
                    onClick={() => {
                        if (!decideIfRoleHasAccess(scopesEnum.UPDATE_CATEGORY)) {
                            triggerToast('error', 'You are not allowed to update category!');
                            return;
                        }
                        setCategory(category);
                        document.getElementById(`category_update_modal`).showModal();
                    }} className="btn btn-warning btn-sm">
                    <FaPenAlt className="text-white" />
                </button>
            </td>
            <td>
                <button
                    disabled={!decideIfRoleHasAccess(scopesEnum.DELETE_CATEGORY)}
                    onClick={() => {
                        if (!decideIfRoleHasAccess(scopesEnum.DELETE_CATEGORY)) {
                            triggerToast('error', 'You are not allowed to delete category!');
                            return;
                        }
                        setCategory(category);
                        document.getElementById(`category_delete_modal`).showModal();
                    }} className="btn btn-error btn-sm">
                    <FaTrashAlt className="text-white" />
                </button>
            </td>
        </tr>
    )
}