import React, { useContext } from 'react';
import { MenuContext, UpdateMenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { handleInputChange } from '../../utils/handleInputChange';
import { triggerToast } from '../../utils/toast';
import { AuthContext } from '../../context/AuthProvider';
import { FaTimes } from "react-icons/fa";

export default function CategoryUpdateModal() {
  const { categories, setCategories } = useContext(MenuContext);
  const { category, setCategory } = useContext(UpdateMenuContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return (
    <dialog id="category_update_modal" className="modal">
      <div className="modal-box">
        <div className="modal-action mt-0">
          <form method="dialog">
            <button className="btn"><FaTimes /></button>
          </form>
        </div>
        <h3 className="font-bold text-lg">Update {category.name} Category</h3>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_CATEGORY)) {
              triggerToast('error', 'You are not allowed to update category!');
              return;
            }
            
            const res = await AuthenticatedFetch(`categories/${category.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                name: category.name,
                rank: parseInt(category.rank)
              })
            });
            if (!res.ok) {
              const data = await res.text();
              triggerToast('error', data);
              return;
            }
            const data = await res.json();
            setCategories(
              categories.map((category) => {
                if (category.id === data.id) {
                  return data;
                }
                return category;
              })
            );
            setCategory({
              name: ''
            });
            document.getElementById('category_update_modal').close();
          }}
          className="form-control m-3">
          <label className="label-text" htmlFor="name">
            Name
          </label>
          <input
            value={category.name}
            onChange={(e) => handleInputChange(e, setCategory)}
            className="mb-10 input input-bordered"
            type="text"
            name="name"
            id="name"
          />
          <input
            value={category.rank}
            onChange={(e) => handleInputChange(e, setCategory)}
            className="mb-10 input input-bordered"
            type="number"
            name="rank"
            id="rank"
          />
          <button className="btn btn-primary">Update</button>
        </form>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn">Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
}
