import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

export default function BillButton({ onClick, label, color, isHalved, className, disabled, isLoading }) {
  return (
    <button
      className={`btn ${
        isHalved ? 'w-1/2' : 'w-full'
      } bg-${color}-200 bg-opacity-80 border-2 border-${color}-500 ${className ? className : ''}`}
      type="button"
      disabled={disabled}  
      onClick={onClick}>
        {isLoading && <CircularProgress/>}
      {label}
    </button>
  );
}

BillButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isHalved: PropTypes.bool,
  className: PropTypes.string
};
