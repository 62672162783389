import React from 'react';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import OrderInfo from './OrderInfo';
import { triggerToast } from '../../../../utils';
import { AuthenticatedFetch, scopesEnum } from '../../../../lib';
import { AuthContext } from '../../../../context/AuthProvider';
import { CircularProgress } from '@mui/material';
import { closeCurrentTab } from '../../../../utils/windowManagement';

export default function InfoBar() {
  const { order, menus, orderItemToBeUsed, orderItems, tempOrder } = React.useContext(CashierOrderContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const { subtotals, tax, total } = React.useMemo(() => {
    if (!order) return {};

    const subtotals = Math.round(
      parseFloat(
        orderItemToBeUsed.reduce((result, orderItem) => {
          const subtotal = orderItem.price;
          return result + subtotal;
        }, 0) / 1.1
      )
    );

    const tax = Math.round(parseFloat(subtotals * 0.1));

    return {
      subtotals: subtotals,
      tax: tax,
      total: subtotals + tax
    };
  }, [order, orderItemToBeUsed]);

  async function handleAddOrderItems() {
    if (tempOrder.customerName == null) {
      triggerToast('error', 'Customer Name harus diisi');
      return;
    }

    if (tempOrder.type == "DINE_IN" && tempOrder.tableNumber == null) {
      triggerToast('error', 'Table Number harus diisi');
      return;
    }

    if (tempOrder.type == "ONLINE" && (tempOrder.description == null || tempOrder.description == "")) {
      triggerToast('error', 'Description harus diisi');
      return;
    }

    if (!decideIfRoleHasAccess(scopesEnum.PLACED_CONFIRM_ORDER)) {
      triggerToast('error', 'You are not allowed to place order!');
      return;
    }

    try {
      setIsLoading(true);
      const orderItemsWithoutTempAndId = orderItems.map((orderItem) => {
        // eslint-disable-next-line no-unused-vars
        const { addons, id: _, temp: __, ...rest } = orderItem;
        // eslint-disable-next-line no-unused-vars
        const addonsWithoutPrice = addons.map((addon) => {
          // eslint-disable-next-line no-unused-vars
          const { price: __, ...restAddon } = addon;

          return restAddon;
        });

        return {
          ...rest,
          addons: addonsWithoutPrice
        };
      });

      const orderRes = await AuthenticatedFetch(`order/${order.id}/order-item`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderItems: orderItemsWithoutTempAndId
        })
      });

      let success = orderRes.status < 400;

      if (!success) {
        triggerToast('error', 'Failed to add order items');
        return;
      }

      triggerToast('success', 'Order placed successfuly');

      closeCurrentTab();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {order.orderItems && menus && (
        <div className="min-h-full lg:w-1/3 border-2 flex flex-col justify-between items-center rounded-xl w-full">
          <div className="flex flex-col justify-center w-full">
            <div className="flex flex-col gap-2 mt-2 lg:p-0 p-4">
              <h4 className="font-semibold text-center">
                {order.customerName} #{order.id}
              </h4>
              <div className="flex flex-col px-2">
                <h4 className="text-center">Daftar Pesanan</h4>
                <OrderInfo />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full gap-3 mb-2 px-2 md:px-4">
            <div className="flex flex-col gap-1 w-full px-4 py-2 font-semibold text-sm">
              <div className="flex flex-row justify-between">
                <p>Subtotal</p>
                <p className="mr-5 md:mr-2 lg:mr-0 text-right">
                  Rp.{subtotals.toLocaleString('de-DE')}
                </p>
              </div>
              <div className="flex flex-row justify-between">
                <p>Pajak</p>
                <p className="mr-5 md:mr-2 lg:mr-0 text-right">Rp.{tax.toLocaleString('de-DE')}</p>
              </div>
              <div className="flex flex-row justify-between">
                <p>Total</p>
                <p className="mr-5 md:mr-2 lg:mr-0 text-right">
                  Rp.{total.toLocaleString('de-DE')}
                </p>
              </div>
            </div>
            {
              <button
                className={`button-with-loading w-full text-center border-t-2 mt-2 rounded-xl font-semibold lg:p-1 p-2 ${orderItems.length > 0 ? 'bg-green-200' : 'bg-gray-200'
                  }`}
                disabled={isLoading || orderItems.length == 0}
                onClick={async () => {
                  await handleAddOrderItems();
                }}>
                {isLoading && <CircularProgress />}
                Tambah Pesanan
              </button>
            }
          </div>
        </div>
      )}
    </>
  );
}
