import React from 'react';
import { MdClose } from 'react-icons/md';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import { triggerToast } from '../../../../utils';
import { AuthenticatedFetch } from '../../../../lib';

export default function ConfirmationModal() {
  const { order, setOrder, orderItemToBeUpdated, setOrderItems } =
    React.useContext(CashierOrderContext);

  return (
    <dialog id="confirmation-modal" className="modal">
      <div className="modal-box">
        <MdClose
          className="float-right cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
          onClick={() => {
            document.getElementById('confirmation-modal').close();
          }}
        />
        <h4 className="font-semibold text-center">Hapus Order Item</h4>
        <div className="divider"></div>
        <div className="modal-action">
          <button
            className="btn"
            type="button"
            onClick={async () => {
              if (!order || !orderItemToBeUpdated) return;

              document.getElementById('confirmation-modal').close();
              document.getElementById('modal-order').close();

              if (orderItemToBeUpdated.temp) {
                setOrderItems((prev) => prev.filter((item) => item.id != orderItemToBeUpdated.id));

                return;
              }

              await AuthenticatedFetch(`order/${order.id}/order-item/${orderItemToBeUpdated.id}`, {
                method: 'DELETE'
              });

              setOrder((prev) => ({
                ...prev,
                orderItems: prev.orderItems.filter((item) => item.id != orderItemToBeUpdated.id)
              }));

              triggerToast('success', 'Order item successfully deleted');
            }}>
            Hapus
          </button>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button></button>
      </form>
    </dialog>
  );
}
