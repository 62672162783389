import React from 'react';
import { MdClose } from 'react-icons/md';
import { triggerToast } from '../../../utils';
import { CashierContext } from '../../../context/CashierProvider';
import BillGroup from './BillGroup';
import { AuthenticatedFetch } from '../../../lib';

export default function ConfirmationModal() {
  const { selectedOrder, setSelectedOrder, setOrders, isOrderToBeDeleted } =
    React.useContext(CashierContext);

  return (
    <dialog id="confirmation-modal" className="modal">
      <div className="modal-box">
        <MdClose
          className="float-right cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
          onClick={() => {
            document.getElementById('confirmation-modal').close();
          }}
        />
        {selectedOrder && (
          <>
            <h4 className="font-semibold text-center">
              {!isOrderToBeDeleted
                ? `Bayar Bill ${selectedOrder.id}`
                : `Hapus Order ${selectedOrder.id}`}
            </h4>
            <div className="divider"></div>
            {isOrderToBeDeleted && (
              <div className="modal-action">
                <button
                  className="btn"
                  type="button"
                  onClick={async () => {
                    const res = await AuthenticatedFetch(`order/${selectedOrder.id}`, {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json'
                      }
                    });

                    const success = res.status < 400;
                    const message = success ? 'Berhasil menghapus pesanan' : await res.text();

                    triggerToast(success ? 'success' : 'error', message);
                    if (!success) return;

                    setSelectedOrder(null);
                    setOrders((orders) => orders.filter((o) => o.id !== selectedOrder.id));
                    document.getElementById('confirmation-modal').close();
                  }}>
                  Hapus
                </button>
              </div>
            )}
            {!isOrderToBeDeleted && <BillGroup />}
          </>
        )}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button></button>
      </form>
    </dialog>
  );
}
