import React from 'react';
import { MdClose } from 'react-icons/md';
import { ACLContext } from '../../../context/ACLProvider';
import { triggerToast } from '../../../utils';
import { AuthenticatedFetch } from '../../../lib';
import { useForm } from 'react-hook-form';

export default function ModalBox() {
  const {
    activeTab,
    userToBeUpdated,
    setUserToBeUpdated,
    roleToBeUpdated,
    setRoleToBeUpdated,
    setUsers,
    setRoles,
    isDeleteModalOpen
  } = React.useContext(ACLContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  async function del() {
    const res = await AuthenticatedFetch(
      `${process.env.REACT_APP_API_URL}${
        activeTab == 'User' ? `users/${userToBeUpdated.id}` : `role/${roleToBeUpdated.id}`
      }`,
      {
        method: 'DELETE'
      }
    );

    const data = await res.json();
    if (res.status == 200) {
      if (activeTab == 'User') {
        setUsers((prev) => prev.filter((user) => user.id != data.id));
        setUserToBeUpdated(null);
      } else {
        setRoles((prev) => prev.filter((role) => role.id != data.id));
        setRoleToBeUpdated(null);
      }
      triggerToast('success', `${activeTab} deleted successfully!`);

      document.querySelector('#modal-acl').close();
    }
  }

  async function updatePassword(data) {
    const res = await AuthenticatedFetch(`users/${userToBeUpdated.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: data.password
      })
    });

    if (res.status < 400) {
      triggerToast('success', `Password updated successfully!`);
      document.querySelector('#modal-acl').close();
    } else {
      triggerToast('error', `Failed to update password!`);
    }
  }

  return (
    <dialog id="modal-acl" className="modal">
      <div className="modal-box">
        <MdClose
          className="float-right cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
          onClick={() => {
            document.querySelector('#modal-acl').close();
          }}
        />
        <h3 className="font-bold text-lg text-center">
          {isDeleteModalOpen ? 'Hapus' : 'Change Password'} {activeTab}{' '}
          {activeTab == 'User' ? userToBeUpdated?.username : roleToBeUpdated?.name}?
        </h3>
        {isDeleteModalOpen == false && (
          <form onSubmit={handleSubmit(updatePassword)}>
            <label className="form-control w-full">
              <div className="label w-fit">
                <span className="label-text font-bold">Password</span>
              </div>
              <input
                type="password"
                placeholder="Enter Password"
                className="input input-bordered w-full font-semibold"
                {...register('password', {
                  required: true
                })}
              />
            </label>
            <div className="label">
              <span className="label-text-alt text-red-500 font-semibold">
                {' '}
                {errors.password && 'Password is required!'}
              </span>
            </div>
            <label className="form-control w-full">
              <div className="label w-fit">
                <span className="label-text font-bold">Confirm Password</span>
              </div>
              <input
                type="password"
                placeholder="Enter Confirm Password"
                className="input input-bordered w-full font-semibold"
                {...register('confirmPassword', {
                  required: true,
                  validate: {
                    isSame: (value) => {
                      if (value !== watch('password')) {
                        return 'Password does not match';
                      }
                    }
                  }
                })}
              />
            </label>
            <div className="label">
              <span className="label-text-alt text-red-500 font-semibold">
                {' '}
                {errors.confirmPassword && errors.confirmPassword.message}
              </span>
            </div>
            <div className="modal-action">
              <button className="btn" type="submit">
                Change Password
              </button>
            </div>
          </form>
        )}
        {isDeleteModalOpen && (
          <div className="modal-action">
            <button
              className="btn"
              type="button"
              onClick={async () => {
                if (isDeleteModalOpen == true) {
                  await del();
                }
              }}>
              {isDeleteModalOpen ? 'Hapus' : 'Change Password'}
            </button>
          </div>
        )}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button></button>
      </form>
    </dialog>
  );
}
