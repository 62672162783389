import React from 'react';
import CommandButton from './CommandButton';
import WaitingListBox from './WaitingListBox';
import InformationTable from './InformationTable';
import { CustomerProvider } from '../../../context/CustomerProvider';
import ConfirmationModal from './ConfirmationModal';

export default function CustomerPage() {
  return (
    <CustomerProvider>
      <div className="flex min-h-screen min-w-full max-w-full justify-center items-center flex-col px-2 py-4">
        <div className="card w-[90%] bg-base-100 shadow-xl">
          <div className="card-body md:gap-8 sm:gap-6 gap-4 justify-between flex lg:flex-row flex-col w-full items-stretch">
            <div className="flex flex-1 flex-col md:gap-4 gap-2 overflow-auto">
              <CommandButton />
              <WaitingListBox />
              <InformationTable />
            </div>
          </div>
        </div>
        <ConfirmationModal />
      </div>
    </CustomerProvider>
  );
}
