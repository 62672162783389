import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import { useParams } from 'react-router-dom';
import { triggerToast } from '../utils';
import { sortCategoriesByRank, sortMenusByRank } from '../utils/categorySorter';

import { AuthenticatedFetch, scopesEnum } from '../lib';
import { AuthContext } from './AuthProvider';
import { useNavigate } from 'react-router-dom';

export const CashierOrderContext = createContext({});

export function CashierOrderProvider({ children }) {
  const { orderId } = useParams();
  const [order, setOrder] = React.useState({});
  const [tempOrder, setTempOrder] = React.useState({});
  const [categories, setCategories] = React.useState([]);
  const [menus, setMenus] = React.useState([]);
  const [menuId, setMenuId] = React.useState();
  const [addonsProps, setAddonsProps] = React.useState([]);
  const [quantityProps, setQuantityProps] = React.useState(1);
  const [orderItemToBeUpdated, setOrderItemToBeUpdated] = React.useState(undefined);
  const [orderItems, setOrderItems] = React.useState([]);
  const [tables, setTables] = React.useState([]);
  const [payments, setPayments] = React.useState([]);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const [orderResponse, categoriesResponse, menusResponse, tablesResponse, paymentsResponse] = await Promise.all([
        AuthenticatedFetch(`order/${orderId}`),
        AuthenticatedFetch(`categories`),
        AuthenticatedFetch(`menus`),
        AuthenticatedFetch(`table`),
        AuthenticatedFetch(`payment`)
      ]);

      const [orderData, categoriesData, menusData, tablesData, paymentsData] = await Promise.all([
        orderResponse.json(),
        categoriesResponse.json(),
        menusResponse.json(),
        tablesResponse.json(),
        paymentsResponse.json()
      ]);

      setOrder(orderData);
      setTempOrder(orderData);
      setCategories(sortCategoriesByRank(categoriesData, false));
      setMenus(sortMenusByRank(menusData, false));
      setTables(tablesData);
      setPayments(paymentsData);

      if (orderData.status == "UNPAID" && !decideIfRoleHasAccess(scopesEnum.UPDATE_UNPLACED_ORDER)) {
        return navigate('/cashier');
      }
      if (orderData.status == "PAID" && !decideIfRoleHasAccess(scopesEnum.UPDATE_PAID_ORDER)) {
        return navigate('/cashier');
      }
    }

    fetchData();
  }, []);

  const menuToDisplay = useMemo(() => {
    if (menuId) {
      return menus.find((menu) => menu.id == menuId);
    }
  }, [menuId]);

  async function handleUpdate(key, value, name, callback) {
    let newTempOrder = tempOrder;
    newTempOrder[key] = value;
    setTempOrder(newTempOrder)
    if (callback) {
      const pass = callback();

      if (!pass) return;
    }

    const res = await AuthenticatedFetch(`order/${order.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        [key]: value
      })
    });

    if (res.status < 400) {
      triggerToast('success', `${name} successfully updated`);
      setOrder((prev) => ({
        ...prev,
        [key]: value
      }));
    } else {
      const errMessage = await res.text();

      triggerToast('error', errMessage);
    }
  }

  const orderItemToBeUsed = useMemo(() => {
    if (!order.orderItems || !orderItems) return [];

    return [...order.orderItems, ...orderItems];
  }, [orderItems, menus, order]);

  return (
    <CashierOrderContext.Provider
      value={{
        order,
        setOrder,
        categories,
        setCategories,
        menus,
        setMenus,
        menuToDisplay,
        setMenuId,
        addonsProps,
        setAddonsProps,
        quantityProps,
        setQuantityProps,
        orderItemToBeUpdated,
        setOrderItemToBeUpdated,
        orderItems,
        setOrderItems,
        tables,
        orderItemToBeUsed,
        handleUpdate,
        tempOrder,
        setTempOrder,
        payments, 
        setPayments
      }}>
      {children}
    </CashierOrderContext.Provider>
  );
}

CashierOrderProvider.propTypes = {
  children: PropTypes.node.isRequired
};
