export function sortCategoriesByRank(data, isFilterHidden) {
    if (isFilterHidden) {
        data = data.filter(item => item.name && item.name.toLowerCase() !== "bagi")
    }

    data = data.sort((a, b) => a.rank - b.rank);
    data.forEach(category => {
        category.menus = sortMenusByRank(category.menus);
    });

    return data.sort((a, b) => a.rank - b.rank);
}

export function sortMenusByRank(data, isFilterHidden) {
    if (isFilterHidden) {
        data = data.filter(item => item.name && item.name.toLowerCase() !== "bagi")
    }

    data = data.slice()
        .sort((item1, item2) => {
            if (!item1.category || !item2.category) {
                return item1.category ? -1 : 1;
            }

            return item1.category.rank - item2.category.rank;
        }).sort((item1, item2) => {
            return item1.rank - item2.rank;
        });

    return data;
}