import React from 'react';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { triggerToast } from '../../../../utils';
import { AuthenticatedFetch, scopesEnum } from '../../../../lib';
import { AuthContext } from '../../../../context/AuthProvider';

export default function OrderModalBox() {
  const {
    order,
    setOrder,
    menuToDisplay,
    addonsProps,
    quantityProps,
    orderItemToBeUpdated,
    orderItems,
    setOrderItems,
    menuId
  } = React.useContext(CashierOrderContext);
  const [addons, setAddons] = React.useState([]);
  const [subTotalPrice, setSubTotalPrice] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1);
  const [isTriggered, setIsTriggered] = React.useState(false);
  const [triggerFetch, setTriggerFetch] = React.useState(undefined);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      notes: undefined,
      type: undefined
    }
  });

  React.useEffect(() => {
    if (!menuToDisplay) return;

    setAddons(addonsProps);
    setQuantity(quantityProps);

    reset({
      notes: "",
    });
    if (orderItemToBeUpdated) {
      reset({
        notes: orderItemToBeUpdated.notes,
        type: orderItemToBeUpdated.type
      });
    }
  }, [addonsProps, orderItemToBeUpdated, menuId]);

  React.useEffect(() => {
    if (!menuToDisplay) return;

    if (addons.length > 0) {
      let total = 0;
      addons.forEach((addon) => {
        total += addon.price;
      });
      setSubTotalPrice(
        order.type == 'ONLINE'
          ? (menuToDisplay.onlinePrice + total) * quantity
          : (menuToDisplay.offlinePrice + total) * quantity
      );
    } else {
      setSubTotalPrice(
        order.type == 'ONLINE'
          ? menuToDisplay.onlinePrice * quantity
          : menuToDisplay.offlinePrice * quantity
      );
    }

    setIsTriggered(true);
  }, [addons, quantity]);

  React.useEffect(() => {
    async function updateData() {
      triggerFetch(subTotalPrice);
    }

    if (!orderItemToBeUpdated) return;
    if (!isTriggered) return;
    if (!triggerFetch) return;

    updateData();

    setTriggerFetch(undefined);
    setIsTriggered(false);
  }, [isTriggered, triggerFetch, orderItemToBeUpdated, subTotalPrice]);

  async function onSubmit() {
    const { notes, type } = watch();

    const body = {
      menuId: menuToDisplay.id,
      quantity: quantity,
      price: subTotalPrice,
      type: type,
      notes: notes
    };

    setValue('notes', undefined);

    if (order.state == 'PLACED' && orderItemToBeUpdated && !orderItemToBeUpdated.temp) {
      await handlePlacedOrder(body);

      return;
    }

    await handleUnplacedOrder(body);
  }

  function findOrderItemNextID() {
    let nextID = 1;

    orderItems.forEach((item) => {
      if (item.id >= nextID) {
        nextID = item.id + 1;
      }
    });

    return nextID;
  }

  async function handleUnplacedOrder(body) {
    const bodyWithAddons = {
      ...body,
      id: orderItemToBeUpdated ? orderItemToBeUpdated.id : findOrderItemNextID(),
      addons: addons,
      temp: true
    };

    if (!orderItemToBeUpdated) {
      setOrderItems((prev) => [...prev, bodyWithAddons]);
    } else {
      setOrderItems((prev) => {
        return prev.map((item) => {
          if (item.id == orderItemToBeUpdated.id) {
            return {
              ...item,
              ...bodyWithAddons
            };
          }

          return item;
        });
      });
    }

    document.getElementById('modal-order').close();
  }

  async function handlePlacedOrder(body) {
    const orderItemRes = await AuthenticatedFetch(
      `order/${order.id}/order-item/${`${orderItemToBeUpdated.id}`}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
    );

    let success = orderItemRes.status < 400;
    const message = success ? `Order item successfully updated` : await orderItemRes.text();

    if (!success) {
      triggerToast('error', message);
      return;
    }

    let orderItem = success ? await orderItemRes.json() : null;

    setOrder((prev) => ({
      ...prev,
      orderItems: [...prev.orderItems.map((item) => (item.id == orderItem.id ? orderItem : item))]
    }));
    triggerToast('success', message);
    document.getElementById('modal-order').close();
  }

  async function handleUpdateAddon(body, subTotalPrice) {
    const addonRes = await AuthenticatedFetch(`order/${orderItemToBeUpdated.id}/addon`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    const success = addonRes.status < 400;
    const message = success ? 'Addon successfully updated' : await addonRes.text();

    if (!success) {
      triggerToast('error', message);
      return;
    }

    await updateOrderItemAfterUpdatingAddon(subTotalPrice);
    triggerToast('success', message);
  }

  async function handleDeleteAddon(body, subTotalPrice) {
    const addonRes = await AuthenticatedFetch(`order/${orderItemToBeUpdated.id}/addon`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });

    const success = addonRes.status < 400;
    const message = success ? 'Addon successfully deleted' : await addonRes.text();

    if (!success) {
      triggerToast('error', message);
      return;
    }

    await updateOrderItemAfterUpdatingAddon(subTotalPrice);
    triggerToast('success', message);
  }

  async function updateOrderItemAfterUpdatingAddon(subTotalPrice) {
    const orderRes = await AuthenticatedFetch(
      `order/${order.id}/order-item/${orderItemToBeUpdated.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          price: subTotalPrice
        })
      }
    );

    const orderItem = await orderRes.json();

    setOrder((prev) => ({
      ...prev,
      orderItems: prev.orderItems.map((item) => (item.id == orderItem.id ? orderItem : item))
    }));
  }

  return (
    <dialog id="modal-order" className="modal">
      <div className="modal-box" >
        <MdClose
          className="float-right cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
          onClick={() => {
            document.getElementById('modal-order').close();
          }}
        />
        {menuToDisplay && (
          <>
            <h3 className="font-bold text-lg text-center">
              {orderItemToBeUpdated ? 'Update' : 'Tambah'} {menuToDisplay.name}
            </h3>
            <div className="divider"></div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ height: "50vh", overflowY: "auto" }}>
                <div className="label w-full flex flex-row justify-between" >
                  <span className="label-text font-bold">{menuToDisplay.name}</span>
                  <span className="font-bold">
                    {order.type == 'ONLINE' ? menuToDisplay.onlinePrice : menuToDisplay.offlinePrice}
                  </span>
                </div>

                {menuToDisplay.addons.length > 0 && (
                  <>
                    {menuToDisplay.addons.map((addOn) => (
                      <div
                        className="label w-full items-start flex flex-col gap-1"
                        key={`addOn-${addOn.id}`}>
                        <p className="label-text font-bold">{addOn.name}</p>
                        <div className="form-control w-full">
                          {addOn.options.map((option) => (
                            <div
                              className="flex flex-row justify-between items-center"
                              key={`addOn-option-${option.id}`}>
                              <div className="flex flex-row gap-1 items-center font-medium">
                                <label className="label cursor-pointer">
                                  <input
                                    type={addOn.multiple ? 'checkbox' : 'radio'}
                                    name={addOn.name}
                                    className={`${addOn.multiple
                                      ? 'checkbox checkbox-primary checkbox-sm !rounded-md'
                                      : 'radio radio-primary radio-sm'
                                      }`}
                                    checked={addons.some(
                                      (item) => item.optionId == option.id && item.addonId == addOn.id
                                    )}
                                    onClick={async (e) => {
                                      if (addOn.multiple) return;
                                      // Deselect radio button if it is already checked
                                      if (e.target.checked) {
                                        setAddons((prev) =>
                                          prev.filter((item) => item.optionId != option.id)
                                        );
                                        if (!orderItemToBeUpdated) return;

                                        if (order.state == 'PLACED' && !orderItemToBeUpdated.temp) {
                                          setTriggerFetch(() => async (subTotalPrice) => {
                                            await handleDeleteAddon(
                                              {
                                                optionId: option.id,
                                                addonId: addOn.id
                                              },
                                              subTotalPrice
                                            );
                                          });
                                        } else {
                                          setOrderItems((prev) => {
                                            return prev.map((item) => {
                                              if (item.id == orderItemToBeUpdated.id) {
                                                return {
                                                  ...item,
                                                  addons: item.addons.filter(
                                                    (addon) =>
                                                      addon.optionId != option.id &&
                                                      addon.addonId != addOn.id
                                                  )
                                                };
                                              }

                                              return item;
                                            });
                                          });
                                        }
                                      }
                                    }}
                                    onChange={async (e) => {
                                      const body = {
                                        optionId: option.id,
                                        addonId: addOn.id,
                                        price:
                                          order.type == 'ONLINE' ? option.priceOnline : option.price
                                      };

                                      if (e.target.checked && !addOn.multiple) {
                                        let prevBody = undefined;
                                        await setAddons((prev) => [
                                          ...prev.filter((item) => {
                                            if (
                                              item.optionId != option.id &&
                                              item.addonId == addOn.id
                                            ) {
                                              prevBody = item;
                                            }

                                            return (
                                              item.optionId != option.id && item.addonId != addOn.id
                                            );
                                          }),
                                          body
                                        ]);
                                        if (!orderItemToBeUpdated) return;

                                        if (order.state == 'PLACED' && !orderItemToBeUpdated.temp) {
                                          setIsTriggered(true);
                                          setTriggerFetch(() => async (subTotalPrice) => {
                                            await handleDeleteAddon(prevBody, subTotalPrice);
                                            await handleUpdateAddon(body, subTotalPrice);
                                          });
                                        } else {
                                          setOrderItems((prev) => {
                                            return prev.map((item) => {
                                              if (item.id == orderItemToBeUpdated.id) {
                                                return {
                                                  ...item,
                                                  addons: [
                                                    ...item.addons.filter(
                                                      (addon) =>
                                                        addon.optionId != prevBody.optionId &&
                                                        addon.addonId != prevBody.addonId
                                                    ),
                                                    body
                                                  ]
                                                };
                                              }

                                              return item;
                                            });
                                          });
                                        }
                                      } else if (e.target.checked && addOn.multiple) {
                                        setAddons((prev) => [...prev, body]);
                                        if (!orderItemToBeUpdated) return;

                                        if (order.state == 'PLACED' && !orderItemToBeUpdated.temp) {
                                          setTriggerFetch(() => async (subTotalPrice) => {
                                            await handleUpdateAddon(body, subTotalPrice);
                                          });
                                        } else {
                                          setOrderItems((prev) => {
                                            return prev.map((item) => {
                                              if (item.id == orderItemToBeUpdated.id) {
                                                return {
                                                  ...item,
                                                  addons: [...item.addons, body]
                                                };
                                              }

                                              return item;
                                            });
                                          });
                                        }
                                      } else {
                                        setAddons((prev) =>
                                          prev.filter((item) => item.optionId != option.id)
                                        );
                                        if (!orderItemToBeUpdated) return;

                                        if (order.state == 'PLACED' && !orderItemToBeUpdated.temp) {
                                          setTriggerFetch(() => async (subTotalPrice) => {
                                            await handleDeleteAddon(body, subTotalPrice);
                                          });
                                        } else {
                                          setOrderItems((prev) => {
                                            return prev.map((item) => {
                                              if (item.id == orderItemToBeUpdated.id) {
                                                return {
                                                  ...item,
                                                  addons: item.addons.filter(
                                                    (addon) =>
                                                      addon.optionId != body.optionId &&
                                                      addon.addonId != body.addonId
                                                  )
                                                };
                                              }

                                              return item;
                                            });
                                          });
                                        }
                                      }
                                    }}
                                  />
                                  <span style={{ marginLeft: "10px" }} className="label-text">{option.name}</span>
                                </label>
                              </div>
                              <div className="label-text">
                                {order.type == 'ONLINE' ? option.priceOnline : option.price}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )}
                <label className="form-control w-full">
                  <div className="label w-fit">
                    <span className="label-text font-bold">Notes</span>
                  </div>
                  <textarea
                    type="text"
                    min={0}
                    placeholder="Enter Notes"
                    className="input input-bordered px-4 py-1 w-full font-semibold"
                    {...register('notes')}
                  />
                </label>
                <label className="form-control w-full flex flex-row justify-between mt-2">
                  <div className="label w-fit flex-1">
                    <span className="label-text font-bold">Quantity</span>
                  </div>
                  <div className="flex flex-row gap-2 items-center">
                    <FaMinus
                      className="h-3 w-3 text-gray-600 cursor-pointer"
                      onClick={() => {
                        if (quantity == 1 && !orderItemToBeUpdated) return;

                        if (quantity == 1 && orderItemToBeUpdated) {
                          if (
                            orderItemToBeUpdated.temp &&
                            !decideIfRoleHasAccess(scopesEnum.DELETE_UNPLACED_ORDER)
                          ) {
                            triggerToast('error', 'You are not allowed to delete unplaced order!');
                            return;
                          }

                          document.querySelector('#confirmation-modal').showModal();

                          return;
                        }

                        setQuantity(quantity - 1);
                      }}
                    />
                    <input
                      type="number"
                      value={quantity}
                      onChange={(e) => {
                        const newQuantity = parseInt(e.target.value, 10);
                        if (!isNaN(newQuantity) && newQuantity > 0) {
                          setQuantity(newQuantity);
                        }
                      }}
                      min={1}
                      className="input input-bordered flex items-center !w-16 text-center h-fit"
                    />
                    <FaPlus
                      className="h-3 w-3 text-gray-600 cursor-pointer"
                      onClick={() => {
                        setQuantity(quantity + 1);
                      }}
                    />
                  </div>
                </label>
                <div className="label">
                  <span className="label-text-alt text-red-500 font-semibold">
                    {' '}
                    {errors.tableNumber && 'Table number is required!'}
                  </span>
                </div>
                <div className="label w-full flex flex-row justify-between">
                  <span className="label-text font-bold">Subtotal</span>
                  <span className="font-bold">{subTotalPrice}</span>
                </div>
              </div>
              <div className="modal-action" >
                <button
                  className={`btn ${order.type == 'DINE_IN' ? 'w-1/2' : 'w-full'}`}
                  type="submit"
                  onClick={() => {
                    setValue('type', 'TAKE_AWAY');
                  }}>
                  Take Away
                </button>
                {order.type == 'DINE_IN' && (
                  <button
                    className="btn w-1/2"
                    type="submit"
                    onClick={() => {
                      setValue('type', 'DINE_IN');
                    }}>
                    Dine In
                  </button>
                )}
              </div>
            </form>
          </>
        )
        }
      </div >
      <form method="dialog" className="modal-backdrop">
        <button></button>
      </form>
    </dialog >
  );
}
