export const RestaurantTab = ['Table', 'Lokasi Printer', 'Payment Method'];

export const StatusTab = [
  {
    label: 'Paid',
    value: 'PAID'
  },
  {
    label: 'Unpaid',
    value: 'UNPAID'
  },
  {
    label: 'All',
    value: 'ALL'
  }
];

export const TypeTab = [
  {
    label: 'Dine In',
    value: 'DINE_IN'
  },
  {
    label: 'Take Away',
    value: 'TAKE_AWAY'
  },
  {
    label: 'Online',
    value: 'ONLINE'
  },
  {
    label: 'All',
    value: 'ALL'
  }
];

export const ACLTab = ['User', 'Role'];

export const RECORDS_PER_PAGE = 50;

export const scopesEnum = {
  CUSTOMER: 'Customer',
  ASSIGN_TABLE: 'Assign Table',
  ADD_WAITING_LIST: 'Add Waiting List',
  CASHIER: 'Cashier',
  KITCHEN: 'Kitchen',
  CREATE_ORDER: 'Create Order',
  UPDATE_UNPLACED_ORDER: 'Update (unplaced) Order',
  DELETE_UNPLACED_ORDER: 'Delete (unplaced) order',
  PLACED_CONFIRM_ORDER: 'Placed (confirm) Order',
  UPDATE_PLACED_ORDER: 'Update (placed) order',
  PROCESS_PAYMENT: 'Process payment',
  CANCEL_ORDER: 'Cancel order',
  UPDATE_PAID_ORDER: 'Update (paid) Order',
  DELETE_PAID_ORDER: 'Delete (paid) Order',
  MENU_MANAGEMENT: 'Menu Management',
  UPDATE_AVAILABILITY: 'Update Availability',
  CREATE_MENU: 'Create Menu',
  UPDATE_MENU: 'Update Menu',
  DELETE_MENU: 'Delete Menu',
  CREATE_ADDONS: 'Create Addons',
  UPDATE_ADDONS: 'Update Addons',
  DELETE_ADDONS: 'Delete Addons',
  CREATE_CATEGORY: 'Create Category',
  UPDATE_CATEGORY: 'Update Category',
  DELETE_CATEGORY: 'Delete Category',
  OPERATION: 'Operation',
  MARK_TABLE_AS_EMPTY: 'Mark Table as Empty',
  CHANGE_TABLE: 'Change Table',
  REPRINT_DOCUMENT: 'Reprint Document',
  RESTAURANT_MANAGEMENT: 'Restaurant Management',
  CREATE_TABLE: 'Create Table',
  UPDATE_TABLE: 'Update Table',
  DELETE_TABLE: 'Delete Table',
  CREATE_KITCHEN: 'Create Kitchen',
  UPDATE_KITCHEN: 'Update Kitchen',
  DELETE_KITCHEN: 'Delete Kitchen',
  CREATE_PAYMENT_METHOD: 'Create Payment Method',
  UPDATE_PAYMENT_METHOD: 'Update Payment Method',
  DELETE_PAYMENT_METHOD: 'Delete Payment Method',
  VIEW_REPORT: 'View Report',
  ACCESS_CONTROL_LIST: 'Access Control List'
};

export const scopesGroup = {
  'Assign Table': 'Customer',
  'Add Waiting List': 'Customer',
  Kitchen: 'Cashier',
  'Create Order': 'Cashier',
  'Update (unplaced) Order': 'Cashier',
  'Delete (unplaced) order': 'Cashier',
  'Placed (confirm) Order': 'Cashier',
  'Update (placed) order': 'Cashier',
  'Process payment': 'Cashier',
  'Cancel order': 'Cashier',
  'Update (paid) Order': 'Cashier',
  'Delete (paid) Order': 'Cashier',
  'Update Availability': 'Menu Management',
  'Create Menu': 'Menu Management',
  'Update Menu': 'Menu Management',
  'Delete Menu': 'Menu Management',
  'Create Addons': 'Menu Management',
  'Update Addons': 'Menu Management',
  'Delete Addons': 'Menu Management',
  'Create Category': 'Menu Management',
  'Update Category': 'Menu Management',
  'Delete Category': 'Menu Management',
  'Mark Table as Empty': 'Operation',
  'Change Table': 'Operation',
  'Reprint Document': 'Operation',
  'Create Table': 'Restaurant Management',
  'Update Table': 'Restaurant Management',
  'Delete Table': 'Restaurant Management',
  'Create Kitchen': 'Restaurant Management',
  'Update Kitchen': 'Restaurant Management',
  'Delete Kitchen': 'Restaurant Management',
  'Create Payment Method': 'Restaurant Management',
  'Update Payment Method': 'Restaurant Management',
  'Delete Payment Method': 'Restaurant Management'
};
