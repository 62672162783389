import React from 'react';
import AddTable from './command/AddTable';
import AddPrinterLocation from './command/AddPrinterLocation';
import AddPayment from './command/AddPayment';
import { triggerToast } from '../../../utils';
import { RestaurantContext } from '../../../context/RestaurantProvider';
import { MdClose } from 'react-icons/md';
import { AuthenticatedFetch } from '../../../lib';

export default function ModalBox() {
  const { deleteModal, activeTab, setTables, setPrinterLocations, setPayments, updateModal } =
    React.useContext(RestaurantContext);

  return (
    <dialog id="modal-restaurant" className="modal">
      <div className="modal-box">
        <MdClose
          className="float-right cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
          onClick={() => {
            document.querySelector('#modal-restaurant').close();
          }}
        />
        <h3 className="font-bold text-lg text-center">
          {deleteModal.show
            ? `Delete ${deleteModal.name}`
            : `${updateModal.update ? 'Update' : 'Tambah'} ${
                activeTab == 'Table' ? updateModal.name : activeTab
              }`}
        </h3>
        {!deleteModal.show && (
          <>
            <div className="divider"></div>
            {activeTab == 'Table' && (
              <AddTable
                id={updateModal.id}
                printerLocationId={updateModal.printerLocationId}
                capacity={updateModal.capacity}
                update={updateModal.update}
              />
            )}
            {activeTab == 'Lokasi Printer' && (
              <AddPrinterLocation
                id={updateModal.id}
                name={updateModal.name}
                update={updateModal.update}
              />
            )}
            {activeTab == 'Payment Method' && (
              <AddPayment id={updateModal.id} name={updateModal.name} update={updateModal.update} />
            )}
          </>
        )}
        {deleteModal.show && (
          <div className="modal-action">
            <button
              className="btn"
              type="button"
              onClick={async () => {
                const res = await AuthenticatedFetch(`${deleteModal.link}`, {
                  method: 'DELETE'
                });

                const data = await res.json();
                if (res.status == 200) {
                  if (activeTab == 'Table') {
                    setTables((prev) => prev.filter((table) => table.id != data.id));
                  } else if (activeTab == 'Lokasi Printer') {
                    setPrinterLocations((prev) =>
                      prev.filter((printerLocation) => printerLocation.id != data.id)
                    );
                  } else {
                    setPayments((prev) => prev.filter((payment) => payment.id != data.id));
                  }
                  triggerToast('success', `${activeTab} deleted successfully!`);

                  document.querySelector('#modal-restaurant').close();
                }
              }}>
              Hapus
            </button>
          </div>
        )}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button></button>
      </form>
    </dialog>
  );
}
