import React from 'react';
import { documentHeader, DocumentHeaderProps } from "../components/document-header"
import { render, Printer, Text, Line, Cut, Br } from 'react-thermal-printer';
import { columnVariableValue } from '../components/formatter';
import { CollumnFormatType } from '../PrinterEnum';

export const renderCaptainOrderDocument = async ({ captainOrder }) => {
  return render(<Printer type="epson" width={48}>
    {captainOrderDocument({ captainOrder })}
  </Printer>);
}

const captainOrderDocument = ({ captainOrder }) => {
  const documentHeaderData = new DocumentHeaderProps({
    title: "Captain Order",
    orderId: captainOrder.orderId,
    userName: captainOrder.customerName,
    tableNumber: captainOrder.tableNumber,
    kitchenName: captainOrder.kitchenName,
    date: captainOrder.date,
    contentTableRightVar: "Notes",
    showIdentity: false,
  });

  return (
    <>
      {documentHeader(documentHeaderData)}
      {content(captainOrder)}
      {footer(captainOrder)}
    </>
  );
};

const footer = (props) => {
  return (
    <>
      <Line character='=' />
      <Br />
      <Text align="center" bold>No Nota</Text>
      <Text align="center">{props.noNota}</Text>
      {props.keterangan && <Text align="center" bold>Keterangan</Text>}
      {props.keterangan && <Text align="center">{props.keterangan}</Text>}
      <Cut partial={true} lineFeeds={4} />
    </>
  );
}

const content = (props) => {
  const { orderItems } = props;
  return (
    <>
      {orderItems.map((item) => (
        contentItem(item)
      ))}
    </>
  );
}

const contentItem = (item) => {
  return (
    <>
      <Text align='left' bold>{columnVariableValue({ variable: item.quantity, value: item.name, collumnFormat: CollumnFormatType.QtyItem })}</Text>
      {item.type === 'TAKE_AWAY' && <Text align='left' bold>TAKE AWAY</Text>}
      {item.addons.map((addOn) => (
        <Text align='left' key={addOn}>{columnVariableValue({ variable: '', value: addOn.name, collumnFormat: CollumnFormatType.AddOn })}</Text>
      ))}
      {item.note && <Text align='right'>{item.note}</Text>}
      <Br />
    </>
  )
}