import React from 'react';
import { CashierContext } from '../../../context/CashierProvider';
import OrderInfo from './OrderInfo';
import { AuthenticatedFetch, scopesEnum } from '../../../lib';
import { triggerToast } from '../../../utils';
import { AuthContext } from '../../../context/AuthProvider';

import portManager from '../../../printer/PrinterBluetooth';
import usbManager from '../../../printer/PrinterUsb';

import ReprintModal from '../../../components/operation/ReprintModal';
import PrintSuccessModal from '../../../components/operation/PrintSucessModal';
import { getPrinterDeviceName, printDocumentQueue } from '../printer/utils';

export default function InfoBar() {
  const { selectedOrder, printerType, usbData, bluetoothData, setIsOrderToBeDeleted, printerLocationId
  } = React.useContext(CashierContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  const { subtotals, tax, total } = React.useMemo(() => {
    if (!selectedOrder)
      return {
        subtotals: 0,
        tax: 0,
        total: 0
      };

    const subtotals = Math.ceil(
      parseFloat(
        selectedOrder.orderItems.reduce((result, orderItem) => {
          const subtotal = orderItem.price;
          return result + subtotal;
        }, 0) / 1.1
      )
    );

    const tax = Math.floor(parseFloat(subtotals * 0.1));
    return {
      subtotals: subtotals,
      tax: tax,
      total: subtotals + tax
    };
  }, [selectedOrder]);


  async function printBill(orderId) {
    try {
      let deviceName = getPrinterDeviceName(printerType, usbData, bluetoothData);

      if (deviceName == '') {
        triggerToast('error', `Printer belum terhubung, processing to documentQueue`);
      }

      const res = await AuthenticatedFetch(`document-queue/payment/${orderId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderIds: [orderId]
        })
      });

      triggerToast('success', `Berhasil print ulang order :`, orderId);

      const data = await res.json();
      if (data.length >= 0) {
        printDocumentQueue(printerType, printerLocationId, portManager, usbManager);
      }

      triggerToast('success', `Berhasil print ulang order :`, orderId);
    } catch (e) {
      triggerToast('error', `Gagal Print ${e}`);
    }
  }

  const showPaymentButton = decideIfRoleHasAccess(scopesEnum.PROCESS_PAYMENT);
  return (
    <div
      style={{ height: '80vh', position: 'sticky', top: '10vh' }}
      className="min-h-full lg:w-1/3 flex flex-col justify-between items-center rounded-xl w-full">
      <div style={{ height: '70vh' }}
        className="border-2 rounded-xl w-full"
      >
        <div className="flex flex-col justify-center w-full">
          <div className="flex flex-col gap-2 mt-2 lg:p-0 p-4">
            {selectedOrder && (
              <>
                <h4 className="font-semibold text-center">
                  {' '}
                  Bill {selectedOrder.customerName} #{selectedOrder.id}
                </h4>
                <div className="flex flex-col px-2">
                  {selectedOrder.description && <h4 className="text-center text-[1rem]">{selectedOrder.description}</h4>}
                  <h4 className="text-center text-[1rem]">Daftar Pesanan</h4>
                  <OrderInfo />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col w-full">
          {selectedOrder && (
            <div className="flex flex-col gap-1 w-full px-4 py-2 font-semibold text-sm">
              <div className="flex flex-row justify-between text-[1rem]">
                <p className="text-[1rem]">Subtotal</p>
                <p className="mr-5 md:mr-2 lg:mr-0 text-right text-[1rem]">
                  Rp.{subtotals.toLocaleString('de-DE')}
                </p>
              </div>
              <div className="flex flex-row justify-between text-[1rem]">
                <p className="text-[1rem]">Pajak</p>
                <p className="mr-5 md:mr-2 lg:mr-0 text-right text-[1rem]">
                  Rp.{tax.toLocaleString('de-DE')}
                </p>
              </div>
              <div className="flex flex-row justify-between">
                <p className="text-[1rem]">Total</p>
                <p className="mr-5 md:mr-2 lg:mr-0 text-right text-[1rem]">
                  Rp.{total.toLocaleString('de-DE')}
                </p>
              </div>
            </div>
          )}

          <div className="grid grid-cols-3  w-full flex flex-row justify-center gap-2 items-center">
            <button
              disabled={!selectedOrder}
              className={`btn button-with-loading text-center border-t-2 mt-2 font-semibold lg:p-1 p-2 text-[1rem] bg-orange-200`}
              onClick={() => document.getElementById("reprint_modal").show()}
            >
              Print Document
            </button>

            <button
              disabled={!(selectedOrder && selectedOrder.status != 'PAID')}
              className={`btn text-center border-t-2 mt-2 font-semibold lg:p-1 p-2 text-[1rem] bg-green-200`}
              onClick={() => {
                window.open(`/cashier/${selectedOrder.id}`);
              }}
            >
              Tambah Pesanan
            </button>

            <button
              disabled={!(showPaymentButton && selectedOrder && selectedOrder.status != 'PAID' && selectedOrder.orderItems.length > 0)}
              className={`btn text-center border-t-2 mt-2 font-semibold lg:p-1 p-2 text-[1rem] bg-blue-200`}
              onClick={() => {
                setIsOrderToBeDeleted(false);
                document.querySelector('#confirmation-modal').showModal();
              }}>
              Bayar
            </button>
          </div>
        </div>
      </div>

      <ReprintModal selectedOrderFromCashier={selectedOrder} printBill={printBill} />
      <PrintSuccessModal />
    </div>
  );
}
