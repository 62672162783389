import toast from 'react-hot-toast';
import React from 'react';

export const triggerToast = (type, message) => {
  const style = {
    duration: 4000,
    position: 'bottom-right',
    style: {
      borderRadius: '10px'
    }
  }
  const toastContent = (t) => (
    <span onClick={() => toast.dismiss(t.id)}>
      {message}
    </span>
  );
  switch (type) {
    case 'error':
      toast(toastContent, {
        icon: '❌',
        ...style
      });
      break;
    case 'success':
      toast(toastContent, {
        icon: '👍',
        ...style
      });
      break;
    default:
      toast(toastContent, {
        ...style
      });
      break;
  }
};