import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar/Navbar';
import { MantineProvider } from '@mantine/core';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/admin/dashboard/DashboardPage';
import RestaurantManagementPage from './pages/admin/restaurant/RestaurantManagementPage';
import { Toaster } from 'react-hot-toast';
import MenuPage from './pages/MenuPage';
import CashierPage from './pages/admin/cashier/CashierPage';
import CashierOrderPage from './pages/admin/cashier/order/CashierOrderPage';

import OrderPage from './pages/user/OrderPage';
import { OrderMenu } from './pages/user/OrderMenu';
import MenuDetail from './pages/user/MenuDetail';
import { OrderContext } from './contexts/OrderContext';
import '@mantine/core/styles.css';
import OrderTally from './pages/user/OrderTally';
import OrderBill from './pages/user/OrderBill';
import PrinterPage from './pages/admin/printer/PrinterPage';
import ACLPage from './pages/admin/acl/ACLPage';
import OperationPage from './pages/admin/operation/OperationPage';
import CustomerPage from './pages/admin/customer/CustomerPage';

function App() {
  const [order, setOrder] = useState([]);
  const [total, setTotal] = useState(0);
  const [bill, setBill] = useState([]);
  const [highlight, setHighlight] = useState(null);
  const [highlightedMenu, setHighlightedMenu] = useState();
  const [name, setName] = useState('');
  const [tableNumber, setTableNumber] = useState('');
  const [status, setStatus] = useState('');
  const [activeTab, setActiveTab] = useState('all');

  return (
    <div>
      <MantineProvider>
        <OrderContext.Provider
          value={{
            order,
            setOrder,
            total,
            setTotal,
            bill,
            setBill,
            highlight,
            setHighlight,
            name,
            setName,
            tableNumber,
            setTableNumber,
            status,
            setStatus,
            highlightedMenu,
            setHighlightedMenu,
            activeTab,
            setActiveTab
          }}>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/menu" element={<MenuPage />} />
            <Route exact path="/cashier" element={<CashierPage />} />
            <Route exact path="/cashier/:orderId" element={<CashierOrderPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route exact path="/order/:uid" element={<OrderPage />} />
            <Route path="/operation" element={<OperationPage />} />
            <Route path="/order/:uid/menu" element={<OrderMenu />} />
            <Route path="/order/:uid/menu/:id" element={<MenuDetail />} />
            <Route path="/order/:uid/tally" element={<OrderTally />} />
            <Route path="/order/:uid/bill" element={<OrderBill />} />
            <Route exact path="/printer" element={<PrinterPage />} />
            <Route path="/restaurant-management" element={<RestaurantManagementPage />} />
            <Route exact path="/acl" element={<ACLPage />} />
            <Route exact path="/customer" element={<CustomerPage />} />
          </Routes>
          <Toaster />
        </OrderContext.Provider>
      </MantineProvider>
    </div>
  );
}

export default App;
