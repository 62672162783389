import React from 'react';
import { documentHeader, DocumentHeaderProps } from "../components/document-header"
import { render, Printer, Text, Line, Cut, Br } from 'react-thermal-printer';
import { columnVariableValue, columnRight } from '../components/formatter';
import { CollumnFormatType } from '../PrinterEnum';

export const renderCheckerDocument = async ({ checker }) => {
  return render(<Printer type="epson" width={48}>
    {checkerDocument({ checker })}
  </Printer>);
} 

const checkerDocument = ({ checker }) => {
  console.log(checker)
  const documentHeaderData = new DocumentHeaderProps({
    title: "Checker",
    orderId: checker.orderId,
    userName: checker.customerName,
    tableNumber: checker.tableNumber,
    date: checker.date,
    contentTableRightVar: "Notes",
    showIdentity: false,
  });

  return (
    <>
      {documentHeader(documentHeaderData)}
      {content(checker)}
      {footer(checker)}
    </>
  );
};

const footer = (props) => {
  return (
    <>
      <Line character='=' />
      <Br />
      <Text align="center" bold>No Nota</Text>
      <Text align="center">{props.noNota}</Text>
      {props.keterangan && <Text align="center" bold>Keterangan</Text>}
      {props.keterangan && <Text align="center">{props.keterangan}</Text>}
      <Text align="center" bold={true}>Periksa kembali pesanan anda</Text>
      <Text align="center" bold={true}>Jika ada kesalahan, segera panggil pelayan</Text>
      <Cut partial={true} lineFeeds={4} />
    </>
  );
}

const content = (props) => {
  const { orderItems } = props;
  return (
    <>
      {orderItems.map((item) => (
        contentItem(item)
      ))}
    </>
  );
} 

const contentItem = (item) => {
  let notes = item.note === null ? [] : columnRight({ note: item.note, maxLength: 28 });
  return (
    <>
      <Text align='left' bold>{columnVariableValue({ variable: item.quantity, value: item.name, collumnFormat: CollumnFormatType.QtyItem })}</Text>
      {item.type === 'TAKE_AWAY' && <Text align='left' bold>TAKE AWAY</Text>}
      {item.addons.map((addon, index) => (
        <Text key={`addOn-${index}`} align='left'>{columnVariableValue({ variable: '', value: addon.name, collumnFormat: CollumnFormatType.AddOn })}</Text>
      ))}

      {notes.length === 0 ?
        <Br />
        :

        notes.map((note, index) => (
          <Text key={`note-${index}`} align='right'>{note}</Text>
        ))
      }
    </>
  )
}