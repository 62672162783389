import React from 'react';
import { ACLContext } from '../../../context/ACLProvider';
import UserForm from './UserForm';
import RoleForm from './RoleForm';

export default function Container() {
  const {
    activeTab,
    users,
    roles,
    setIsCommandButtonClicked,
    userToBeUpdated,
    setUserToBeUpdated,
    roleToBeUpdated,
    setRoleToBeUpdated
  } = React.useContext(ACLContext);

  return (
    <div className="border-2 rounded-lg flex flex-row gap-0">
      <div className="overflow-y-auto flex flex-col w-fit border-2 text-center">
        <div className="font-bold border-b-2 px-4">{activeTab}</div>
        {activeTab == 'User' && users && (
          <>
            {users.map((user) => (
              <div
                key={`user-${user.id}`}
                className={`border-b-2 px-4 py-2 font-semibold cursor-pointer ${
                  userToBeUpdated && userToBeUpdated.id == user.id && 'bg-blue-100'
                }`}
                onClick={() => {
                  setIsCommandButtonClicked(false);
                  setUserToBeUpdated(user);
                }}>
                {user.username}
              </div>
            ))}
          </>
        )}
        {activeTab == 'Role' && roles && (
          <>
            {roles.map((role) => (
              <div
                key={`user-${role.id}`}
                className={`border-b-2 px-4 py-2 font-semibold cursor-pointer 
                ${roleToBeUpdated && roleToBeUpdated.id == role.id && 'bg-blue-100'}
                `}
                onClick={() => {
                  setIsCommandButtonClicked(false);
                  setRoleToBeUpdated(role);
                }}>
                {role.name}
              </div>
            ))}
          </>
        )}
      </div>
      <div className="flex flex-1 flex-col border-2">
        <div className="flex w-full justify-center font-bold border-b-2 px-4">Detail</div>
        <div className="flex flex-col gap-2 p-4">
          {activeTab == 'User' && users && <UserForm />}
          {activeTab == 'Role' && roles && <RoleForm />}
        </div>
      </div>
    </div>
  );
}
