import PropTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';
import { ACLTab, AuthenticatedFetch } from '../lib';

export const ACLContext = createContext({});

export function ACLProvider({ children }) {
  const [activeTab, setActiveTab] = React.useState(ACLTab[0]);
  const [users, setUsers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [scopes, setScopes] = React.useState([]);
  const [isCommandButtonClicked, setIsCommandButtonClicked] = React.useState(false);
  const [userToBeUpdated, setUserToBeUpdated] = React.useState(null);
  const [roleToBeUpdated, setRoleToBeUpdated] = React.useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  useEffect(() => {
    async function fetchData() {
      const [usersResponse, rolesResponse, scopesResponse] = await Promise.all([
        AuthenticatedFetch(`users`),
        AuthenticatedFetch(`role`),
        AuthenticatedFetch(`scope`)
      ]);

      const [usersData, rolesData, scopesData] = await Promise.all([
        usersResponse.json(),
        rolesResponse.json(),
        scopesResponse.json()
      ]);

      setUsers(usersData);
      setRoles(rolesData);
      setScopes(scopesData);
    }

    fetchData();
  }, []);

  return (
    <ACLContext.Provider
      value={{
        activeTab,
        setActiveTab,
        users,
        setUsers,
        roles,
        setRoles,
        scopes,
        setScopes,
        isCommandButtonClicked,
        setIsCommandButtonClicked,
        userToBeUpdated,
        setUserToBeUpdated,
        roleToBeUpdated,
        setRoleToBeUpdated,
        isDeleteModalOpen,
        setIsDeleteModalOpen
      }}>
      {children}
    </ACLContext.Provider>
  );
}

ACLProvider.propTypes = {
  children: PropTypes.node.isRequired
};
