import React from 'react';
import { ACLTab } from '../../../lib';
import { ACLContext } from '../../../context/ACLProvider';

export default function TabBar() {
  const { activeTab, setActiveTab, setIsCommandButtonClicked } = React.useContext(ACLContext);

  return (
    <div role="tablist" className="tabs tabs-boxed">
      {ACLTab.map((tab) => (
        <a
          role="tab"
          className={`font-bold text-xs sm:text-sm md:text-base tab ${
            activeTab == tab ? 'tab-active font-extrabold' : ''
          }`}
          onClick={() => {
            setIsCommandButtonClicked(false);
            setActiveTab(tab);
          }}
          key={tab}>
          {tab}
        </a>
      ))}
    </div>
  );
}
