import React from 'react';
import { Link } from 'react-router-dom';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import { closeCurrentTab } from '../../../../utils/windowManagement';

export default function Breadcrumbs() {
  const { order } = React.useContext(CashierOrderContext);

  return (
    <div className="text-sm breadcrumbs font-semibold px-8">
      <ul>
        <li>
          <Link onClick={() => { closeCurrentTab() }}>Cashier</Link>
        </li>
        {order && order.id && (
          <li>
            <Link to={`/order/${order.id}/menu`}>{order && order.id}</Link>
          </li>
        )}
      </ul>
    </div>
  );
}
