import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { RestaurantContext } from '../../../../context/RestaurantProvider';
import { triggerToast } from '../../../../utils';
import { AuthenticatedFetch } from '../../../../lib';

export default function AddTable({ id, printerLocationId, capacity, update }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      paxs: update ? capacity : null,
      printerLocationId: update ? printerLocationId : NaN
    }
  });

  useEffect(() => {
    if (update) {
      setValue('paxs', capacity);
      setValue('printerLocationId', printerLocationId);
    } else {
      setValue('paxs', null);
      setValue('printerLocationId', NaN);
    }
  }, [update, capacity, printerLocationId]);

  const { setTables, printerLocations } = React.useContext(RestaurantContext);

  async function onSubmit(data) {
    const { paxs, printerLocationId } = data;

    const res = await AuthenticatedFetch(`table` + `${update ? `/${id}` : ''}`, {
      method: update ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ paxs, printerLocationId })
    });

    if (res.status < 400) {
      const dt = await res.json();

      if (update) {
        setTables((prev) => {
          return prev.map((table) => (table.id == id ? { ...table, ...dt } : table));
        });
      } else {
        setTables((prev) => [...prev, dt]);
      }

      triggerToast('success', `Table ${update ? 'updated' : 'added'} successfully!'`);
      document.querySelector('#modal-restaurant').close();
      setValue('paxs', null);
      setValue('printerLocationId', NaN);
    } else {
      const errMessage = await res.text();

      triggerToast('error', errMessage);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="form-control w-full">
        <div className="label w-fit">
          <span className="label-text font-bold">Table Capacity</span>
        </div>
        <input
          type="number"
          min={0}
          placeholder="Enter Table Capacity"
          className="input input-bordered w-full font-semibold"
          {...register('paxs', {
            valueAsNumber: true,
            required: true
          })}
        />
      </label>
      <div className="label">
        <span className="label-text-alt text-red-500 font-semibold">
          {' '}
          {errors.paxs && 'Capacity is required!'}
        </span>
      </div>
      <label className="form-control w-full">
        <div className="label w-fit">
          <span className="label-text font-bold">Printer Location</span>
        </div>
        <select
          defaultValue={''}
          className={`dropdown input input-bordered w-full font-semibold ${
            watch('printerLocationId') ? '' : 'text-gray-400'
          }`}
          {...register('printerLocationId', {
            valueAsNumber: true,
            required: true
          })}>
          <option value="" hidden>
            Please select printer location
          </option>
          {printerLocations.map((printerLocation) => (
            <option key={`printerLocation-${printerLocation.id}`} value={printerLocation.id}>
              {printerLocation.name}
            </option>
          ))}
        </select>
      </label>
      <div className="label">
        <span className="label-text-alt text-red-500 font-semibold">
          {' '}
          {errors.printerLocationId && 'Printer Location is required!'}
        </span>
      </div>
      <div className="modal-action">
        <button className="btn" type="submit">
          {update ? 'Update' : 'Tambah'}
        </button>
      </div>
    </form>
  );
}

AddTable.propTypes = {
  id: PropTypes.number,
  printerLocationId: PropTypes.number,
  capacity: PropTypes.number,
  update: PropTypes.bool.isRequired
};
