import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ACLContext } from '../../../context/ACLProvider';
import { triggerToast } from '../../../utils';
import { AuthenticatedFetch, scopesGroup, scopesEnum } from '../../../lib';
import { AuthContext } from '../../../context/AuthProvider';

export default function RoleForm() {
  const {
    scopes: scopesList,
    setRoles,
    roleToBeUpdated,
    setRoleToBeUpdated,
    setIsDeleteModalOpen
  } = React.useContext(ACLContext);

  const { decideIfRoleHasAccess } = React.useContext(AuthContext);
  const { refetchToken } = React.useContext(AuthContext);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      roleName: '',
      scopes: []
    }
  });

  useEffect(() => {
    if (!roleToBeUpdated) {
      reset({
        roleName: '',
        scopes: []
      });
      return;
    }

    const formattedScopes = roleToBeUpdated.scopes.map((scope) => ({
      scopeId: scope.id
    }));
    reset({
      roleName: roleToBeUpdated.name,
      scopes: formattedScopes
    });
  }, [roleToBeUpdated]);

  async function onSubmit(data) {
    const res = await AuthenticatedFetch(`role${roleToBeUpdated ? `/${roleToBeUpdated.id}` : ''}`, {
      method: roleToBeUpdated ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (res.status < 400) {
      const result = await res.json();
      if (roleToBeUpdated) {
        setRoles((prev) => {
          return prev.map((role) =>
            role.id == roleToBeUpdated.id ? { ...role, ...result } : role
          );
        });

        setRoleToBeUpdated(result);
      } else {
        setRoles((prev) => [...prev, result]);
      }
      triggerToast('success', `Role ${roleToBeUpdated ? 'updated' : 'created'} successfully!`);
      refetchToken();
    } else {
      triggerToast('error', 'Failed to create role!');
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="form-control w-full">
        <div className="label w-fit">
          <span className="label-text font-bold">Role Name</span>
        </div>
        <input
          type="text"
          placeholder="Enter Username"
          className="input input-bordered w-full font-semibold"
          {...register('roleName', {
            required: true
          })}
        />
      </label>
      <div className="label">
        <span className="label-text-alt text-red-500 font-semibold">
          {' '}
          {errors.roleName && 'Role name is required!'}
        </span>
      </div>
      <label className="form-control w-full">
        <div className="label w-fit">
          <span className="label-text font-bold">Scope List</span>
        </div>
        <div className="overflow-y-auto border-2 flex flex-col w-full items-center rounded-lg max-h-80">
          {scopesList &&
            scopesList.map((scope) => (
              <div
                className="flex flex-row w-full justify-between border-b-2 px-4 py-2  items-center rounded-lg"
                key={`scopes-${scope.id}`}>
                <div className="font-semibold flex-1">
                  {scopesGroup[scope.name] ? <>&nbsp;{'>'} </> : ''} {scope.name}
                </div>
                <input
                  type="checkbox"
                  name="scopes"
                  className="checkbox checkbox-primary checkbox-sm !rounded-md"
                  onChange={(e) => {
                    clearErrors('scopes');
                    if (e.target.checked) {
                      setValue('scopes', [
                        ...watch('scopes'),
                        {
                          scopeId: scope.id
                        }
                      ]);
                    } else {
                      setValue(
                        'scopes',
                        watch('scopes').filter((item) => item.scopeId !== scope.id)
                      );
                    }
                  }}
                  checked={watch('scopes').some((item) => item.scopeId === scope.id)}></input>
              </div>
            ))}
        </div>
      </label>
      <div className="label">
        <span className="label-text-alt text-red-500 font-semibold">
          {' '}
          {errors.scopes && 'Scopes is required!'}
        </span>
      </div>
      {decideIfRoleHasAccess(scopesEnum.ACCESS_CONTROL_LIST) && (
        <div
          className={`flex gap-2 ${
            roleToBeUpdated ? 'flex-row-reverse justify-end float-right' : 'flex-col items-end'
          }`}>
          <button
            className="btn"
            onClick={() => {
              if (watch('scopes').length === 0) {
                setError('scopes', {
                  type: 'manual',
                  message: 'Scope is required!'
                });
                return;
              }
            }}
            type="submit">
            Save Role
          </button>
          {roleToBeUpdated && (
            <button
              className="btn btn-error text-white"
              onClick={() => {
                setIsDeleteModalOpen(true);
                document.getElementById('modal-acl').showModal();
              }}
              type="button">
              Delete
            </button>
          )}
        </div>
      )}
    </form>
  );
}
