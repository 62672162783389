import React, { useEffect } from 'react';
import BillButton from '../../../components/cashier/BillButton';
import { CashierContext } from '../../../context/CashierProvider';
import { AuthenticatedFetch } from '../../../lib';
import { triggerToast } from '../../../utils';

import portManager from '../../../printer/PrinterBluetooth';
import usbManager from '../../../printer/PrinterUsb';

import { DocumentQueueApiUrls, DocumentQueueType } from '../../../printer/PrinterEnum';
import { renderCaptainOrderDocument } from '../../../printer/documents/captain-order';
import { renderCheckerDocument } from '../../../printer/documents/checker';
import { renderBillDocument } from '../../../printer/documents/bill';
import { renderQRDocument } from '../../../printer/documents/qr';

export default function BillGroup() {
  const { orders, selectedOrder, payments, setOrders, printerType, usbData, bluetoothData } = React.useContext(CashierContext);
  const [selectedBills, setSelectedBills] = React.useState([]);
  const [selectedPayment, setSelectedPayment] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setSelectedPayment(null);
    setSelectedBills([]);
  }, [selectedOrder]);

  function getDeviceName() {
    let deviceName = '';

    if (printerType === 'USB') {
      deviceName = usbData.name;
    } else if (printerType === 'Bluetooth') {
      deviceName = bluetoothData.id;
    }
    return deviceName;
  }

  async function handleFinish(isPrintBill) {
    try {
      setIsLoading(true);
      if (!selectedPayment) {
        return triggerToast('error', 'Pilih Payment Method');
      }
      if (isPrintBill && (getDeviceName() == '' || printerType == '')) {
        return triggerToast('error', "Printer belum terhubung");
      }

      const orderIds = Object.values(selectedBills).map((item) => item.value);
      orderIds.push(selectedOrder.id);

      const res = await AuthenticatedFetch(`order/${selectedOrder.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status: 'PAID',
          paymentId: selectedPayment,
          orderIds: orderIds
        })
      });

      const success = res.status < 400;
      const message = success ? 'Berhasil membayar pesanan' : await res.text();

      if (!success) {
        triggerToast('error', message);
        return;
      }

      const data = await res.json();
      const mapOfData = data.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setOrders((orders) => {
        return orders.map((order) => {
          const updatedOrder = mapOfData[order.id];
          if (updatedOrder !== undefined && orderIds.includes(order.id)) {
            return {
              ...order,
              status: updatedOrder.status,
              paymentId: updatedOrder.paymentId
            };
          }

          return order;
        });
      });
      setSelectedBills([]);
      setSelectedPayment(null);

      triggerToast('success', message);

      if (isPrintBill) printBill(true);

      document.getElementById('confirmation-modal').close();
    } finally {
      setIsLoading(false);
    }
  }

  async function printBill(isFinal) {
    try {
      setIsLoading(true);
      const orderIds = Object.values(selectedBills).map((item) => item.value);
      orderIds.push(selectedOrder.id);
      const res = await AuthenticatedFetch(`document-queue/payment/${selectedOrder.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          orderIds: orderIds
        })
      });

      if (!res.ok) {
        triggerToast('error', `Gagal membuat bill ${isFinal ? 'final' : 'temporary'}`);
        return;
      }

      triggerToast('success', `Berhasil membuat bill ${isFinal ? 'final' : 'temporary'}`);

      const data = await res.json();
      if (data.length >= 0) {
        const orderId = await printDocument(data[0]);
        if (orderId === null) {
          return triggerToast('error', `gagal print ${orderId}`);
        }
        const deleteRes = await AuthenticatedFetch(DocumentQueueApiUrls.BASE, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ids: [orderId] })
        });

        if (deleteRes.ok) {
          triggerToast('success', `printing ${orderId}`);
        } else {
          triggerToast('error', `gagal print ${orderId}`);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function printDocument(document) {
    let dataToPrint;
    switch (document.type) {
      case DocumentQueueType.PAYMENT:
        dataToPrint = await renderBillDocument({ bill: document });
        break;

      case DocumentQueueType.CAPTAIN_ORDER:
        dataToPrint = await renderCaptainOrderDocument({ captainOrder: document });
        break;

      case DocumentQueueType.CHECKER:
        dataToPrint = await renderCheckerDocument({ checker: document });
        break;

      case DocumentQueueType.QR:
        dataToPrint = await renderQRDocument({ qr: document });
        break;
      default:
        console.log('Unknown type:', document);
    }

    if (dataToPrint !== null) {
      try {
        let isSuccess = false;
        if (printerType === 'Bluetooth') {
          isSuccess = await portManager.handleBluetoothData(dataToPrint);
        } else if (printerType === 'USB') {
          isSuccess = await usbManager.handleUsbData(dataToPrint);
        }
        if (isSuccess) return document.queueId;
        else return null;
      } catch (e) {
        triggerToast('error', `gagal print document id : ${dataToPrint.orderId}`);
      }
    }
    return undefined;
  }

  return (
    <form className="flex flex-col gap-4">
      <select
        onChange={(e) => {
          const order = orders.find((order) => order.id === e.target.value);
          const isAlreadySelected = selectedBills.find((bill) => bill.value === order.id);

          if (isAlreadySelected) return;

          const label = `${order.id} - ${order.customerName} - ${order.type == 'DINE_IN' ? order.tableNumber : order.type
            }`;

          setSelectedBills([
            ...selectedBills,
            {
              label: label,
              value: order.id
            }
          ]);
        }}
        className={`dropdown input input-bordered w-full font-semibold text-gray-500`}
        defaultValue={''}>
        <option value="" hidden>
          Add Another Bill
        </option>{' '}
        {orders.map((order) => {
          if (order.id === selectedOrder.id || order.status === 'PAID') return;
          return (
            <option
              key={`order-type-${order.id}`}
              value={order.id}
              onClick={() => {
                const isAlreadySelected = selectedBills.find((bill) => bill.value == order.id);

                if (isAlreadySelected) return;

                const label = `${order.id} - ${order.customerName} - ${order.type == 'DINE_IN' ? order.tableNumber : order.type
                  }`;

                setSelectedBills([
                  ...selectedBills,
                  {
                    label: label,
                    value: order.id
                  }
                ]);
              }}>
              {`${order.id} - ${order.customerName}${order.type == 'DINE_IN'
                ? ` - ${order.tableNumber || ''} - ${order.numberOfPeople || ''} pax`
                : ``
                }`}
            </option>
          );
        })}
      </select>
      <div className="flex flex-row gap-4 justify-start items-center flex-wrap">
        {selectedBills.map((selectedBill) => (
          <div
            className="flex flex-row gap-3 items-center flex-wrap rounded-xl border-2 p-2 sm:p-4"
            key={`bill-${selectedBill.value}`}>
            <h4 className="font-semibold">{selectedBill.label}</h4>
            <BillButton
              label={'X'}
              color="red"
              className={`!w-fit`}
              onClick={async () => {
                setSelectedBills(selectedBills.filter((bill) => bill.value != selectedBill.value));
              }}
            />
          </div>
        ))}
      </div>

      <div className="grid grid-cols-3  w-full flex flex-row justify-center gap-2 items-center">
        {payments &&
          payments.map((payment) => (
            <div
              className="flex flex-row gap-2 items-center"
              key={`payment-${payment.id}`}
              onClick={() => {
                setSelectedPayment(payment.id);
              }}
            >
              <label className="label cursor-pointer">
                <input
                  type={'radio'}
                  name={'payment'}
                  className={`radio radio-primary radio-sm`}
                  checked={selectedPayment == payment.id}
                />
              </label>
              <span className="label-text">{payment.name}</span>
            </div>
          ))}
      </div>

      <BillButton
        label="Print Bill"
        color="blue"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={async () => {
          await printBill(false);
        }}
        className={`sm:mt-6 mt-4`}
      />
      <div className="modal-action">
        <BillButton
          label="Finish"
          color="orange"
          onClick={async () => {
            await handleFinish(false);
          }}
        // isHalved={true}
        />
        {/* <BillButton
          label="Print Final Bill"
          color="green"
          onClick={async () => {
            await handleFinish(true);
          }}
          isHalved={true}
        /> */}
      </div>
    </form>
  );
}
