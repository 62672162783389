import React from 'react';
import { ACLContext } from '../../../context/ACLProvider';

export default function CommandButton() {
  const { activeTab, setIsCommandButtonClicked, setUserToBeUpdated, setRoleToBeUpdated } =
    React.useContext(ACLContext);

  return (
    <button
      className="btn flex ml-auto w-fit font-bold text-xs sm:text-sm md:text-base"
      onClick={() => {
        setIsCommandButtonClicked(true);
        if (activeTab == 'User') {
          setUserToBeUpdated(null);
        } else {
          setRoleToBeUpdated(null);
        }
      }}>
      Add {activeTab}
    </button>
  );
}
