import { createContext } from 'react';

export const OrderContext = createContext({
  total: 0,
  setTotal: () => { },
  order: [],
  setOrder: () => { },
  bill: [],
  setBill: () => { },
  highlight: null,
  setHighlight: () => { },
  name: '',
  setName: () => { },
  tableNumber: '',
  setTableNumber: () => { },
  status: '',
  setStatus: () => { },
  highlightedMenu: null,
  setHighlightedMenu: () => { },
  activeTab: 'all',
  setActiveTab: () => { },
});
