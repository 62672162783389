export const CollumnFormatType = {
    QtyItem: { variableColLength: 4, divider: ' ' },
    AddOn: { variableColLength: 7, divider: ' ' },
    InfoData: { variableColLength: 12, divider: ' : ' }
};

export const DocumentQueueApiUrls = {
    BASE: `document-queue`,
    GET_ALL: `document-queue`,
    GET_BY_PRINTER_LOCATION_ID: (id) => `document-queue/printer-location/${id}`,
};

export const DocumentQueueType = {
    PAYMENT: 'PAYMENT',
    CAPTAIN_ORDER: 'CAPTAIN_ORDER',
    CHECKER: 'CHECKER',
    QR: 'QR',
}