import React, { useContext } from 'react';
import { MenuContext, UpdateMenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { AuthContext } from '../../context/AuthProvider';
import { triggerToast } from '../../utils/toast';

export default function MenuDeleteModal() {
  const { menus, setMenus } = useContext(MenuContext);
  const { menu } = useContext(UpdateMenuContext);
  const [deleteError, setDeleteError] = React.useState('');
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return (
    <dialog id="menu_delete_modal" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Delete {menu?.name}</h3>
        <p>Are you sure you want to delete {menu?.name}?</p>
        {deleteError && <p className="text-error">{deleteError}</p>}
        <div className="modal-action">
          <button
            className="btn btn-primary"
            onClick={() => document.getElementById('menu_delete_modal').close()}>
            Cancel
          </button>
          <button
            className="btn btn-error text-white"
            onClick={async () => {
              if (!decideIfRoleHasAccess(scopesEnum.DELETE_MENU)) {
                triggerToast('error', 'You are not allowed to delete menu!');
                return;
              }
              const res = await AuthenticatedFetch(`menus/${menu.id}`, {
                method: 'DELETE'
              });
              if (!res.ok) {
                const data = await res.text();
                setDeleteError(data);
                return;
              }
              const data = await res.json();
              setMenus(
                menus.map((m) => {
                  if (m.id === menu.id) {
                    return data;
                  }
                  return m;
                })
              );
              document.getElementById('menu_delete_modal').close();
            }}>
            Delete
          </button>
        </div>
      </div>
    </dialog>
  );
}
