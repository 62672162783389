import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import { AuthenticatedFetch, StatusTab, TypeTab } from '../lib';
import { triggerToast } from '../utils';

export const CashierContext = createContext({});

export function CashierProvider({ children }) {
  const [activePaymentStatusTab, setactivePaymentStatusTab] = React.useState(StatusTab[1].value);
  const [activePaymentTypeTab, setActivePaymentTypeTab] = React.useState();
  const [activeTypeTab, setActiveTypeTab] = React.useState(TypeTab[3].value);
  const [orders, setOrders] = React.useState([]);
  const [menus, setMenus] = React.useState([]); // const [menuId, setMenuId] = React.useState()
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [isOrderToBeDeleted, setIsOrderToBeDeleted] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const [selectedStartDate, setSelectedStartDate] = React.useState(undefined);
  const [selectedEndDate, setSelectedEndDate] = React.useState(undefined);
  const [nameToBeSearch, setNameToBeSearch] = React.useState(undefined);

  //printer
  const [usbData, setUsbData] = React.useState({ name: null, configuration: null });
  const [bluetoothData, setBluetoothData] = React.useState({ id: null });
  const [printerType, setPrinterType] = React.useState('');
  const [isPrinterBarOpen, setIsPrinterBarOpen] = React.useState(false);
  const [autoPrintEnabled, setAutoPrintEnabled] = React.useState(false);
  const [printerLocationId, setPrinterLocationId] = React.useState(null);

  const intervalRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const today = new Date();
      const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const endOfDay = new Date(startOfDay.getTime() + 86400000 - 1);
      const [ordersResponse, menusResponse, paymentsResponse] = await Promise.all([
        AuthenticatedFetch(`order/?startDate=${startOfDay}&endDate=${endOfDay}`),
        AuthenticatedFetch(`menus`),
        AuthenticatedFetch(`payment`),
        AuthenticatedFetch(`printer-location`)
      ]);

      const [ordersData, menusData, paymentsData] = await Promise.all([
        ordersResponse.json(),
        menusResponse.json(),
        paymentsResponse.json()
      ]);

      setOrders(ordersData);
      setMenus(menusData);

      const firstUnpaid = ordersData.find((order) => order.status == 'UNPAID');

      setSelectedOrder(
        ordersData.length > 0
          ? firstUnpaid != undefined && firstUnpaid != null
            ? firstUnpaid
            : null
          : null
      );
      setPayments(paymentsData);
    }
    fetchData();


    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      fetchByDate()
    }, 1000 * (process.env.REACT_APP_CASHIER_INTERVAL_DURATION_IN_SECOND || 5));
  }, []);

  const ordersUpdated = useMemo(() => {
    const filteredOrders = orders.filter((order) => {
      if (nameToBeSearch == undefined) return true;
      if (order.customerName == null) return false;
      return order.customerName.toLowerCase().includes(nameToBeSearch.toLowerCase());
    });

    let totalPriceWithOrders = filteredOrders.map((order) => {
      const totalPrice = order.orderItems.reduce((acc, curr) => {
        return acc + curr.price;
      }, 0);

      return { ...order, totalPrice };
    });

    if (activePaymentTypeTab != undefined) {
      totalPriceWithOrders = totalPriceWithOrders.filter((order) => {
        return order.paymentId == activePaymentTypeTab.id;
      });
    }

    return totalPriceWithOrders.filter((order) => {
      if (activePaymentStatusTab == 'ALL' && activeTypeTab == 'ALL') {
        return true;
      } else if (activePaymentStatusTab == 'ALL' && activeTypeTab != 'ALL') {
        return order.type == activeTypeTab;
      } else if (activePaymentStatusTab != 'ALL' && activeTypeTab == 'ALL') {
        return order.status == activePaymentStatusTab;
      } else {
        return order.status == activePaymentStatusTab && order.type == activeTypeTab;
      }
    });
  }, [activePaymentStatusTab, activeTypeTab, orders, nameToBeSearch, activePaymentTypeTab]);

  async function fetchByDate() {
    try {
      const today = new Date();
      const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const endOfDay = new Date(startOfDay.getTime() + 86400000 - 1);
      const startDate = selectedStartDate ?? startOfDay;
      const endDate = selectedEndDate ?? endOfDay;

      const res = await AuthenticatedFetch(
        `order/?startDate=${startDate}&endDate=${endDate}`
      );
      const data = await res.json();

      setOrders(data);

      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        fetchByDate()
      }, 1000 * (process.env.REACT_APP_CASHIER_INTERVAL_DURATION_IN_SECOND || 5));
    } catch (e) {
      triggerToast('error', `gagal mengambil data - ${e.toString()}`);
    }
  }

  useEffect(() => {
    if (selectedStartDate == undefined && selectedEndDate == undefined) return;
    fetchByDate();
  }, [selectedStartDate, selectedEndDate]);

  function onSearchButtonClick() {
    fetchByDate();
  }

  return (
    <CashierContext.Provider
      value={{
        activePaymentStatusTab,
        setactivePaymentStatusTab,
        activeTypeTab,
        setActiveTypeTab,
        orders: ordersUpdated,
        setOrders,
        menus,
        setMenus,
        selectedOrder,
        setSelectedOrder,
        isOrderToBeDeleted,
        setIsOrderToBeDeleted,
        selectedStartDate,
        selectedEndDate,
        setSelectedStartDate,
        setSelectedEndDate,
        payments,
        nameToBeSearch,
        setNameToBeSearch,
        usbData,
        setUsbData,
        bluetoothData,
        setBluetoothData,
        printerType,
        setPrinterType,
        isPrinterBarOpen,
        setIsPrinterBarOpen,
        onSearchButtonClick,
        autoPrintEnabled,
        setAutoPrintEnabled,
        printerLocationId,
        setPrinterLocationId,
        activePaymentTypeTab,
        setActivePaymentTypeTab
      }}>
      {children}
    </CashierContext.Provider>
  );
}

CashierProvider.propTypes = {
  children: PropTypes.node.isRequired
};