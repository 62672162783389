import React from 'react';
import Datepick from './Datepick';
import { CashierContext } from '../../../context/CashierProvider';

export default function DateGroup() {
  const { selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate } =
    React.useContext(CashierContext);

  return (
    <div className="flex flex-row gap-4 items-center justify-center">
      <div className="flex flex-col gap-2 items-center justify-center">
        <label className="text-gray-500 font-bold">Start Date</label>
        <Datepick selectedDate={selectedStartDate} setSelectedDate={setSelectedStartDate} />
      </div>
      <div className="flex flex-col gap-2 items-center justify-center">
        <label className="text-gray-500 font-bold">End Date</label>
        <Datepick selectedDate={selectedEndDate} setSelectedDate={setSelectedEndDate} />
      </div>
    </div>
  );
}
