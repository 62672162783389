import React, { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import MenuItem from './MenuItem';
import { AuthContext } from '../../context/AuthProvider';
import { sortMenusByRank } from '../../utils/categorySorter';

export default function MenuTab() {
  const { menus, setMenus, categories } = useContext(MenuContext);
  const [filteredMenus, setFilteredMenus] = useState(menus);
  const [search, setSearch] = useState('');
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);
  useEffect(() => {
    AuthenticatedFetch(`menus`)
      .then((res) => res.json())
      .then((data) => setMenus(sortMenusByRank(data, false)));
  }, []);

  useEffect(() => {
    setFilteredMenus(menus);
    if (search) {
      setFilteredMenus(
        menus.filter((menu) => {
          return menu.name.toLowerCase().includes(search.toLowerCase());
        })
      );
    } else {
      setFilteredMenus(menus);
    }
  }, [menus, search]);

  return (
    <div>
      <div className='grid place-items-end mt-5'>
        <button
          disabled={!decideIfRoleHasAccess(scopesEnum.CREATE_MENU)}
          className="btn btn-primary"
          onClick={() => document.getElementById('menu_modal').showModal()}>
          Add New Menu
        </button>
      </div>
      <div className="grid place-items-center mt-5">
        <div className="overflow-x-scroll w-3/4">
          <div className="flex my-5 gap-1">
            <button className="btn btn-accent" onClick={() => setFilteredMenus(menus)}>
              All
            </button>
            {categories.map((category) => (
              <button
                key={category?.id}
                className="btn btn-accent"
                onClick={() => {
                  setFilteredMenus(
                    menus.filter((menu) => {
                      return menu?.category?.name === category?.name;
                    })
                  );
                }}>
                {category.name}
              </button>
            ))}
          </div>
        </div>
        <div className="overflow-x-auto mt-10 w-3/4">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="input input-bordered w-full mb-5"
            type="text"
            placeholder="Search"
          />
          <table className="table">
            <thead>
              <tr>
                <th>Menu</th>
                <th>Available?</th>
                <th>Offline Price</th>
                <th>Online Price</th>
              </tr>
            </thead>
            <tbody>
              {filteredMenus.map((menu) => (
                <MenuItem key={menu?.id} menuProp={menu} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
