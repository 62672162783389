import React, { useEffect } from 'react';
import { CashierOrderProvider } from '../../../../context/CashierOrderProvider';
import InfoBar from './InfoBar';
import OrderInformationBar from './OrderInformationBar';
import OrderNotesBar from './OrderNotesBar';
import MenuSegment from './MenuSegment';
import OrderModalBox from './OrderModalBox';
import ConfirmationModal from './ConfirmationModal';
import Breadcrumbs from './Breadcrumbs';

export default function CashierOrderPage() {
  return (
    <CashierOrderProvider>
      <div className="flex min-h-screen items-center flex-col px-2 py-4">
        <div className="card md:w-[90%] bg-base-100 shadow-xl">
          <Breadcrumbs />
          <div className="card-body md:gap-8 sm:gap-6 gap-4 justify-between flex lg:flex-row flex-col w-full items-stretch">
            <div className="flex flex-1 flex-col md:gap-4 gap-2">
              <OrderInformationBar />
              <OrderNotesBar />
              <MenuSegment />
            </div>
            <InfoBar />
          </div>
        </div>
        <OrderModalBox />
        <ConfirmationModal />
      </div>
    </CashierOrderProvider>
  );
}
