import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import MenuList from './MenuList';

export default function MenuSegment() {
  const { categories, order } = React.useContext(CashierOrderContext);

  const [category, setSelectedCategory] = React.useState();
  const [activateTab, setActivateTab] = React.useState(false);

  const handleChange = (_, newValue) => {
    setSelectedCategory(newValue);
  };

  React.useEffect(() => {
    if (categories.length > 0) {
      setTimeout(() => {
        setActivateTab(true);
      }, 100);
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  return (
    <div className="w-full">
      <div className="flex flex-col gap-1">
        <div className="text-sm font-semibold">Menu List</div>
        <div className="h-1 bg-base-200 w-32"></div>
      </div>
      <div className="w-full flex flex-col items-center">
        {categories.length > 0 && category && (
          <Box className="justify-center xl:max-w-2xl lg:max-w-lg md:max-w-2xl sm:max-w-sm max-w-xs">
            <Tabs
              value={category}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto">
              {activateTab &&
                categories.map((category, index) => (
                  <Tab
                    key={index}
                    className="!font-bold !capitalize"
                    label={category.name}
                    value={category}
                  />
                ))}
            </Tabs>
          </Box>
        )}
        {category && category.menus && (
          <MenuList menus={category.menus} isOffline={order.type != 'ONLINE'} />
        )}
      </div>
    </div>
  );
}
