import React, { useContext, useEffect, useState } from 'react';
import { OperationContext } from '../../context/OperationContext';
import { SelectedTableContext } from '../../pages/admin/operation/OperationPage';
import { triggerToast } from '../../utils/toast';
import { AuthenticatedFetch } from '../../lib';
import { CircularProgress } from '@mui/material';

export default function EmptyTableModal() {
    const { tables, setTables } = useContext(OperationContext);
    const { selectedTable } = useContext(SelectedTableContext);
    const [unpaidError, setUnpaidError] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(selectedTable?.Order?.[0]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setSelectedOrder(selectedTable?.Order?.[0]);
    }, [selectedTable])

    return (
        <dialog id="empty_table_modal" className="modal">
            <div className="modal-box">
                <h3 className="font-bold text-lg">{unpaidError ? "ERROR" : "Kosongkan Meja"}</h3>
                {unpaidError ? (
                    <p>Order belum dibayar silahkan hubungi kasir</p>
                ) : (<>
                    <p htmlFor="table">Table: <span>{selectedTable?.tableNumber}</span></p>
                    <label htmlFor="order">Order : </label>
                    <select onChange={(e) => {
                        const order = selectedTable?.Order?.find(order => order.id === e.target.value);
                        if (!order) {
                            return;
                        }
                        setSelectedOrder(order)
                    }}>
                        <option value="">Pilih pelanggan</option>
                        {
                            selectedTable?.Order?.map((order, index) => (
                                <option selected={index == 0} key={index} value={order.id}>{order.customerName} - {order.id}</option>
                            ))
                        }
                    </select>
                </>)}
                <div className="modal-action">
                    <button
                        disabled={isLoading}
                        onClick={async () => {
                            try {
                                setIsLoading(true);

                                if (selectedOrder?.status !== "PAID") {
                                    return setUnpaidError(true);
                                }
                                try {
                                    const res = await AuthenticatedFetch(`table/unseat/${selectedTable.tableNumber}/${selectedOrder.id}`);
                                    const data = await res.json();
                                    setTables(tables.map(table => table.id === selectedTable.id ? data : table));
                                    document.getElementById("empty_table_modal").close();
                                    triggerToast("success", "Table emptied")
                                } catch {
                                    triggerToast("error", "Failed to empty table")
                                }
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                        className='button-with-loading btn btn-primary'>
                        {isLoading && <CircularProgress />}
                        Submit
                    </button>
                    <form method="dialog">
                        <button onClick={() => setUnpaidError(false)} className="btn">Close</button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}
