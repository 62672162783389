import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthenticatedFetch } from '../../lib';
import { OrderContext } from '../../contexts/OrderContext';
import { triggerToast } from '../../utils';

export default function OrderPage() {
  const navigate = useNavigate();
  const { uid } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      customerName: undefined,
      numberOfPeople: undefined,
      tableNumber: undefined,
      status: undefined,
    }
  });

  useEffect(() => {
    async function fetchOrder() {
      const order = await AuthenticatedFetch(`order/${uid}`);
      const orderData = await order.json();

      reset({
        customerName: orderData.customerName,
        numberOfPeople: orderData.numberOfPeople,
        tableNumber: orderData.tableNumber,
        status: orderData.status,
      });

      if (orderData.customerName !== null || orderData.numberOfPeople !== null) {
        let url = window.location.href
        while (url.endsWith('/')) {
          url = url.slice(0, -1);
        }
        url = url + "/menu";
        window.location.replace(url);
      }
    }

    fetchOrder();
  }, []);

  async function onSubmit(data) {
    let { customerName, numberOfPeople } = data;
    numberOfPeople = parseInt(data.numberOfPeople, 10);
    
    const res = await AuthenticatedFetch(`order/${uid}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customerName,
        numberOfPeople
      })
    });

    if (res.status < 400) {
      navigate(`menu`);
    } else {
      const errMessage = await res.text();
      triggerToast('error', errMessage);
    }
  }

  const { setName } = useContext(OrderContext);

  if (getValues("status") === 'PAID') {
    return (
      <div className="flex items-center justify-center h-screen w-screen font-inter bg-white">
        Order Sudah Selesai
      </div>)
  }

  const handleIntegerInput = (event) => {
    const newValue = event.target.value;
    const parsedValue = parseInt(newValue, 10);

    if (!isNaN(parsedValue)) {
      event.target.value = parsedValue;
    } else if (newValue === "") {
      event.target.value = "";
    } else {
      event.target.value = event.target.value.slice(0, -1);
    }
    setValue('numberOfPeople', event.target.value)
  };

  return (
    <div className="w-full bg-gray-500 flex justify-center h-full font-inter">
      <form
        className="w-full bg-white max-w-[425px] h-full flex flex-col items-center justify-center p-5"
        onSubmit={handleSubmit(onSubmit)}>
        <img src="/siantar.png" alt="logo" className="my-5" />
        <p className="font-bold mmd:text-base text-sm">Selamat Datang di</p>
        <p className="font-bold mmd:text-base text-sm mb-5">Bakmie Siantar Paus Rawamangun</p>

        <input
          placeholder="Nama"
          type="text"
          className="w-full mmd:text-base text-xs border p-2 border-gray-400 rounded-md"
          {...register('customerName', {
            required: true
          })}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.customerName && <span>This field is required</span>}
        <input
          placeholder="Jumlah Orang"
          type="number" // Set type to "number" for numeric input
          className="w-full mmd:text-base text-xs mt-5 p-2 border border-gray-400 rounded-md"
          {...register('numberOfPeople', {required: true
          })}
          onChange={handleIntegerInput} // Pass the handler function
        />
        {errors.numberOfPeople && <span>This field is required</span>}
        <div className="bg-gray-300 rounded-sm mt-5 w-full px-3 py-2 mmd:text-base text-xs">{getValues("tableNumber")}</div>

        <button type="submit" className="bg-[#6888B3] text-white mt-10 rounded-lg px-5 py-2 mmd:text-base text-xs">
          Masuk
        </button>
      </form>
    </div>
  );
}
