import React, { useContext } from 'react';
import { RECORDS_PER_PAGE } from '../../../lib';
import { DashboardContext } from '../../../context/DashboardProvider';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

export default function ListOrder() {
  const { orders, setSelectedOrder } = useContext(DashboardContext);
  const [tablePage, setTablePage] = React.useState(1);
 // 0 = default , 1 = sort asc, 2 sort descending
 const [sortByName, setSortByName] = React.useState(0);
 const [sortByPrice, setSortByPrice] = React.useState(0);

 const filteredOrders = React.useMemo(() => {
   if (!orders) return [];
   let sorted = [...orders];
   if (sortByName !== 0) {
     sorted = sorted.sort((a, b) => {
       const nameA = a.customerName ? a.customerName.toLowerCase() : '';
       const nameB = b.customerName ? b.customerName.toLowerCase() : '';
       if (nameA < nameB) return sortByName === 1 ? -1 : 1;
       if (nameA > nameB) return sortByName === 1 ? 1 : -1;
       return 0;
     });
   }

   if (sortByPrice !== 0) {
     sorted = sorted.sort((a, b) => {
       if (a.totalPrice < b.totalPrice) return sortByPrice === 1 ? -1 : 1;
       if (a.totalPrice > b.totalPrice) return sortByPrice === 1 ? 1 : -1;
       return 0;
     });
   }
   return sorted.slice((tablePage - 1) * RECORDS_PER_PAGE, tablePage * RECORDS_PER_PAGE);
 }, [orders, tablePage, sortByName, sortByPrice ]);

 const handleSort = (type) => {
   if (type === 'name') {
     setSortByPrice(0); // Reset price sort
     setSortByName((prev) => (prev === 2 ? 0 : prev + 1));
   } else if (type === 'price') {
     setSortByName(0); // Reset name sort
     setSortByPrice((prev) => (prev === 2 ? 0 : prev + 1));
   }
 };

  return (
    <div className="w-full min-w-full overflox-x-auto">
      <table className="table sm:table-md table-xs min-w-full">
        {/* head */}
        <thead>
          <tr className="font-semibold md:text-base sm:text-sm text-xs">
            <th>No</th>
            <th onClick={() => handleSort('name')}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              Name
              {sortByName === 0 && <FaSort />}
              {sortByName === 1 && <FaSortUp />}
              {sortByName === 2 && <FaSortDown />}
              </span>
            </th>
            <th className="sm:table-cell hidden">No Meja</th>
            <th onClick={() => handleSort('price')}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              Total
              {sortByPrice === 0 && <FaSort />}
              {sortByPrice === 1 && <FaSortUp />}
              {sortByPrice === 2 && <FaSortDown />}
              </span>
            </th>
            <th className="sm:table-cell hidden">Status</th>
            <th className="sm:table-cell hidden">Created At</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr
              key={order.id}
              className="hover cursor-pointer"
              onClick={() => {
                setSelectedOrder(order);
              }}>
              <td className="">{order.id}</td>
              <td className="">{order.customerName}</td>
              <td className="sm:table-cell hidden">
                {order.type == 'DINE_IN'
                  ? order.tableNumber
                  : order.type == 'TAKE_AWAY'
                    ? 'Take Away'
                    : 'Online'}
              </td>
              <td className="">Rp.{order.totalPrice.toLocaleString('de-DE')}</td>
              <td className="sm:table-cell hidden">
                {order && order.status && order.status.slice(0, 1) + order.status.slice(1).toLowerCase()}
              </td>
              <td className="sm:table-cell hidden">
                {new Date(order.createdAt).toLocaleString('id')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="join py-2">
        {orders && orders.length > 0 && (
          <>
            {Array.from({ length: Math.ceil(orders.length / RECORDS_PER_PAGE) }, (_, index) => (
              <button
                key={`table-page-${index}`}
                className={`join-item btn ${tablePage == index + 1 ? 'btn-active' : ''}`}
                onClick={() => setTablePage(index + 1)}>
                {index + 1}
              </button>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
