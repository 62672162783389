import React from 'react';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import { useForm } from 'react-hook-form';
import { scopesEnum } from '../../../../lib';
import { AuthContext } from '../../../../context/AuthProvider';
import { triggerToast } from '../../../../utils';

export default function OrderNotesBar() {
  const { order, handleUpdate } = React.useContext(CashierOrderContext);

  const {
    register,
    watch,
    reset,
    formState: { dirtyFields }
  } = useForm({
    defaultValues: {
      description: order.description
    }
  });

  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  React.useEffect(() => {
    reset({
      description: order.description
    });
  }, [order.description, reset]);

  function validateRole() {
    if (order.state == 'UNPLACED' && !decideIfRoleHasAccess(scopesEnum.UPDATE_UNPLACED_ORDER)) {
      triggerToast('error', 'You are not allowed to update order!');

      return false;
    }

    if (order.state == 'PLACED' && !decideIfRoleHasAccess(scopesEnum.UPDATE_PLACED_ORDER)) {
      triggerToast('error', 'You are not allowed to update order!');

      return false;
    }

    return true;
  }

  return (
    <div className="w-full md:-mt-4">
      <div className="flex flex-col gap-1">
        <div className="text-sm font-semibold">Order Description</div>
        <div className="h-1 bg-base-200 w-32"></div>
      </div>
      <label className="form-control w-full">
        <span className="label text-sm">Description</span>
        <input
          className="input input-bordered w-full font-semibold"
          placeholder="Order Description"
          {...register('description')}
          onBlur={async () => {
            if (dirtyFields.description) {
              handleUpdate('description', watch('description'), 'Order Description', () => {
                return validateRole();
              });
            }
          }}
        />
      </label>
    </div>
  );
}
