import React, { useContext, useState } from 'react';
import { MenuContext, UpdateMenuContext } from '../../contexts/MenuContext';
import { OptionContext } from '../../contexts/OptionContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { handleInputChange } from '../../utils/handleInputChange';
import { triggerToast } from '../../utils/toast';
import OptionItem from './OptionItem';
import { AuthContext } from '../../context/AuthProvider';
import { FaTimes } from "react-icons/fa";

export default function AddonUpdateModal() {
  const { addons, setAddons } = useContext(MenuContext);
  const { addon, setAddon } = useContext(UpdateMenuContext);
  const [selected, setSelected] = useState(0);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return (
    <dialog id="addon_update_modal" className="modal">
      <div className="modal-box">
        <div className="modal-action mt-0">
          <form method="dialog">
            <button className="btn"><FaTimes /></button>
          </form>
        </div>
        <h3 className="font-bold text-lg">Update {addon.name} Addon</h3>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_ADDONS)) {
              triggerToast('error', 'You are not allowed to update addon!');
              return;
            }
            const res = await AuthenticatedFetch(`addons/${addon.id}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                name: addon.name,
                deleted: false,
                deletedAt: null
              })
            });
            if (!res.ok) {
              const data = await res.text();
              triggerToast('error', data);
              return;
            }
            const data = await res.json();
            setAddons(
              addons.map((addon) => {
                if (addon.id === data.id) {
                  return data;
                }
                return addon;
              })
            );
            setAddon({
              name: ''
            });
            document.getElementById('addon_update_modal').close();
          }}
          className="form-control m-3">
          <label className="label-text" htmlFor="name">
            Name
          </label>
          <input
            value={addon.name}
            onChange={(e) => handleInputChange(e, setAddon)}
            className="mb-10 input input-bordered"
            type="text"
            name="name"
            id="name"
          />
          <button className="btn btn-primary">Update</button>
        </form>
        <h1 className="text-lg font-bold my-5">Options</h1>
        <form
          className="form-control"
          onSubmit={async (e) => {
            e.preventDefault();
            const res = await AuthenticatedFetch(`options`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                name: e.target.name.value,
                priceOnline: parseInt(e.target.onlinePrice.value),
                price: parseInt(e.target.price.value),
                addonId: addon.id,
                default: false
              })
            });
            e.target.name.value = '';
            e.target.price.value = '';
            e.target.onlinePrice.value = '';
            if (!res.ok) {
              const data = await res.text();
              triggerToast('error', data);
              return;
            }
            const data = await res.json();
            setAddon((addon) => ({
              ...addon,
              options: [...addon.options, data]
            }));
            setAddons(
              addons.map((addon) => {
                if (addon.id === data.addonId) {
                  return {
                    ...addon,
                    options: [...addon.options, data]
                  };
                }
                return addon;
              })
            );
            document.getElementById('addon_update_modal').close();
          }}>
          <label htmlFor="name" className="label-text">
            Name
          </label>
          <input type="text" name="name" id="name" className="input input-bordered mb-3" />
          <label htmlFor="price" className="label-text">
            Price (Offline)
          </label>
          <input type="number" name="price" id="price" className="input input-bordered mb-3" />
          <label htmlFor="onlinePrice">Price (Online)</label>
          <input
            type="number"
            name="onlinePrice"
            id="onlinePrice"
            className="input input-bordered mb-3"
          />
          <input className="btn btn-primary w-full my-5" type="submit" value="Add Option" />
        </form>
        <div className="overflow-x-scroll w-full">
          <table className="table">
            <thead>
              <tr>
                <th>Default?</th>
                <th>Name</th>
                <th>Offline Price</th>
                <th>Online Price</th>
              </tr>
            </thead>
            <OptionContext.Provider value={{ selected, setSelected }}>
              <tbody>
                {addon.options?.map((option) => (
                  <OptionItem key={option.id} id={option.id} optionProp={option} />
                ))}
              </tbody>
            </OptionContext.Provider>
          </table>
        </div>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn">Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
}
