import React, { useContext, useEffect } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { OrderContext } from '../../contexts/OrderContext';
import { useParams } from 'react-router-dom';
import { triggerToast } from '../../utils';
import { AuthenticatedFetch } from '../../lib';
import { CircularProgress } from '@mui/material';

export default function OrderTally() {
  const navigate = useNavigate();

  const { order, setOrder, setTotal, total, setBill, bill } = useContext(OrderContext);
  const [realOrder, setRealOrder] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });
  const { uid } = useParams();

  useEffect(() => {
    async function fetchData() {
      const order = await AuthenticatedFetch(`order/${uid}`);
      const res = await fetch(`${process.env.REACT_APP_API_URL}/order/${uid}`)
      if (!res.ok) {
        triggerToast('error', await res.text());
        navigate('/order');
      }

      const orderData = await order.json();

      setRealOrder(orderData);
    }

    fetchData();
  }, []);

  async function handlePlaceOrder() {
    const orderItems = order.map((o) => {
      return {
        menuId: o.id,
        quantity: o.quantity,
        price:
          (o.offlinePrice + o?.addons?.reduce((total, addon) => total + addon.price, 0)) *
          o.quantity,
        type: o.takeAway ? 'TAKE_AWAY' : 'DINE_IN',
        notes: o.notes,
        addons: o.addons.map((addon) => {
          return {
            addonId: addon.addonId,
            optionId: addon.id
          };
        })
      };
    });

    const orders = await AuthenticatedFetch(`order/${uid}/order-item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        orderItems: orderItems
      })
    });

    if (orders.status < 400) {
      return;
    }

    console.log(await orders.text());
  }

  const { name, tableNumber } = useContext(OrderContext);

  function handleChange(orderId, newQuantity) {
    newQuantity = newQuantity || 0;
    if (newQuantity < 0) newQuantity = 0;
    let newOrder = order
      .filter((order) => order.orderId !== orderId || newQuantity > 0)
      .map((order) => {
        if (order.orderId === orderId) {
          return { ...order, quantity: newQuantity };
        }
        return order;
      });

    setOrder(newOrder);

    setTotal(
      newOrder.reduce(
        (total, order) =>
          total +
          order.quantity *
          (order.offlinePrice + order?.addons?.reduce((total, addon) => total + addon.price, 0)),
        0
      )
    );
  }

  return (
    <div className="w-full overflow-x-hidden bg-gray-500 relative flex justify-center h-full font-inter">
      <div className="min-h-screen w-full relative bg-white max-w-[425px] h-full flex flex-col items-center p-3">
        <div className="relative w-full mb-5">
          <FaChevronLeft
            onClick={() => {
              navigate(-1);
            }}
            className="absolute cursor-pointer ml-2 mt-1 left-0 text-2xl text-[#6888B2]"
          />
          <h1 className="text-[20px] font-bold text-center mmd:text-base text-sm">Pesanan</h1>
          <h1 className="text-[20px] font-bold text-center mmd:text-base text-sm">{name} - Table {tableNumber}</h1>
        </div>
        <table className="table mb-48">
          <thead>
            <tr>
              <th>Menu</th>
              <th className="text-right pr-8">Harga</th>
            </tr>
          </thead>
          <tbody>
            {order.map((o, i) => (
              <tr key={i}>
                <td>
                  <h1 className="mmd:text-sm text-xs text-wrap">{o.name}</h1>
                  <p className="text-xs text-wrap">
                    {o.addons.map((addon) => addon.name).join(', ')}
                  </p>
                  <p className="text-xs text-wrap">Notes: {o.notes || 'N/A'}</p>
                  {o.takeAway && <p className="text-xs text-wrap">TAKE AWAY</p>}
                </td>
                <td className="pl-0 pr-0">
                  <div className="flex flex-col items-end justify-center gap-3 my-3">
                    <div className="flex items-center gap-2">
                      <button
                        className="font-bold mmd:text-base text-xs text-white bg-[#6888B2] mmd:w-7 w-4 rounded-full text-center mmd:h-7 h-4"
                        onClick={() => handleChange(o.orderId, o.quantity - 1)}
                      >
                        -
                      </button>
                      <input
                        type="int"
                        style={{ width: "3rem" }}
                        className="border-[#6888B2] mmd:text-base text-xs border rounded-md text-center"
                        value={o.quantity}
                        onChange={(e) => {
                          handleChange(o.orderId, parseInt(e.target.value));
                        }}
                      />
                      <button
                        className="font-bold mmd:text-base text-xs text-white bg-[#6888B2] mmd:w-7 w-4 rounded-full text-center mmd:h-7 h-4"
                        onClick={() => handleChange(o.orderId, o.quantity + 1)}
                      >
                        +
                      </button>
                    </div>
                    <p className='mmd:text-base text-xs'>
                      {IDR.format(
                        (o.offlinePrice +
                          o?.addons?.reduce((total, addon) => total + addon.price, 0)) *
                        o.quantity,
                        0
                      )}
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <footer className="fixed max-w-[425px] border-t-2 bottom-0 p-5 items-center gap-5 w-full bg-white">
          <div className="flex justify-between items-center">
            <p className="font-bold mmd:text-base text-sm">Total</p>
            <p className='mmd:text-base text-sm'>{IDR.format(total)}</p>
          </div>
          <button
          disabled={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                setBill((bill) => [...bill, ...order.filter((order) => order.quantity !== 0)]);
                setOrder([]);
                await handlePlaceOrder();
                navigate(`/order/${uid}/bill`);
              } finally {
                setIsLoading(false);
              }
            }}
            className="button-with-loading bg-[#6888B2] w-full mt-2 text-white mmd:text-base text-sm btn">

            {isLoading && <CircularProgress />}
            Tambah Pesanan
          </button>
        </footer>
      </div>
    </div>
  );
}
