import React, { useContext, useEffect, useState } from 'react';
import { AuthenticatedFetch } from '../../lib';
import { triggerToast } from '../../utils/toast';
import { SelectedTableContext } from '../../pages/admin/operation/OperationPage';
import { CircularProgress } from '@mui/material';

export default function ReprintModal({ selectedOrderFromCashier, printBill }) {
    const { selectedTable } = useContext(SelectedTableContext)
    const [selected, setSelected] = useState(false);
    const [printerLocation, setPrinterLocation] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(selectedTable?.Order?.[0]);
    const [isLoading, setIsLoading] = useState(false);

    function reset() {
        setSelectedOrder(null)
        document.getElementById("reprint_modal").close()
        document.getElementById("finished_print_modal").show()
    }

    useEffect(() => {
        const fetchPrinterLocation = async () => {
            const res = await AuthenticatedFetch('printer-location');
            const data = await res.json();
            setPrinterLocation(data);
        }
        fetchPrinterLocation();
    }, []);

    const color = () => !selected ? "btn-success" : "btn-neutral";

    useEffect(() => {
        setSelectedOrder(selectedTable?.Order?.[0]?.id);
    }, [selectedTable]);

    return (
        <dialog id="reprint_modal" className="modal">
            <div
                className="modal-box"
                style={{ maxWidth: selectedOrderFromCashier == null ? '32rem' : '44rem' }}
            >
                <h3 className="font-bold text-lg">
                    Print Ulang Document
                    {selectedOrderFromCashier &&
                        <span> - {selectedOrderFromCashier.id}</span>
                    }
                </h3>

                {!selectedOrderFromCashier &&
                    <>
                        <label htmlFor="table" className='block'>Table : <span>{selectedTable?.tableNumber}</span></label>

                        <label htmlFor="order">Order : </label>
                        <select onChange={(e) => {
                            setSelectedOrder(e.target.value)
                        }}>
                            <option value="">Pilih pelanggan</option>
                            {
                                selectedTable?.Order?.map((order, index) => (
                                    <option selected={index == 0} key={index} value={order.id}>{order.customerName} - {order.id}</option>
                                ))
                            }
                        </select>
                    </>
                }

                <div className='flex mt-5 justify-between'>
                    <button
                        disabled={selected || isLoading}
                        onClick={async () => {
                            try {
                                let orderId = selectedOrder || selectedOrderFromCashier?.id;
                                const response = await AuthenticatedFetch(`document-queue/create-qr/${orderId}`, {
                                    method: "POST"
                                })
                                if (!response.ok) {
                                    triggerToast("error", "Failed to print menu")
                                    return
                                }
                                reset()
                            } catch (e) {
                                triggerToast("error", "Failed to print menu")
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                        className={`button-with-loading text-white w-[150px] btn ${color()}`}
                    >
                        {isLoading && <CircularProgress />}
                        QR Menu
                    </button>

                    <button
                        onClick={() => setSelected(!selected)}
                        className='button-with-loading text-white w-[150px] btn btn-success'
                    >
                        {isLoading && <CircularProgress />}Captain Order
                    </button>

                    <button
                        disabled={selected || isLoading}
                        onClick={async () => {
                            try {
                                let orderId = selectedOrder || selectedOrderFromCashier?.id;
                                const response = await AuthenticatedFetch(`document-queue/checker/${orderId}/final`, {
                                    method: "POST"
                                })
                                if (!response.ok) {
                                    triggerToast("error", "Failed to print menu")
                                    return
                                }
                                await response.json()
                                reset()
                            } catch {
                                triggerToast("error", "Failed to print menu")
                            } finally {
                                setIsLoading(false);
                            }
                        }}
                        className={`button-with-loading text-white w-[150px] btn ${color()}`}
                    >
                        {isLoading && <CircularProgress />}
                        Checker
                    </button>

                    {selectedOrderFromCashier && selectedOrderFromCashier.status == 'PAID' &&
                        <button
                            className={`button-with-loading text-white w-[150px] btn ${color()}`}
                            onClick={() => {
                                try {
                                    setIsLoading(true);
                                    console.log()

                                    return printBill(selectedOrderFromCashier.id);
                                } finally {
                                    setIsLoading(false);
                                }
                            }}
                        >
                            {isLoading && <CircularProgress />}
                            Print Ulang Bill
                        </button>}
                </div>

                {selected && (
                    <div className='flex mt-5 justify-start gap-2 flex-wrap'>
                        {
                            printerLocation.map((printer, index) => (
                                <button
                                    key={index}
                                    disabled={isLoading}
                                    onClick={async () => {
                                        try {
                                            setIsLoading(true);

                                            let orderId = selectedOrder || selectedOrderFromCashier?.id;
                                            const response = await AuthenticatedFetch(`document-queue/captain-order/${orderId}/${printer.id}`, {
                                                method: "POST"
                                            })
                                            reset()
                                        } catch (e) {
                                            triggerToast("error", "Failed to print!", e)
                                        } finally {
                                            setIsLoading(false);
                                        }
                                    }}
                                    className={`button-with-loading text-white w-[150px] btn btn-success`}>
                                    {isLoading && <CircularProgress size={20} />}
                                    {printer.name}</button>
                            ))
                        }
                    </div>
                )}
                <div className="modal-action">
                    <form method="dialog">
                        <button className="btn">Close</button>
                    </form>
                </div>
            </div>
        </dialog >
    );
}