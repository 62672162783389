import React, { useEffect, useState } from 'react';
import AddonTab from '../components/addon/AddOnTab';
import AddonCreateModal from '../components/addon/AddonCreateModal';
import AddonDeleteModal from '../components/addon/AddonDeleteModal';
import AddonUpdateModal from '../components/addon/AddonUpdateModal';
import CategoryCreateModal from '../components/category/CategoryCreateModal';
import CategoryDeleteModal from '../components/category/CategoryDeleteModal';
import CategoryTab from '../components/category/CategoryTab';
import CategoryUpdateModal from '../components/category/CategoryUpdateModal';
import MenuCreateModal from '../components/menu/MenuCreateModal';
import MenuDeleteModal from '../components/menu/MenuDeleteModal';
import MenuTab from '../components/menu/MenuTab';
import MenuUpdateModal from '../components/menu/MenuUpdateModal';
import { MenuContext, UpdateMenuContext } from '../contexts/MenuContext';
import { AuthenticatedFetch } from '../lib';

export default function MenuPage() {
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menu, setMenu] = useState({});
  const [category, setCategory] = useState({});
  const [addons, setAddons] = useState([]);
  const [addon, setAddon] = useState({});
  const [printers, setPrinters] = useState([]);

  useEffect(() => {
    AuthenticatedFetch(`printer-location`)
      .then((res) => res.json())
      .then((data) => setPrinters(data));
  }, []);

  return (
    <MenuContext.Provider
      value={{
        menus,
        setMenus,
        categories,
        setCategories,
        addons,
        setAddons,
        printers,
        setPrinters
      }}>
      <UpdateMenuContext.Provider value={{ menu, setMenu, category, setCategory, addon, setAddon }}>
        <h1 className="text-center mt-5 font-bold text-xl">Menu Page</h1>
        <div role="tablist" className="tabs tabs-lifted m-5">
          <input
            type="radio"
            name="menu_tabs"
            role="tab"
            className="tab"
            aria-label="Menus"
            defaultChecked
          />
          <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
            <MenuTab />
          </div>

          <input type="radio" name="menu_tabs" role="tab" className="tab" aria-label="Add-ons" />
          <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
            <AddonTab />
          </div>

          <input type="radio" name="menu_tabs" role="tab" className="tab" aria-label="Categories" />
          <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
            <CategoryTab />
          </div>
        </div>
        <MenuCreateModal />
        <MenuUpdateModal />
        <MenuDeleteModal />
        <CategoryCreateModal />
        <CategoryUpdateModal />
        <CategoryDeleteModal />
        <AddonCreateModal />
        <AddonUpdateModal />
        <AddonDeleteModal />
      </UpdateMenuContext.Provider>
    </MenuContext.Provider>
  );
}
