import React, { useContext } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { FaRegTrashCan } from 'react-icons/fa6';
import { CashierContext } from '../../../context/CashierProvider';
import { Link } from 'react-router-dom';
import { RECORDS_PER_PAGE, scopesEnum } from '../../../lib';
import { AuthContext } from '../../../context/AuthProvider';
import { triggerToast } from '../../../utils';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

export default function PlacedOrder() {
  const { orders, setSelectedOrder, setIsOrderToBeDeleted } = useContext(CashierContext);
  const [tablePage, setTablePage] = React.useState(1);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);
  // 0 = default , 1 = sort asc, 2 sort descending
  const [sortByName, setSortByName] = React.useState(0);
  const [sortByPrice, setSortByPrice] = React.useState(0);

  const filteredOrders = React.useMemo(() => {
    if (!orders) return [];
    let sorted = [...orders];
    if (sortByName !== 0) {
      sorted = sorted.sort((a, b) => {
        const nameA = a.customerName ? a.customerName.toLowerCase() : '';
        const nameB = b.customerName ? b.customerName.toLowerCase() : '';
        if (nameA < nameB) return sortByName === 1 ? -1 : 1;
        if (nameA > nameB) return sortByName === 1 ? 1 : -1;
        return 0;
      });
    }

    if (sortByPrice !== 0) {
      sorted = sorted.sort((a, b) => {
        if (a.totalPrice < b.totalPrice) return sortByPrice === 1 ? -1 : 1;
        if (a.totalPrice > b.totalPrice) return sortByPrice === 1 ? 1 : -1;
        return 0;
      });
    }
    return sorted.slice((tablePage - 1) * RECORDS_PER_PAGE, tablePage * RECORDS_PER_PAGE);
  }, [orders, tablePage, sortByName, sortByPrice ]);

  const handleSort = (type) => {
    if (type === 'name') {
      setSortByPrice(0); // Reset price sort
      setSortByName((prev) => (prev === 2 ? 0 : prev + 1));
    } else if (type === 'price') {
      setSortByName(0); // Reset name sort
      setSortByPrice((prev) => (prev === 2 ? 0 : prev + 1));
    }
  };

  return (
    <div style={{ maxHeight: '50vh' }} className="overflow-y-auto w-full min-w-full overflox-x-auto">
      <div className="join py-2">
        {orders.length > 0 && (
          <>
            {Array.from({ length: Math.ceil(orders.length / RECORDS_PER_PAGE) }, (_, index) => (
              <button
                key={`table-page-${index}`}
                className={`join-item btn ${tablePage == index + 1 ? 'btn-active' : ''}`}
                onClick={() => setTablePage(index + 1)}>
                {index + 1}
              </button>
            ))}
          </>
        )}
      </div>
      <table className="table sm:table-md table-xs min-w-full !text-[1rem]">
        {/* head */}
        <thead>
          <tr className="font-semibold md:text-base text-[1rem]">
            <th>No</th>
            <th onClick={() => handleSort('name')}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              Name
              {sortByName === 0 && <FaSort />}
              {sortByName === 1 && <FaSortUp />}
              {sortByName === 2 && <FaSortDown />}
              </span>
            </th>
            <th className="sm:table-cell hidden">No Meja</th>
            <th onClick={() => handleSort('price')}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              Total
              {sortByPrice === 0 && <FaSort />}
              {sortByPrice === 1 && <FaSortUp />}
              {sortByPrice === 2 && <FaSortDown />}
              </span>
            </th>
            <th className="sm:table-cell hidden">Status</th>
            <th className="sm:table-cell hidden">Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr
              key={order.id}
              className="hover cursor-pointer text-[1rem]"
              onClick={() => {
                setSelectedOrder(order);
              }}>
              <td className="text-[1rem]">{order.id}</td>
              <td className="text-[1rem]">{order.customerName}</td>
              <td className="sm:table-cell hidden text-[1rem]">
                {order.type == 'DINE_IN'
                  ? order.tableNumber
                  : order.type == 'TAKE_AWAY'
                    ? 'Take Away'
                    : 'Online'}
              </td>
              <td className="text-[1rem]">Rp.{order.totalPrice.toLocaleString('de-DE')}</td>
              <td className="sm:table-cell hidden text-[1rem]">
                {order.status.slice(0, 1) + order.status.slice(1).toLowerCase()}
              </td>
              <td className="sm:table-cell hidden text-[1rem]">
                {new Date(order.createdAt).toLocaleString('id')}
              </td>
              <td className="flex flex-row gap-2 text-[1rem]">
                {(order.status == "UNPAID" && !decideIfRoleHasAccess(scopesEnum.UPDATE_UNPLACED_ORDER)) ||
                  (order.status == "PAID" && !decideIfRoleHasAccess(scopesEnum.UPDATE_PAID_ORDER)) ||
                  <Link to={`/cashier/${order.id}`} target="_blank">
                    <FaRegEdit className="cursor-pointer sm:w-6 sm:h-6 w-4 h-4" />
                  </Link>
                }

                {(order.status == "UNPAID" && !decideIfRoleHasAccess(scopesEnum.DELETE_UNPLACED_ORDER)) ||
                  (order.status == "PAID" && !decideIfRoleHasAccess(scopesEnum.DELETE_PAID_ORDER)) ||
                  <FaRegTrashCan
                    className="cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
                    onClick={async () => {
                      if (!decideIfRoleHasAccess(scopesEnum.CANCEL_ORDER)) {
                        triggerToast('error', 'You do not have access to cancel order');
                        return;
                      }

                      setSelectedOrder(order);
                      setIsOrderToBeDeleted(true);
                      document.querySelector('#confirmation-modal').showModal();
                    }}
                  />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="join py-2">
        {orders.length > 0 && (
          <>
            {Array.from({ length: Math.ceil(orders.length / RECORDS_PER_PAGE) }, (_, index) => (
              <button
                key={`table-page-${index}`}
                className={`join-item btn ${tablePage == index + 1 ? 'btn-active' : ''}`}
                onClick={() => setTablePage(index + 1)}>
                {index + 1}
              </button>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
