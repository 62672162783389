import React from 'react';
import { RestaurantContext } from '../../../context/RestaurantProvider';
import Item from './Item';

export default function ListItem() {
  const { activeTab, tables, printerLocations, payments } = React.useContext(RestaurantContext);
  return (
    <div className="flex flex-col gap-2">
      {activeTab == 'Table' && (
        <>
          {tables.map((table) => (
            <React.Fragment key={`table-${table.id}`}>
              <Item
                id={table.id}
                name={`Table ${table.tableNumber}`}
                capacity={table.paxs}
                printerLocationId={table.printerLocationId}
                link={`table/${table.id}`}
              />
            </React.Fragment>
          ))}
        </>
      )}

      {activeTab == 'Lokasi Printer' && (
        <>
          {printerLocations.map((printerLocation) => (
            <React.Fragment key={`printerLocation-${printerLocation.id}`}>
              <Item
                id={printerLocation.id}
                name={printerLocation.name}
                link={`printer-location/${printerLocation.id}`}
              />
            </React.Fragment>
          ))}
        </>
      )}

      {activeTab == 'Payment Method' && (
        <>
          {payments.map((payment) => (
            <React.Fragment key={`payment-${payment.id}`}>
              <Item id={payment.id} name={payment.name} link={`payment/${payment.id}`} />
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
}
