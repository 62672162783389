import React from 'react';
import { DashboardProvider } from '../../../context/DashboardProvider';
import ListOrder from './ListOrder';
import PaymentBox from './PaymentBox';
import SelectedInfoBox from './SelectedInfoBox';
import TodayBox from './TodayBox';
import Dategroup from './Dategroup';

const DashboardPage = () => {
  return (
    <DashboardProvider>
      <div className="flex min-h-screen min-w-full max-w-full justify-center items-center flex-col px-2 py-4">
        <div className="card md:w-[90%] bg-base-100 shadow-xl" style={{ minHeight: "150vh" }}>
          <div className="card-body md:gap-8 sm:gap-6 gap-4 justify-between flex lg:flex-row flex-col w-full items-stretch">
            {/* <iframe
            src="https://lookerstudio.google.com/embed/reporting/5da9ef65-2b00-4aff-900f-b7753291456d/page/pdVyD"
            allowFullScreen={true}
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            style={{
              minHeight: "100vh",
              height: "100%",
              width: "100%",
              overflow: "hidden",
              border: "none"
            }}
          >
          </iframe> */}

            <div className="flex flex-1 flex-col md:gap-4 gap-2">
              <div className="w-full flex flex-row justify-center items-center gap-2">
                <TodayBox />
              </div>
              <div className="w-full flex flex-row items-center justify-between">
                <h2 className="text-lg font-semibold">Detail Pemasukan</h2>
                <Dategroup />
              </div>
              <div className="w-full flex flex-row justify-center items-center gap-2">
                <SelectedInfoBox />
              </div>
              <div className="w-full flex flex-row justify-center items-center gap-2 flex-wrap">
                <PaymentBox />
              </div>
              <ListOrder />
            </div>
          </div>
        </div>
      </div>
    </DashboardProvider>
  );
};

export default DashboardPage;
