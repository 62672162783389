import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RestaurantContext } from '../../../../context/RestaurantProvider';
import { triggerToast } from '../../../../utils';
import { AuthenticatedFetch } from '../../../../lib';

export default function AddPayment({ id, name, update }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      paymentName: update ? name : ''
    }
  });

  useEffect(() => {
    if (update) {
      setValue('paymentName', name);
    } else {
      setValue('paymentName', '');
    }
  }, [update, name]);

  const { setPayments } = React.useContext(RestaurantContext);

  async function onSubmit(data) {
    const { paymentName } = data;

    const res = await AuthenticatedFetch(`payment` + `${update ? `/${id}` : ''}`, {
      method: update ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ paymentName: paymentName })
    });

    if (res.status < 400) {
      const dt = await res.json();

      if (update) {
        setPayments((prev) => {
          return prev.map((payment) => (payment.id == id ? { ...payment, ...dt } : payment));
        });
      } else {
        setPayments((prev) => [...prev, dt]);
      }

      triggerToast('success', `Payment ${update ? 'updated' : 'added'} successfully!`);

      document.querySelector('#modal-restaurant').close();
    } else {
      const errMessage = await res.text();

      triggerToast('error', errMessage);
    }
    setValue('paymentName', '');
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="form-control w-full">
        <div className="label w-fit">
          <span className="label-text font-bold">Payment Name</span>
        </div>
        <input
          type="text"
          placeholder="Enter Payment Name"
          className="input input-bordered w-full font-semibold"
          {...register('paymentName', {
            required: true
          })}
        />
      </label>
      <div className="label">
        <span className="label-text-alt text-red-500 font-semibold">
          {' '}
          {errors.paymentName && 'Payment name is required!'}
        </span>
      </div>
      <div className="modal-action">
        <button className="btn" type="submit">
          {update ? 'Update' : 'Tambah'}
        </button>
      </div>
    </form>
  );
}

AddPayment.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  update: PropTypes.bool.isRequired
};
