class PortManager {
  constructor() {
    this.port = null;
    this.id = '';
  }
  async openPortConnection() {
    try {
      // Close any existing port
      await this.closePortConnection();

      // Get all available ports
      const ports = await navigator.serial.getPorts();

      // Check for an existing readable/writable port
      this.port = ports.find((p) => p.readable || p.writable);

      // If no existing port, request a new one
      if (!this.port) {
        this.port = await window.navigator.serial.requestPort();
        await this.port.open({ baudRate: 9600 });
      }

      // Log port information
      this.id = this.port.getInfo()?.bluetoothServiceClassId;
      console.log(this.port.getInfo());
    } catch (error) {
      console.error('Error opening port:', error);
    }
  }

  getPortInfo() {
    return {
      id: this.id
    };
  }

  async handleBluetoothData(data) {
    try {
      if (this.port) {
        const writer = this.port?.writable.getWriter();
        if (writer) {
          try {
            await writer.write(data);
            return true
          } finally {
            writer.releaseLock();
          }
        } else {
          // Attempt to open port connection, retrying up to N times on failure
          const MAX_RETRY_ATTEMPTS = 3; // Adjust based on your requirements
          let attempts = 0;
          while (!this.port && attempts < MAX_RETRY_ATTEMPTS) {
            attempts++;
            try {
              await this.openPortConnection();
            } catch (error) {
              console.error('Failed to open Bluetooth port:', error);
              // Implement appropriate error handling or backoff strategy here
            }
          }

          if (this.port) {
            // If successful, retry writing data
            this.handleBluetoothData(data); // Recursive call
          } else {
            console.error('Failed to open Bluetooth port after retries.');
            // Handle the case where opening the port fails even after retries
          }
        }
      } else {
        // Handle the case where `this.port` is not defined or null
        console.error('Bluetooth port is not available.');
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async closePortConnection() {
    try {
      if (this.port) {
        this.port.close();
        this.port = null;
        console.log('PORT connection closed');
      } else {
        console.log('No PORT connection to close');
      }
    } catch (error) {
      console.error('Error closing PORT connection:', error);
    }
  }
}

// Export a single instance of PortManager
const portManager = new PortManager();
export default portManager;
