import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CustomerContext } from '../../../context/CustomerProvider';

export default function TableBox({ tableNumber, totalPax, capacity, isOccupied, orders }) {
  const {
    setTableModal,
    setWaitingListModal,
    setDeleteWaitingListModal,
    waitingListBoxPicked,
    setWaitingListBoxPicked
  } = useContext(CustomerContext);

  return (
    <div
      className={`w-full py-2 px-8 rounded-lg border-2 ${!isOccupied
        ? 'bg-[#D5E8D4] border-green-300'
        : capacity <= totalPax
          ? 'bg-[#F8CECC] border-gray-300'
          : 'bg-[#FFF2CC] border-yellow-300'
        } relative flex flex-col gap-8 cursor-pointer`}
      onClick={() => {
        if (waitingListBoxPicked) {
          setTableModal({
            title: `Assign Customer Waiting List`,
            body: `${waitingListBoxPicked.orderId || 'id'} - ${waitingListBoxPicked.order.customerName || 'nama'} - ${waitingListBoxPicked.order.numberOfPeople || 0} Pax`,
            subBody: `Table ${tableNumber}`,
            tableNumber: tableNumber
          });
          setWaitingListModal(null);
          setDeleteWaitingListModal(null);
        } else {
          setTableModal({
            title: `Assign Customer`,
            body: `Table ${tableNumber}`,
            tableNumber: tableNumber
          });
          setWaitingListModal(null);
          setDeleteWaitingListModal(null);
          setWaitingListBoxPicked(null);
        }

        document.querySelector('#modal-customer').showModal();
      }}>
      <p className="font-semibold text-center">Table {tableNumber}</p>
      <p className="font-medium text-center">
        {orders.map(order =>
          <div key={order}>{order}</div>
        )}
      </p>
      <p className="font-medium text-center">
        {totalPax}/{capacity} pax
      </p>
    </div>
  );
}

TableBox.propTypes = {
  tableNumber: PropTypes.number,
  totalPax: PropTypes.number,
  capacity: PropTypes.number,
  isOccupied: PropTypes.bool
};
