import React from 'react';
import { Text, Br, Line } from 'react-thermal-printer';
import { customHeaderText } from './custom-text-header';
import { columnVariableValue } from './formatter';
import { CollumnFormatType } from '../PrinterEnum';
import { contentHeader } from '../components/content-header'
import convertDateTime from '../../utils/date-utils';

export class DocumentHeaderProps {
  constructor(props) {
    this.title = props.title;
    this.userName = props.userName;
    this.orderId = props.orderId;
    this.tableNumber = props.tableNumber;
    this.kitchenName = props.kitchenName;
    this.numOfCol = props.numOfCol;
    this.date = props.date;
    this.showIdentity = props.showIdentity;
    this.contentTableRightVar = props.contentTableRightVar;
  }
}

export const documentHeader = (props) => {
  return (
    <>
      {props.showIdentity &&
        <>
          {customHeaderText({ text: "Bakmie Siantar Paus", textAlign: "center" })}
          {customHeaderText({ text: "Rawamangun", textAlign: "center" })}
          <Br />

          <Text align="center">Jalan Sunan Sedayu Blok A Nomor 1, Rawamangun</Text>
          <Text align="center">Telp: 021-47860696/29568916</Text>
          <Br />
        </>
      }
      {props.title && <Text align='center' bold size={{ height: 2, width: 3 }}>{props.title}</Text>}

      <Br />
      <Line character='=' />

      {documentDetail(props)}
    </>
  );
};

const documentDetail = (props) => {
  let orderType = null;
  if (props.orderId.includes("TA")) {
    orderType="Take Away"
  } else if (props.orderId.includes("OL")) {
    orderType="Online"
  }

  return (
    <>
      {props.date && <Text>{columnVariableValue({ variable: "Tanggal", value: convertDateTime(props.date), collumnFormat: CollumnFormatType.InfoData })}</Text>}

      {props.userName && <Text>{columnVariableValue({ variable: "Tamu", value: props.userName, collumnFormat: CollumnFormatType.InfoData })}</Text>}
      {props.tableNumber && <Text>{columnVariableValue({ variable: "Meja", value: props.tableNumber, collumnFormat: CollumnFormatType.InfoData })}</Text>}
      {props.kitchenName && <Text>{columnVariableValue({ variable: "Dapur", value: props.kitchenName, collumnFormat: CollumnFormatType.InfoData })}</Text>}
      {orderType && <Text>{columnVariableValue({ variable: "Tipe", value: orderType, collumnFormat: CollumnFormatType.InfoData })}</Text>}

      {props.contentTableRightVar !== null && contentHeader({ rightVar: props.contentTableRightVar })}
    </>
  );
}