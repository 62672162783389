import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createContext } from 'react';
import { AuthenticatedFetch, RestaurantTab } from '../lib';

export const RestaurantContext = createContext({});

export function RestaurantProvider({ children }) {
  const [activeTab, setActiveTab] = React.useState(RestaurantTab[0]);
  const [deleteModal, setDeleteModal] = React.useState({
    show: false,
    name: '',
    link: ''
  });
  const [tables, setTables] = React.useState([]);
  const [printerLocations, setPrinterLocations] = React.useState([]);
  const [payments, setPayments] = React.useState([]);
  const [updateModal, setUpdateModal] = React.useState({
    id: 0,
    name: '',
    printerLocationId: 0,
    capacity: 0,
    update: false
  });

  useEffect(() => {
    async function fetchData() {
      const [tablesResponse, printerLocationsResponse, paymentsResponse] = await Promise.all([
        AuthenticatedFetch(`table`),
        AuthenticatedFetch(`printer-location`),
        AuthenticatedFetch(`payment`)
      ]);

      const [tablesData, printerLocationsData, paymentsData] = await Promise.all([
        tablesResponse.json(),
        printerLocationsResponse.json(),
        paymentsResponse.json()
      ]);

      setTables(tablesData);
      setPrinterLocations(printerLocationsData);
      setPayments(paymentsData);
    }

    fetchData();
  }, []);

  const sortedTables = useMemo(() => {
    return tables.sort((a, b) => {
      return a.tableNumber - b.tableNumber;
    });
  }, [tables]);

  const sortedPrinterLocations = useMemo(() => {
    return printerLocations.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [printerLocations]);

  const sortedPayments = useMemo(() => {
    return payments.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }, [payments]);

  return (
    <RestaurantContext.Provider
      value={{
        activeTab,
        setActiveTab,
        deleteModal,
        setDeleteModal,
        tables: sortedTables,
        setTables,
        printerLocations: sortedPrinterLocations,
        setPrinterLocations,
        payments: sortedPayments,
        setPayments,
        updateModal,
        setUpdateModal
      }}>
      {children}
    </RestaurantContext.Provider>
  );
}

RestaurantProvider.propTypes = {
  children: PropTypes.node.isRequired
};
