import React from 'react';
import PropTypes from 'prop-types';

export default function InfoBox({ title, value, isCurrency, className }) {
  const IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });

  return (
    <div
      className={`rounded-lg border-2 flex flex-col justify-center gap-2 flex-grow p-2 items-center ${
        className ? className : ''
      }`}>
      <h4 className="font-semibold text-lg">{title}</h4>
      <h2 className="font-bold text-2xl">{isCurrency ? IDR.format(value) : value}</h2>
    </div>
  );
}

InfoBox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  isCurrency: PropTypes.bool,
  className: PropTypes.string
};
