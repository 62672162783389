import React from 'react';
import InfoBox from './InfoBox';
import { DashboardContext } from '../../../context/DashboardProvider';

export default function PaymentBox() {
  const { payments, orders } = React.useContext(DashboardContext);

  function getPaymentAmountBasedOnPaymentId(paymentId) {
    return Math.round(
      parseFloat(
        orders.reduce((acc, order) => {
          if (order.status !== 'PAID' || order.paymentId !== paymentId) return acc;

          return (
            acc +
            order.orderItems.reduce((acc, orderItem) => {
              return acc + orderItem.price;
            }, 0)
          );
        }, 0)
      )
    );
  }

  return (
    <>
      {payments &&
        payments.map((payment) => (
          <InfoBox
            key={`payment-${payment.id}`}
            title={payment.name}
            value={getPaymentAmountBasedOnPaymentId(payment.id)}
            isCurrency={true}
            className={`basis-1/5`}
          />
        ))}
    </>
  );
}
