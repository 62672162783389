import React from 'react';
export default function PrintSuccessModal() {

    return (
        <dialog id="finished_print_modal" className="modal">
            <div className="modal-box">
                <h3 className="font-bold text-lg">Success</h3>
                <div className="modal-action">
                    <p>Order berhasil di print!</p>
                    <form method="dialog">
                        <button className="btn">Ok</button>
                    </form>
                </div>
            </div>
        </dialog>
    );
}
