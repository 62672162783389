import React, { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import CategoryItem from './CategoryItem';
import { AuthContext } from '../../context/AuthProvider';
import { sortCategoriesByRank } from '../../utils/categorySorter';

export default function CategoryTab() {
  const { categories, setCategories } = useContext(MenuContext);
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [search, setSearch] = useState('');
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  useEffect(() => {
    AuthenticatedFetch(`categories`)
      .then((res) => res.json())
      .then((data) => setCategories(sortCategoriesByRank(data, false)));
  }, []);

  useEffect(() => {
    setFilteredCategories(categories);
    if (search) {
      setFilteredCategories(
        categories.filter((category) => {
          return category.name.toLowerCase().includes(search.toLowerCase());
        })
      );
    } else {
      setFilteredCategories(categories);
    }
  }, [categories, search]);

  return (
    <div>
      <div className='grid place-items-end mt-5'>
        <button
          disabled={!decideIfRoleHasAccess(scopesEnum.CREATE_CATEGORY)}
          className="btn btn-primary"
          onClick={() => document.getElementById('category_modal').showModal()}>
          Add New Category
        </button>
      </div>
      <div className="grid place-items-center mt-5">
        <div className="overflow-x-auto mt-10 w-1/2">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="input input-bordered w-full mb-5"
            type="text"
            placeholder="Search"
          />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Rank</th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.map((category) => (
                <CategoryItem key={category.id} category={category} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
