import React, { useContext, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { handleInputChange } from '../../utils/handleInputChange';
import { triggerToast } from '../../utils/toast';
import { AuthContext } from '../../context/AuthProvider';
import { FaTimes } from "react-icons/fa";

export default function MenuCreateModal() {
  const { menus, setMenus, categories, addons, printers } = useContext(MenuContext);
  const [createError, setCreateError] = useState('');
  const [newMenu, setNewMenu] = useState({
    name: '',
    image: '',
    info: '',
    offlinePrice: 0,
    onlinePrice: 0,
    available: false,
    categoryId: null,
    kitchenId: null
  });
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [tempSelectedAddonId, setTempSelectedAddonId] = useState(null);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  async function resetForm() {
    setNewMenu({
      name: '',
      image: '',
      info: '',
      offlinePrice: 0,
      onlinePrice: 0,
      available: false,
      categoryId: null,
      kitchenId: null,
    });
    setTempSelectedAddonId(null);
    setSelectedAddons([]);
    setCreateError('');
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setNewMenu({
      ...newMenu,
      image: file,
    });
  };

  return (
    <dialog id="menu_modal" className="modal">
      <div className="modal-box">
        <div className="modal-action mt-0">
          <form method="dialog">
            <button className="btn" onClick={() => { resetForm() }}><FaTimes /></button>
          </form>
        </div>
        <h3 className="font-bold text-lg">Create Menu</h3>
        {createError && <p className="text-error">{createError}</p>}
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!decideIfRoleHasAccess(scopesEnum.CREATE_MENU)) {
              triggerToast('error', 'You are not allowed to create menu!');
              return;
            }
            const menuFormData = new FormData();
            menuFormData.append("image", newMenu.image);
            menuFormData.append("name", newMenu.name);
            menuFormData.append("info", newMenu.info);
            menuFormData.append("available", newMenu.available);
            menuFormData.append("offlinePrice", newMenu.offlinePrice || '');
            menuFormData.append("onlinePrice", newMenu.onlinePrice || '');
            menuFormData.append("kitchenId", newMenu.kitchenId);
            menuFormData.append("rank", newMenu.rank || '');

            if (newMenu.categoryId) {
              menuFormData.append("categoryId", newMenu.categoryId);
            } else {
              menuFormData.append("categoryId", null);
            }

            menuFormData.append("deleted", false);
            menuFormData.append("deletedAt", null);
            const res = await AuthenticatedFetch(`menus`, {
              method: 'POST',
              headers: {
                "enctype": "multipart/form-data"
              },
              body: menuFormData
            });

            if (!res.ok) {
              const data = await res.text();
              setCreateError(data);
              triggerToast('error', data);
              return;
            }
            const data = await res.json();
            selectedAddons.forEach(async (addon) => {
              await AuthenticatedFetch(`addons/connect/${addon.id}/${data.id}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              });
            });
            setMenus([
              ...menus,
              {
                ...data,
                category: categories.find((category) => category.id === data.categoryId),
                addons: addons.filter((addon) => {
                  return selectedAddons.find((a) => a.id === addon.id);
                }),
                printer: printers.find((printer) => printer.id === data.kitchenId)
              }
            ]);
            const file = document.getElementById(`create_image`);
            file.value = file.defaultValue;
            resetForm()
            document.getElementById('menu_modal').close();
          }}
          className="form-control m-3">
          <label className="label-text" htmlFor="name">
            Name
          </label>
          <input
            value={newMenu.name}
            onChange={(e) => handleInputChange(e, setNewMenu)}
            className="mb-3 input input-bordered"
            type="text"
            name="name"
            id="name"
          />
          <label className="label-text" htmlFor="create_image">
            Image
          </label>
          <input
            onChange={handleImageChange}
            className="file-input file-input-bordered"
            type="file"
            name="image"
            id="create_image"
          />
          <label className="label-text" htmlFor="info">
            Description
          </label>
          <textarea
            value={newMenu.info}
            onChange={(e) => handleInputChange(e, setNewMenu)}
            className="mb-3 h-20 textarea textarea-bordered"
            type="text"
            name="info"
            id="info"
          />
          <label className="label-text" htmlFor="offlinePrice">
            Price (Offline)
          </label>
          <input
            value={newMenu.offlinePrice}
            onChange={(e) => handleInputChange(e, setNewMenu)}
            className="mb-3 input input-bordered"
            type="number"
            name="offlinePrice"
            id="offlinePrice"
          />
          <label className="label-text" htmlFor="onlinePrice">
            Price (Online)
          </label>
          <input
            value={newMenu.onlinePrice}
            onChange={(e) => handleInputChange(e, setNewMenu)}
            className="mb-5 input input-bordered"
            type="number"
            name="onlinePrice"
            id="onlinePrice"
          />

          <label className="label-text" htmlFor="rank">
            Rank
          </label>
          <input
            value={newMenu.rank}
            onChange={(e) => handleInputChange(e, setNewMenu)}
            className="mb-5 input input-bordered"
            type="number"
            name="rank"
            id="rank"
          />

          <>
            <label className="label-text" htmlFor="kitchenId">
              Designated Printer Location
            </label>
            <select
              value={newMenu.kitchenId === null ? "" : newMenu.kitchenId}
              className="select select-bordered mb-3"
              name="kitchenId"
              id="kitchenId"
              onChange={(e) => {
                const kitchenId = parseInt(e.target.value);
                if (!kitchenId) return;
                setNewMenu({
                  ...newMenu,
                  kitchenId
                });
              }}>
              <option disabled selected hidden value={null}>
                Select a Printer Location
              </option>
              <option value="" hidden defaultChecked>
                Select a Printer Location
              </option>
              {printers.map((printer) => (
                <option key={printer.id} value={printer.id}>
                  {printer.name}
                </option>
              ))}
            </select>
          </>
          <label htmlFor="categories" className="label-text">
            Category
          </label>
          <select
            value={newMenu.categoryId === null ? '' : newMenu.categoryId}
            onChange={(e) => {
              const categoryId = parseInt(e.target.value);
              if (!categoryId) return;
              setNewMenu({
                ...newMenu,
                categoryId
              });
            }}
            className="select select-bordered mb-5"
            id="categories">
            <option selected hidden disabled value="">
              Select a Category
            </option>
            <option value="" hidden defaultChecked>
              Select a Category
            </option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>

          <label htmlFor="addons">Add-ons</label>
          <select
            value={tempSelectedAddonId === null ? '' : tempSelectedAddonId}
            onChange={(e) => {
              const addonId = parseInt(e.target.value);
              if (!addonId) return;
              const addon = addons.find((addon) => addon.id === addonId);
              if (!addon) return;
              if (selectedAddons.find((a) => a.id === addon.id)) return;
              setSelectedAddons([...selectedAddons, addon]);
              setTempSelectedAddonId(addonId);
            }}
            name="addons"
            id="addons"
            className="select select-bordered mb-3">
            <option value="" selected hidden disabled>
              Select Add-ons
            </option>
            {addons.filter(addon => !addon.deleted).map((addon) => (
              <option key={addon.id} value={addon.id}>
                {addon.name}
              </option>
            ))}
          </select>
          <label className="label-text">Selected Add-ons</label>
          {selectedAddons.length > 0 ? (
            <div className="flex flex-wrap gap-2 mb-5">
              {selectedAddons.map((addon) => (
                <div key={addon.id} className="flex items-center gap-2 btn btn-neutral">
                  <span>{addon.name}</span>
                  <FaTrashAlt
                    onClick={() => {
                      setSelectedAddons(selectedAddons.filter((a) => a.id !== addon.id));
                    }}
                    className="cursor-pointer"
                  />
                </div>
              ))}
            </div>
          ) : (
            <p className="mb-5 text-gray-600 text-sm">No add-ons selected</p>
          )}

          <div className="mb-3 flex items-center">
            <input
              onChange={(e) => {
                setNewMenu({ ...newMenu, available: e.target.checked });
              }}
              checked={newMenu.available}
              className="mr-3 checkbox"
              type="checkbox"
              name="available"
              id="available"
            />
            <span>
              <label htmlFor="available">Available?</label>
            </span>
          </div>

          <button className="btn btn-primary">Create</button>
        </form>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn" onClick={() => { resetForm() }}>Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
}
