import React from 'react';
import { Image } from 'react-thermal-printer';

export const customHeaderText = ({ text, textAlign, fontFamily = "monospace", fontSize = 46 }) => {
  const width = 574;
  const height = fontSize * 1.1;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  ctx.font = `${fontSize}px ${fontFamily}`;
  ctx.textAlign = textAlign;

  const xPosition = textAlign === 'left' ? 0 : textAlign === 'center' ? (width) / 2 : width;
  const yPosition = fontSize * 0.8;

  ctx.fillText(text, xPosition, yPosition);
  const dataUrl = canvas.toDataURL();

  return <Image src={dataUrl} />;
};