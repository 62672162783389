import React, { useContext, useState } from 'react';
import { FaPenAlt, FaTrashAlt } from 'react-icons/fa';
import { UpdateMenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { AuthContext } from '../../context/AuthProvider';
import { triggerToast } from '../../utils/toast';

export default function AddonItem({ addonProp }) {
  const [multiple, setMultiple] = useState(addonProp.multiple);
  const { setAddon } = useContext(UpdateMenuContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return (
    <tr key={addonProp.id}>
      <td>
        {addonProp.name} {addonProp.deleted && '[Deleted]'}
      </td>
      <td>
        <input
          onChange={async (e) => {
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_ADDONS)) {
              return triggerToast('error', 'You are not allowed to update addon!');
            }
            setMultiple(e.target.checked);
            try {
              const res = await AuthenticatedFetch(`addons/${addonProp.id}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  multiple: e.target.checked
                })
              });
              if (!res.ok) {
                setMultiple(!e.target.checked);
              }
            } catch (err) {
              setMultiple(!e.target.checked);
            }
          }}
          type="checkbox"
          className="toggle toggle-primary"
          checked={multiple}
        />
      </td>
      <td>
        <button
          disabled={!decideIfRoleHasAccess(scopesEnum.UPDATE_ADDONS)}
          onClick={() => {
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_ADDONS)) {
              return triggerToast('error', 'You are not allowed to update addon!');
            }
            setAddon(() => addonProp);
            document.getElementById(`addon_update_modal`).showModal();
          }}
          className="btn btn-warning btn-sm">
          <FaPenAlt className="text-white" />
        </button>
      </td>
      <td>
        <button
          disabled={!decideIfRoleHasAccess(scopesEnum.DELETE_ADDONS)}
          onClick={async () => {
            if (!decideIfRoleHasAccess(scopesEnum.DELETE_ADDONS)) {
              return triggerToast('error', 'You are not allowed to delete addon!');
            }
            setAddon(() => addonProp);
            document.getElementById(`addon_delete_modal`).showModal();
          }}
          className="btn btn-error btn-sm">
          <FaTrashAlt className="text-white" />
        </button>
      </td>
    </tr>
  );
}
