import React from 'react';
import { StatusTab } from '../../../lib';
import { CashierContext } from '../../../context/CashierProvider';

const dropDownStyle = {
  backgroundColor: 'oklch(0.961151 0 0)',
  fontSize: "1rem",
  fontWeight: "bold",
  paddingLeft: "10px"
};

const dropDownOptionStyle = {
  backgroundColor: 'oklch(0.961151 0 0)',
  fontSize: "0.9rem",
  fontWeight: "bold",
  linHeight: "2rem"
};

export default function PaymentStatusBar() {
  const { activePaymentStatusTab, setactivePaymentStatusTab, payments, activePaymentTypeTab, setActivePaymentTypeTab } = React.useContext(CashierContext);

  const showPaymentTypeTab = activePaymentStatusTab == StatusTab[0].value;
  return (
    <div className='flex flex-1 flex-col md:gap-4 gap-2'>
      <div role="tablist" className="tabs tabs-boxed w-fit md:tabs-md tabs-xs">
        <div>
          {StatusTab.map((tab) => (
            <a
              role="tab"
              className={`font-bold text-xs sm:text-sm md:text-base tab ${activePaymentStatusTab == tab.value ? 'tab-active font-extrabold' : ''
                }`}
              onClick={() => {
                setactivePaymentStatusTab(tab.value);
                setActivePaymentTypeTab(null)
              }}
              key={tab.label}>
              {tab.label}
            </a>
          ))}
        </div>
      </div>

      {showPaymentTypeTab &&
        <div role="tablist" className="tabs tabs-boxed w-fit md:tabs-md tabs-xs">
          <select
            style={dropDownStyle}
            value={activePaymentTypeTab?.id || ""}
            onChange={(e) => setActivePaymentTypeTab(payments.find(opt => opt.id === parseInt(e.target.value)))}
          >
            <option
              value=""
              style={dropDownOptionStyle}>All</option>
            {payments.map((option) => (
              <option
                style={dropDownOptionStyle}
                key={option.id}
                value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      }
    </div>
  );
}