import React from 'react';
import { ACLProvider } from '../../../context/ACLProvider';
import TabBar from './TabBar';
import Container from './Container';
import CommandButton from './CommandButton';
import ModalBox from './ModalBox';
import { AuthContext } from '../../../context/AuthProvider';
import { scopesEnum } from '../../../lib';

export default function ACLPage() {
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return decideIfRoleHasAccess(scopesEnum.ACCESS_CONTROL_LIST) ? (
    <ACLProvider>
      <div className="flex min-h-screen justify-center items-center flex-col px-2">
        <div className="card md:w-4/5 w-full bg-base-100 shadow-xl">
          <div className="card-body md:gap-8 sm:gap-6 gap-4">
            <TabBar />
            <CommandButton />
            <Container />
          </div>
        </div>
        <ModalBox />
      </div>
    </ACLProvider>
  ) : (
    <div className="text-lg font-bold flex min-h-screen justify-center items-center">
      You do not have access to this page
    </div>
  );
}
