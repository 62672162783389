import React from 'react';
import { Line, Row } from 'react-thermal-printer';
import { columnVariableValue } from './formatter';
import { CollumnFormatType } from '../PrinterEnum';

export class ContentHeaderProps {
  constructor(props) {
    this.rightVar = props.rightVar;
  }
}

export const contentHeader = ({ rightVar }) => {
  return (
    <>
      <Line character='=' />
      <Row left={columnVariableValue({ variable: "Qty", value: "Item", collumnFormat: CollumnFormatType.QtyItem })} right={rightVar} />
      <Line />
    </>
  );
};