import React, { useContext, useEffect, useState } from 'react';
import { FaPenAlt, FaSave, FaTrashAlt } from 'react-icons/fa';
import { MenuContext } from '../../contexts/MenuContext';
import { OptionContext } from '../../contexts/OptionContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { handleInputChange } from '../../utils/handleInputChange';
import { AuthContext } from '../../context/AuthProvider';
import { triggerToast } from '../../utils/toast';

export default function OptionItem({ optionProp, id }) {
  const [editing, setEditing] = useState(false);
  const { addons, setAddons } = useContext(MenuContext);
  const [option, setOption] = useState(optionProp);
  const { selected, setSelected } = useContext(OptionContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  useEffect(() => {
    if (selected == 0) {
      return;
    }
    if (selected === option.id) {
      AuthenticatedFetch(`options/${option.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          default: true
        })
      }).then((res) => {
        if (!res.ok) {
          return;
        }
        setOption({
          ...option,
          default: true
        });
      });
    } else {
      AuthenticatedFetch(`options/${option.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          default: false
        })
      }).then((res) => {
        if (!res.ok) {
          return;
        }
        setOption({
          ...option,
          default: false
        });
      });
    }
  }, [selected]);

  return (
    <tr>
      <td>
        <input
          onChange={async () => {
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_ADDONS)) {
              return triggerToast('error', 'You do not have permission to update addons');
            }
            setSelected(() => option.id);
          }}
          type="radio"
          name="default"
          id="default"
          checked={option.default}
        />
      </td>
      <td>
        {editing ? (
          <input
            type="text"
            name="name"
            value={option.name}
            onChange={(e) => handleInputChange(e, setOption)}
            className="px-2 py-1 w-24  border border-gray-500 rounded-md"
          />
        ) : (
          <p className="text-nowrap">{option.name}</p>
        )}
      </td>
      <td>
        {editing ? (
          <input
            type="number"
            name="price"
            value={option.price}
            onChange={(e) => handleInputChange(e, setOption)}
            className="px-2 py-1 w-24 border border-gray-500 rounded-md"
          />
        ) : (
          <p className="text-nowrap">{option.price}</p>
        )}
      </td>
      <td>
        {editing ? (
          <input
            value={option.priceOnline}
            type="number"
            name="priceOnline"
            id="priceOnline"
            className="px-2 py-1 w-24 border border-gray-500 rounded-md"
          />
        ) : (
          <p className="text-nowrap">{option.priceOnline}</p>
        )}
      </td>
      <td>
        {editing ? (
          <button
            className="btn btn-primary"
            onClick={async () => {
              if (!decideIfRoleHasAccess(scopesEnum.UPDATE_ADDONS)) {
                return triggerToast('error', 'You do not have permission to update addons');
              }
              setEditing(false);
              const res = await AuthenticatedFetch(`options/${id}`, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  name: option.name,
                  price: parseInt(option.price)
                })
              });
              if (!res.ok) {
                const data = await res.text();
                console.log(data);
                return;
              }
              const data = await res.json();
              setOption(data);
              setAddons(
                addons.map((addon) => {
                  if (addon.id === data.id) {
                    return {
                      ...addon,
                      options: addon.options.map((option) => {
                        if (option.id === data.id) {
                          return data;
                        }
                        return option;
                      })
                    };
                  }
                  return addon;
                })
              );
            }}>
            <FaSave />
          </button>
        ) : (
          <button className="btn btn-primary" onClick={() => setEditing(true)}>
            <FaPenAlt />
          </button>
        )}
      </td>
      <td>
        <button
          onClick={async () => {
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_ADDONS)) {
              return triggerToast('error', 'You do not have permission to update addons');
            }
            const res = await AuthenticatedFetch(`options/${option.id}`, {
              method: 'DELETE'
            });
            if (!res.ok) {
              return;
            }
            const data = await res.json();
            setAddons(
              addons.map((addon) => {
                if (addon.id === data.addonId) {
                  return {
                    ...addon,
                    options: addon.options.filter((option) => {
                      console.log(option.id == data.id);
                      console.log(data);
                      return option.id !== data.id;
                    })
                  };
                }
                return addon;
              })
            );
          }}
          className="btn text-white btn-error">
          <FaTrashAlt />
        </button>
      </td>
    </tr>
  );
}
