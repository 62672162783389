import React, { useContext } from 'react';
import { CustomerContext } from '../../../context/CustomerProvider';

export default function CommandButton() {
  const { setWaitingListModal, setTableModal, setDeleteWaitingListModal } =
    useContext(CustomerContext);

  return (
    <button
      className="btn flex ml-auto w-fit font-bold text-xs sm:text-sm md:text-base bg-orange-100"
      onClick={() => {
        setWaitingListModal({ title: 'Tambah Waiting List' });
        setTableModal(null);
        setDeleteWaitingListModal(null);
        document.querySelector('#modal-customer').showModal();
      }}>
      + Waiting List
    </button>
  );
}
