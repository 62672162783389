import React, { useContext } from 'react';
import { IoTrashOutline, IoClipboardOutline } from 'react-icons/io5';
import { CustomerContext } from '../../../context/CustomerProvider';
import PropTypes from 'prop-types';

export default function WaitingBox({ waitingList }) {
  const {
    setTableModal,
    setWaitingListModal,
    setDeleteWaitingListModal,
    waitingListBoxPicked,
    setWaitingListBoxPicked,
    setOrderModal
  } = useContext(CustomerContext);

  const {
    id,
    orderId,
    createdAt: date,
    order: { customerName, numberOfPeople }
  } = waitingList;

  function getTime(isoString) {
    const date = new Date(isoString);
    const options = { hour: '2-digit', minute: '2-digit' };
    const time = date.toLocaleTimeString('en-US', options);

    return time;
  }

  return (
    <div
      className={`py-2 w-[200px] rounded-lg border-2 border-black relative flex flex-col items-center gap-2 flex-wrap cursor-pointer ${waitingListBoxPicked && waitingListBoxPicked.id == waitingList.id
        ? 'bg-gray-200'
        : 'bg-white'
        }`}
      onClick={() => {
        if (waitingListBoxPicked && waitingListBoxPicked.id == waitingList.id) {
          setWaitingListBoxPicked(null);
          return;
        }
        setWaitingListBoxPicked(waitingList);
      }}>
      <p className="font-semibold text-center">
        {id} - {customerName ? customerName : 'Customer'} - {orderId}
      </p>
      <p className="font-medium text-center">{numberOfPeople ? numberOfPeople : '-'} Pax</p>
      <p className="font-medium text-center">{getTime(date)}</p>
      <IoTrashOutline
        className="absolute top-5 right-3 w-5 h-5 md:w-6 md:h-6 cursor-pointer"
        onClick={() => {
          setTableModal(null);
          setWaitingListModal(null);
          setDeleteWaitingListModal({
            title: `Delete Waiting List ${id}`,
            id: id,
            date: date
          });
          setOrderModal(null);

          document.querySelector('#modal-customer').showModal();
        }}
      />
      <IoClipboardOutline
        className="absolute bottom-5 right-3 w-5 h-5 md:w-6 md:h-6 cursor-pointer"
        onClick={() => {
          setTableModal(null);
          setWaitingListModal(null);
          setDeleteWaitingListModal(null);
          setOrderModal({
            title: `Open menu Waiting list id : ${id}`,
            body: `Order Id : ${orderId}`,
            orderId: orderId
          });

          document.querySelector('#modal-customer').showModal();
        }}
      />
    </div>
  );
}

WaitingBox.propTypes = {
  waitingList: PropTypes.object.isRequired
};
