import React, { useContext, useState } from 'react';
import { FaPenAlt, FaTrashAlt } from 'react-icons/fa';
import { UpdateMenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { AuthContext } from '../../context/AuthProvider';
import { triggerToast } from '../../utils/toast';

export default function MenuItem({ menuProp }) {
  let IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });
  const [available, setAvailable] = useState(menuProp.available);
  const { setMenu } = useContext(UpdateMenuContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return (
    <tr key={menuProp.id}>
      <td>
        <div className="flex items-center gap-3">
          <div className="avatar">
            <div className="mask mask-squircle rounded-lg w-12 h-12">
              <img
                loading='lazy'
                src={`${menuProp.image.startsWith('http')
                  ? menuProp.image
                  : `${process.env.REACT_APP_API_URL}/${menuProp.image}`
                  }`}
                alt="Menu"
              />
            </div>
          </div>
          <div>
            <div className="font-bold">
              {menuProp.name} {menuProp.deleted && '[Deleted]'}
            </div>
            <div className="text-sm opacity-50">
              {menuProp.designatedKitchen?.name || 'Unasigned'}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="flex justify-center">
          <input
            disabled={!decideIfRoleHasAccess(scopesEnum.UPDATE_AVAILABILITY)}
            onChange={async (e) => {
              if (!decideIfRoleHasAccess(scopesEnum.UPDATE_AVAILABILITY)) {
                triggerToast('error', 'You are not allowed to update menu availablity!');
                return;
              }
              setAvailable(e.target.checked);
              try {
                const res = await AuthenticatedFetch(`menus/${menuProp.id}`, {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    available: e.target.checked
                  })
                });
                if (!res.ok) {
                  setAvailable(!e.target.checked);
                }
              } catch (err) {
                setAvailable(!e.target.checked);
              }
            }}
            type="checkbox"
            className="toggle toggle-primary"
            checked={available}
          />
        </div>
      </td>
      <td>
        <span className="text-sm font-semibold">{IDR.format(menuProp.offlinePrice)}</span>
      </td>
      <td>
        <span className="text-sm font-semibold">{IDR.format(menuProp.onlinePrice)}</span>
      </td>
      <td>
        <button
          disabled={!decideIfRoleHasAccess(scopesEnum.UPDATE_MENU)}
          onClick={() => {
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_MENU)) {
              triggerToast('error', 'You are not allowed to update menu!');
              return;
            }
            setMenu(() => menuProp);
            document.getElementById(`menu_update_modal`).showModal();
          }}
          className="btn btn-warning btn-sm">
          <FaPenAlt className="text-white" />
        </button>
      </td>
      <td>

        <button
          disabled={!decideIfRoleHasAccess(scopesEnum.DELETE_MENU)}
          onClick={async () => {
            if (!decideIfRoleHasAccess(scopesEnum.DELETE_MENU)) {
              triggerToast('error', 'You are not allowed to delete menu!');
              return;
            }
            setMenu(() => menuProp);
            document.getElementById(`menu_delete_modal`).showModal();
          }}
          className="btn btn-error btn-sm">
          <FaTrashAlt className="text-white" />
        </button>
      </td>
    </tr>
  );
}
