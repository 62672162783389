import React, { useContext, useEffect, useState } from 'react';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import AddonItem from './AddOnItem';
import { AuthContext } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { triggerToast } from '../../utils/toast';

export default function AddonTab() {
  const { addons, setAddons } = useContext(MenuContext);
  const [filteredAddons, setFilteredAddons] = useState(addons);
  const [search, setSearch] = useState('');
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  useEffect(() => {
    AuthenticatedFetch(`addons`)
      .then((res) => res.json())
      .then((data) => setAddons(data));
  }, []);

  useEffect(() => {
    setFilteredAddons(addons);
    if (search) {
      setFilteredAddons(
        addons.filter((addon) => {
          return addon.name.toLowerCase().includes(search.toLowerCase());
        })
      );
    } else {
      setFilteredAddons(addons);
    }
  }, [addons, search]);

  return (
    <div>
      <div className='grid place-items-end mt-5'>
        <button
          disabled={!decideIfRoleHasAccess(scopesEnum.CREATE_ADDONS)}
          className="btn btn-primary"
          onClick={() => document.getElementById('addon_modal').showModal()}>
          Add New Add-on
        </button>
      </div>
      <div className="grid place-items-center mt-5">
        <div className="overflow-x-auto mt-10 w-3/4">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="input input-bordered w-full mb-5"
            type="text"
            placeholder="Search"
          />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Multiple?</th>
              </tr>
            </thead>
            <tbody>
              {filteredAddons.map((addon) => (
                <AddonItem key={addon.id} addonProp={addon} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
