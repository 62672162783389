import { createContext } from "react";

export const MenuContext = createContext({
    menus: [],
    setMenus: () => { },
    categories: [],
    setCategories: () => { },
    addons: [],
    setAddons: () => { },
    printers: [],
    setPrinters: () => { },
});

export const UpdateMenuContext = createContext({
    menu: {},
    setMenu: () => { },
    category: {},
    setCategory: () => { },
    addon: {},
    setAddon: () => { },
});