import Cookies from 'js-cookie';

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 15000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);

  return response;
}

export const AuthenticatedFetch = async (endpoint, init, useTimeout) => {
  const token = Cookies.get('token');

  if (!token)
    return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      ...init,
      headers: {
        ...init?.headers
      }
    });
    
  if (useTimeout) {
    return fetchWithTimeout(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      ...init,
      headers: {
        authorization: `Bearer ${token}`,
        ...init?.headers
      }
    });
  }

  return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
    ...init,
    headers: {
      authorization: `Bearer ${token}`,
      ...init?.headers
    }
  });
};

export const SetCookieToken = (token) => {
  Cookies.set('token', token);
};

export const RemoveCookieToken = () => {
  Cookies.remove('token');
};

export const GetCookieToken = () => {
  return Cookies.get('token');
};
