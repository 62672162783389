import React, { useContext } from 'react';
import TableBox from './TableBox';
import { CustomerContext } from '../../../context/CustomerProvider';

export default function InformationTable() {
  const { tables } = useContext(CustomerContext);
  return (
    <div className="flex flex-col md:gap-4 gap-2 justify-center items-start">
      <h2 className="text-lg font-bold">Information Table</h2>
      <div className="w-full grid grid-cols-4 gap-4 grid-rows-1">
        {tables &&
          tables.map((table) => (
            <TableBox
              key={`table-${table.id}`}
              tableNumber={table.tableNumber}
              totalPax={table.Order.reduce((acc, curr) => {
                if (!curr.numberOfPeople) return acc;
                return acc + curr.numberOfPeople;
              }, 0)}
              capacity={table.paxs}
              orders={table.Order.map(order => `${order.id || "orderId"} - ${order.customerName || "nama"} - ${order.numberOfPeople || 0} pax`)}
              isOccupied={table.Order.reduce((acc, curr) => {
                if (curr.tableNumber !== table.tableNumber) return acc;
                return true;
              }, false)}
            />
          ))}
      </div>
    </div>
  );
}
