const convertDateTime = (utcString) => {
  // Parse the UTC string into a Date object
  const utcDate = new Date(utcString);

  // Adjust for UTC+7 by adding the offset in milliseconds
  const adjustedTime = utcDate.getTime();

  // Create a new Date object with the UTC+7 adjusted time
  const localDate = new Date(adjustedTime);

  // Format the local date-time string in the desired format
  const formattedDateTime = localDate.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }).replace(/\//g, '-'); // Replace '/' with '-' for desired format

  return formattedDateTime;
};

export default convertDateTime;