import { FaChevronLeft } from 'react-icons/fa';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OrderContext } from '../../contexts/OrderContext';
import { triggerToast } from '../../utils';

export default function MenuDetail() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });
  const { order, setOrder, name, tableNumber } = useContext(OrderContext);
  const [selectOrder, setSelectOrder] = useState([]);
  const [notes, setNotes] = useState(state.menu.notes || '');
  const { uid } = useParams();

  useEffect(() => {
    setSelectOrder(() => []);
    state.menu.addons.forEach((addon) => {
      addon.options.forEach((option) => {
        if (option.default && !selectOrder.find((o) => o.id === option.id)) {
          setSelectOrder((selectOrder) => [
            ...selectOrder,
            {
              ...option,
              addonId: addon.id
            }
          ]);
        }
      });
    });
    fetch(`${process.env.REACT_APP_API_URL}/order/${uid}`)
      .then((res) => {
        if (!res.ok) {
          res.text().then(err => triggerToast('error', err));
          navigate('/order');
        }
      });
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-gray-500 relative flex justify-center h-full font-inter">
      <div className="min-h-screen w-full relative bg-white max-w-[425px] h-full flex flex-col items-center">
        <div className="relative w-full mb-5 mt-5 mx-5">
          <FaChevronLeft
            onClick={() => {
              navigate(-1);
            }}
            className="absolute cursor-pointer mmd:ml-2 mt-1 left-0 mmd:text-2xl text-sm text-[#6888B2]"
          />
          <h1 className="mmd:text-[20px] text-sm font-bold text-center">{name} - Table {tableNumber}</h1>
        </div>
        <div className="relative w-full">
          <img
            loading='lazy'
            className="w-full px-5 aspect-square object-cover rounded-2xl"
            src={`${process.env.REACT_APP_API_URL}/${state.menu.image}`}
            alt="Menu"
          />
          <div className="flex justify-between mt-5 items-center  mb-2 px-5">
            <h1 className="mmd:text-[22px] text-xs font-bold leading-normal w-1/2">{state.menu.name}</h1>
            <p className="mmd:text-[18px] text-xs font-bold">{IDR.format(state.menu.offlinePrice)}</p>
          </div>
          <p className="px-5 mmd:text-[14px] text-xs text-gray-700">{state.menu.info}</p>

          {state.menu.addons.map((addon) => (
            <div key={addon.id}>
              <div className="w-full h-1 mt-5 bg-[#D9D9D94D]"></div>
              <h1 className="font-bold px-5 my-2 mmd:text-base text-sm">{addon.name}</h1>
              <form className="flex flex-col gap-2 px-5">
                {addon.options.map((option, index) => (
                  <div
                    key={option.id}
                    className={`flex items-center gap-2 pb-1 ${index !== addon.options.length - 1 ? 'border-b border-gray-700' : ''}`}>
                    <input
                      defaultChecked={option.default}
                      onChange={(e) => {
                        const opt = state.menu.addons
                          .find((addon) => addon.id === parseInt(e.target.name))
                          .options.find((option) => option.id === parseInt(e.target.value));
                        if (addon.multiple) {
                          if (selectOrder.find((o) => o.id === opt.id)) {
                            setSelectOrder((selectOrder) =>
                              selectOrder.filter((order) => order.id !== opt.id)
                            );
                          } else {
                            setSelectOrder((selectOrder) => [
                              ...selectOrder,
                              {
                                ...opt,
                                addonId: addon.id
                              }
                            ]);
                          }
                        } else {
                          const s = selectOrder.filter((order) => order.addonId !== addon.id);
                          if (s.find((o) => o.id === opt.id)) {
                            setSelectOrder(() =>
                              order.map((o) => {
                                if (o.addonId === addon.id) {
                                  return opt;
                                }
                                return o;
                              })
                            );
                          } else {
                            setSelectOrder(() => [
                              ...s,
                              {
                                ...opt,
                                addonId: addon.id
                              }
                            ]);
                          }
                        }
                      }}
                      id={`${option.id}`}
                      value={option.id}
                      className="accent-[#6888B3]"
                      name={`${addon.id}`}
                      type={addon.multiple ? 'checkbox' : 'radio'}
                    />
                    <label className="flex w-full justify-between" htmlFor={`${option.id}`}>
                      <p className='mmd:text-base text-xs'>{option.name}</p>
                      <p className='mmd:text-base text-xs'>+{IDR.format(option.price)}</p>
                    </label>
                  </div>
                ))}
              </form>
            </div>
          ))}
          <div className="w-full h-1 mt-5 bg-[#D9D9D94D]"></div>
          <div className="form-control mt-5">
            <label htmlFor="notes" className="font-bold px-5 mb-2 mmd:text-base text-sm">
              Catatan
            </label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              rows={5}
              name="notes"
              id="notes"
              className="textarea mx-5 textarea-bordered"></textarea>
          </div>
          <div className="w-full h-1 mt-5 bg-[#D9D9D94D]"></div>
          <div className="flex px-5 items-center my-5 justify-between">
            <p className="font-bold mmd:text-base text-sm">Jumlah</p>
            <div className="flex gap-2 items-center">
              <button
                onClick={() => {
                  if (state.menu.quantity === 1) return;
                  state.menu.quantity--;
                  setOrder((order) =>
                    order.map((o) => {
                      if (o.orderId === state.menu.orderId) {
                        return {
                          ...state.menu,
                          quantity: state.menu.quantity,
                          addons: selectOrder,
                          notes,
                          allAddon: state.menu.addons,
                          orderId: order.length
                        };
                      }
                      return o;
                    })
                  );
                }}
                className="font-bold text-white bg-[#6888B2] mmd:w-7 mmd:h-7 w-4 h-4 mmd:text-base text-xs rounded-full text-center ">
                -
              </button>
              <input
              type="int"
               onChange={(e) => {
                let value = parseInt(e.target.value) || 0;
                if (value < 1) value = 1
                state.menu.quantity = value;
                setOrder((order) =>
                  order.map((o) => {
                    if (o.orderId === state.menu.orderId) {
                      return {
                        ...state.menu,
                        quantity: state.menu.quantity,
                        addons: selectOrder,
                        notes,
                        allAddon: state.menu.addons,
                        orderId: order.length
                      };
                    }
                    return o;
                  })
                );
              }} 
              value={state.menu.quantity} 
              className="border-[#6888B2] max-w-[60px] mmd:text-base text-xs border rounded-md text-center" />
              <button
                onClick={() => {
                  state.menu.quantity++;
                  setOrder((order) =>
                    order.map((o) => {
                      if (o.orderId === state.menu.orderId) {
                        return {
                          ...state.menu,
                          quantity: state.menu.quantity,
                          addons: selectOrder,
                          notes,
                          allAddon: state.menu.addons,
                          orderId: order.length
                        };
                      }
                      return o;
                    })
                  );
                }}
                className="font-bold text-white bg-[#6888B2] mmd:w-7 mmd:h-7 w-4 h-4 mmd:text-base text-xs rounded-full text-center">
                +
              </button>
            </div>
          </div>
          <div className="mb-20 px-5 pb-5 flex justify-between items-center">
            <p className="font-bold mmd:text-base text-sm">Subtotal</p>
            <p className='mmd:text-base text-xs'>
              {IDR.format(
                (state.menu.offlinePrice + selectOrder.reduce((acc, curr) => acc + curr.price, 0)) *
                state.menu.quantity
              )}
            </p>
          </div>
        </div>
        <div className="fixed max-w-[425px] bottom-0 border-t-4 border-t-[#D9D9D94D] p-5 w-full flex justify-around items-center gap-5 bg-white">
          <button
            onClick={() => {
              if (state.menu.updating) {
                setOrder((order) =>
                  order.map((o) => {
                    if (o.orderId === state.menu.orderId) {
                      return {
                        ...state.menu,
                        addons: selectOrder,
                        notes,
                        allAddon: state.menu.addons,
                        takeAway: true,
                        orderId: order.length
                      };
                    }
                    return o;
                  })
                );
                setSelectOrder([]);
                return navigate(-1);
              }
              setOrder(() => [
                ...order,
                {
                  ...state.menu,
                  addons: selectOrder,
                  notes,
                  takeAway: true,
                  orderId: order.length,
                  allAddon: state.menu.addons
                }
              ]);
              setSelectOrder([]);

              navigate(-1);
            }}
            className="btn w-[100px] mmd:w-[120px] mmd:text-base text-xs font-bold btn-outline border-[#6888B2] text-[#6888B2]">
            Take Away
          </button>
          <button
            onClick={() => {
              if (state.menu.updating) {
                setOrder((order) =>
                  order.map((o) => {
                    if (o.orderId === state.menu.orderId) {
                      return {
                        ...state.menu,
                        addons: selectOrder,
                        notes,
                        allAddon: state.menu.addons,
                        takeAway: false,
                        orderId: order.length
                      };
                    }
                    return o;
                  })
                );

                setSelectOrder([]);
                return navigate(-1);
              }
              setOrder(() => [
                ...order,
                {
                  ...state.menu,
                  addons: selectOrder,
                  notes,
                  takeAway: false,
                  orderId: order.length,
                  allAddon: state.menu.addons
                }
              ]);

              setSelectOrder([]);
              navigate(-1);
            }}
            className="btn w-[100px] mmd:w-[120px] mmd:text-base text-xs bg-[#6888B2] text-white font-bold">
            Dine In
          </button>
        </div>
      </div>
    </div>
  );
}
