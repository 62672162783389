import React from 'react';
import { CashierOrderContext } from '../../../../context/CashierOrderProvider';
import { AuthContext } from '../../../../context/AuthProvider';
import { scopesEnum } from '../../../../lib';
import { triggerToast } from '../../../../utils';

export default function OrderInfo() {
  const {
    order,
    menus,
    setMenuId,
    setAddonsProps,
    setQuantityProps,
    setOrderItemToBeUpdated,
    orderItemToBeUsed,
    orderItems
  } = React.useContext(CashierOrderContext);

  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  function getMenuName(menuId) {
    const menu = menus.find((menu) => menu.id == menuId);

    return menu.name;
  }

  function getAddonOptionName(addonOptionId) {
    const addonOption = menus.reduce((result, menu) => {
      const foundOption = menu.addons.reduce((res, addon) => {
        return res || addon.options.find((option) => option.id === addonOptionId);
      }, null);

      return result || foundOption;
    }, null);

    return addonOption?.name;
  }

  return (
    <>
      {orderItemToBeUsed.map((orderItem, index) => (
        <div className="flex flex-col items-end w-full" key={`order-items-${orderItem.id}`}>
          {order.orderItems && order.orderItems.length > 0 && index == 0 && (
            <h2 className="text-center font-semibold text-lg w-full">Sudah Ditaruh</h2>
          )}
          <div
            className="menu w-full flex flex-row gap-2 justify-start md:-ml-2 -ml-7 py-2 cursor-pointer hover:bg-gray-300/80 hover:rounded-lg"
            onClick={() => {
              // TODO: ADD ACL
              if (orderItem.temp && !decideIfRoleHasAccess(scopesEnum.UPDATE_UNPLACED_ORDER)) {
                triggerToast('error', 'You are not allowed to update unplaced order!');
                return;
              }

              if (!orderItem.temp && !decideIfRoleHasAccess(scopesEnum.UPDATE_PLACED_ORDER)) {
                triggerToast('error', 'You are not allowed to update order!');
                return;
              }

              let addons = [];

              if (!orderItem.temp) {
                addons = orderItem.OrderAddon.map((orderAddon) => ({
                  addonId: orderAddon.addonId,
                  optionId: orderAddon.optionId,
                  price:
                    order.type == 'ONLINE' ? orderAddon.option.priceOnline : orderAddon.option.price
                }));
              } else {
                addons = orderItem.addons.map((addon) => ({
                  addonId: addon.addonId,
                  optionId: addon.optionId,
                  price: addon.price
                }));
              }

              setMenuId(orderItem.menuId);
              setAddonsProps(addons);
              setQuantityProps(orderItem.quantity);
              setOrderItemToBeUpdated(orderItem);
              document.querySelector('#modal-order').showModal();
            }}>
            <li className="flex flex-col">
              <p className="font-semibold">{getMenuName(orderItem.menuId)}</p>
              <ul>
                {!orderItem.temp &&
                  orderItem.OrderAddon.map((orderAddon) => (
                    <li key={`order-addon-${orderAddon.addonId}-${orderAddon.optionId}`}>
                      {getAddonOptionName(orderAddon.optionId)}
                    </li>
                  ))}
                {orderItem.temp &&
                  orderItem.addons.map((addon) => (
                    <li key={`order-addon-${addon.addonId}-${addon.optionId}`}>
                      {getAddonOptionName(addon.optionId)}
                    </li>
                  ))}
              </ul>
              <ul>
                <p className="-ml-2">{orderItem.type == 'DINE_IN' ? 'Dine In' : 'Take Away'}</p>
              </ul>
              {orderItem && <ul><p className="-ml-2">{orderItem.notes}</p></ul>}
            </li>
            <div className="flex flex-row ml-auto px-4 py-2 font-semibold">
              <p className="mr-2">x{orderItem.quantity}</p>
              <p>Rp. {orderItem.price}</p>
            </div>
          </div>
          <div className="flex w-full justify-center py-2">
            <div className="divider m-0 text-center w-2/5"></div>
          </div>
          {order.orderItems && orderItems.length > 0 && index == order.orderItems.length - 1 && (
            <h2 className="text-center font-semibold text-lg w-full">Belum Ditaruh</h2>
          )}
        </div>
      ))}
    </>
  );
}
