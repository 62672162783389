import React from 'react';
import { AuthContext } from '../../../context/AuthProvider';
import { RestaurantProvider } from '../../../context/RestaurantProvider';
import { scopesEnum } from '../../../lib';
import ModalBox from './ModalBox';
import TabBar from './TabBar';

export default function RestaurantManagementPage() {
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return (
    <>
      {decideIfRoleHasAccess(scopesEnum.RESTAURANT_MANAGEMENT) ? (
        <RestaurantProvider>
          <div className="flex min-h-screen justify-center items-center flex-col px-2">
            <div className="card w-full bg-base-100 shadow-xl min-h-screen h-fit">
              <div className="card-body md:gap-8 sm:gap-6 gap-4">
                <div role="tablist" className="tabs tabs-lifted m-5">
                  <TabBar />
                </div>
              </div>
            </div>
            <ModalBox />
          </div>
        </RestaurantProvider>
      ) : (
        <div className="flex min-h-screen justify-center items-center flex-col">
          <h1 className="text-3xl font-bold">You are not allowed to access this page</h1>
        </div>
      )}
    </>
  );
}
