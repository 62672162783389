import React from 'react';
import PropTypes from 'prop-types';
import { RestaurantContext } from '../../../context/RestaurantProvider';
import { AuthContext } from '../../../context/AuthProvider';
import { scopesEnum } from '../../../lib';
import { triggerToast } from '../../../utils';
import { FaPenAlt, FaTrashAlt } from "react-icons/fa";

export default function Item({ id, name, printerLocationId, capacity, link }) {
  const { setDeleteModal, setUpdateModal, activeTab } = React.useContext(RestaurantContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  function decideScopesEditEnum() {
    if (activeTab === 'Table') {
      return scopesEnum.UPDATE_TABLE;
    } else if (activeTab === 'Lokasi Printer') {
      return scopesEnum.UPDATE_KITCHEN;
    } else {
      return scopesEnum.UPDATE_PAYMENT_METHOD;
    }
  }

  function decideScopesDeleteEnum() {
    if (activeTab === 'Table') {
      return scopesEnum.DELETE_TABLE;
    } else if (activeTab === 'Lokasi Printer') {
      return scopesEnum.DELETE_KITCHEN;
    } else {
      return scopesEnum.DELETE_PAYMENT_METHOD;
    }
  }

  return (
    <div className="flex justify-between items-center md:px-8 px-6 lg:py-6 md:py-4 py-2 border-2 rounded-xl">
      <p className="font-semibold text-justify">
        {name} {capacity && `- ${capacity} pax`}
      </p>
      <div className="flex gap-2">
        <button onClick={() => {
          if (!decideIfRoleHasAccess(decideScopesEditEnum())) {
            triggerToast('error', 'You are not allowed to access this functionality');
            return;
          }

          setDeleteModal({
            show: false,
            name: '',
            link: ''
          });
          setUpdateModal({
            id: id,
            name: name,
            printerLocationId: printerLocationId,
            capacity: capacity,
            update: true
          });
          document.getElementById('modal-restaurant').showModal();
        }}
          className="btn btn-warning btn-sm">
          <FaPenAlt className="text-white" />
        </button>
        <span style={{width:"10px"}}/>
        <button onClick={() => {
          if (!decideIfRoleHasAccess(decideScopesDeleteEnum())) {
            triggerToast('error', 'You are not allowed to access this functionality');
            return;
          }

          setDeleteModal({
            show: true,
            name: name,
            link: link
          });
          document.getElementById('modal-restaurant').showModal();
        }}
          className="btn btn-error btn-sm">
          <FaTrashAlt className="text-white" />
        </button>
      </div>
    </div>
  );
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  printerLocationId: PropTypes.number,
  capacity: PropTypes.number,
  link: PropTypes.string.isRequired
};
