import React from 'react';
import { documentHeader, DocumentHeaderProps } from "../components/document-header"
import { render, Printer, Text, Line, Cut, Row, Br } from 'react-thermal-printer';
import { formattedDoubleToRupiahString, columnVariableValue, columnLeftRight, getAddOnNameWithPriceRightLimit } from '../components/formatter';
import { CollumnFormatType } from '../PrinterEnum';

export const renderBillDocument = async ({ bill }) => {
  return render(<Printer type="epson" width={48}>
    {billDocument({ bill })}
  </Printer>);
}

const billDocument = ({ bill }) => {
  const documentHeaderData = new DocumentHeaderProps({
    title: "Payment",
    orderId: bill.orderId,
    userName: bill.customerName,
    tableNumber: bill.tableNumber,
    date: bill.date,
    contentTableRightVar: "SubTotal",
    showIdentity: true,
  });

  return (
    <>
      {documentHeader(documentHeaderData)}
      {content(bill)}
      {footer(bill)}
    </>
  );
};

const footer = (bill) => {
  return (
    <>
      <Line character='=' />
      <Br />
      <Text align="center" bold>No Nota</Text>
      <Text align="center">{bill.noNota}</Text>
      {bill.keterangan && <Text align="center" bold>Keterangan</Text>}
      {bill.keterangan && <Text align="center">{bill.keterangan}</Text>}
      <Cut partial={true} lineFeeds={4} />
    </>
  );
}

const content = (bill) => {
  const { orderItems } = bill;
  return (
    <>
      {orderItems.map((item) => (
        contentItem(item)
      ))}

      <Line />

      <Row left={"Sub Total"} center={""} right={formattedDoubleToRupiahString(bill.subTotal)} />
      <Row left={"PB1 10%"} center={""} right={formattedDoubleToRupiahString(bill.pb1)} />
      <Br/>
      <Text align='left' bold>{columnLeftRight({ left: "Grand Total", right: formattedDoubleToRupiahString(bill.grandTotal) })}</Text>
    </>
  );
}

const contentItem = (item) => {
  return (
    <>
      <Row left={columnVariableValue({ variable: item.quantity, value: item.name, collumnFormat: CollumnFormatType.QtyItem })} right={`${formattedDoubleToRupiahString(item.priceBeforeTax)}    `} />
      {item.type === 'TAKE_AWAY' && <Text align='left' bold>TAKE AWAY</Text>}
      {item.addons.map((addon) => {
        const addOnRows = getAddOnNameWithPriceRightLimit({ addOn: addon.name, leftSpace: 7, price: addon.priceBeforeTax });
        {
          return addOnRows.length === 0 ? (
            <Br />
          ) : (

            addOnRows.map((addOnRow, index) => (
              <Text key={`text-${addon.name}-${index}`}>{addOnRow}</Text>
            ))
          );
        }
      })}
      <Text bold align='right'>{formattedDoubleToRupiahString(item.totalPriceBeforeTax)} </Text>
      <Br />
    </>
  )
}