class USBManager {
  constructor() {
    this.usbController = null;
    this.usbName = '';
    this.usbConfiguration = '';
  }

  async openUsbConnection() {
    try {
      if (this.usbController) {
        // Disconnect the existing USB connection
        await this.usbController.close();
        this.usbController = null;
        this.usbName = null;
        this.usbConfiguration = null;
        console.log('Disconnected existing USB connection');
      }

      // Find a new USB device
      this.usbController = await navigator.usb.requestDevice({ filters: [] });
      await this.usbController.open();
      await this.usbController.selectConfiguration(1);
      await this.usbController.claimInterface(0);

      // Extract USB name and configuration info
      this.usbName = this.usbController.productName || 'Unknown';
      this.usbConfiguration = this.usbController.configuration.configurationName || 'Unknown';
      console.log(this.usbName);

      console.log('New USB connection opened');
    } catch (error) {
      console.error('Error opening or finding USB connection:', error);
    }
  }

  getUsbInfo() {
    return {
      name: this.usbName,
      configuration: this.usbConfiguration
    };
  }

  async handleUsbData(data) {
    try {
      if (this.usbController) {
        await this.usbController.transferOut(1, data);
        console.log('Data transferred via USB');
        return true
      } else {
        // Attempt to open USB connection, retrying up to N times with error handling
        const MAX_RETRY_ATTEMPTS = 3; // Adjust based on your requirements
        let attempts = 0;
        while (!this.usbController && attempts < MAX_RETRY_ATTEMPTS) {
          attempts++;
          try {
            await this.openUsbConnection();
          } catch (error) {
            console.error('Failed to open USB connection:', error);
            // Implement appropriate error handling or backoff strategy here
          }
        }

        if (this.usbController) {
          // If successful, retry data transfer
          this.handleUsbData(data); // Recursive call
        } else {
          console.error('Failed to open USB connection after retries.');
          // Handle the case where opening the connection fails even after retries
          return false;
        }
      }
    } catch (error) {
      console.error('Error handling USB:', error);
      // Implement additional error handling or logging as needed
      return false;
    }
  }


  async closeUsbConnection() {
    try {
      if (this.usbController) {
        await this.usbController.close();
        this.usbController = null; // Reset USB controller
        console.log('USB connection closed');
      } else {
        console.log('No USB connection to close');
      }
    } catch (error) {
      console.error('Error closing USB connection:', error);
    }
  }
}

// Export a single instance of USBManager
const usbManager = new USBManager();
export default usbManager;
