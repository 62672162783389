import React, { useEffect, useState } from "react"
import { AuthenticatedFetch } from "../../../lib";
import EmptyTableModal from "../../../components/operation/EmptyTableModal";
import { OperationContext } from "../../../context/OperationContext";
import ReprintModal from "../../../components/operation/ReprintModal";
import MoveTableModal from "../../../components/operation/MoveTableModal";
import { triggerToast } from "../../../utils/toast"
import PrintSuccessModal from "../../../components/operation/PrintSucessModal";

export const SelectedTableContext = React.createContext({
    selectedTable: null,
    setSelectedTable: () => { }
});

export default function OperationPage() {
    const [date, setDate] = useState(new Date());

    const [selectedTable, setSelectedTable] = useState(null);
    const [tables, setTables] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await AuthenticatedFetch('table/seated');
            const data = await response.json();
            setTables(data);
            setDate(new Date());
        };

        fetchData();
        const interval = setInterval(fetchData, 1000 * (process.env.REACT_APP_OPERATION_INTERVAL_DURATION_IN_SECOND || 5));
        return () => clearInterval(interval);
    }, []);

    return (
        <OperationContext.Provider value={{ setTables, tables }}>
            <SelectedTableContext.Provider value={{ selectedTable, setSelectedTable }}>
                <div className="flex min-h-screen justify-center items-center flex-col px-2 py-4">
                    <div className="card md:w-[90%] bg-base-100 shadow-xl">
                        <div className="p-5">
                            <p>{new Intl.DateTimeFormat('id-ID', {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                            }).format(date)}</p>

                            <div className="flex justify-between w-full mt-10" style={{marginBottom:'2rem'}}>
                                <button onClick={() => {
                                    if (!selectedTable) {
                                        triggerToast("error", "Please select a table first");
                                        return;
                                    };
                                    document.getElementById("empty_table_modal").show()
                                }} className="btn btn-primary w-[185px]">Kosongkan Meja</button>
                                <button onClick={() => document.getElementById("reprint_modal").show()} className="btn btn-primary w-[185px]">Print Ulang Document</button>
                                <button onClick={() => {
                                    if (!selectedTable) {
                                        triggerToast("error", "Please select a table first");
                                        return;
                                    };
                                    document.getElementById("move_table_modal").show()
                                }} className="btn btn-primary w-[185px]">Pindah Meja</button>
                            </div>

                            <div className="w-full grid grid-cols-4 gap-4 grid-rows-2">
                                {tables.map((table, idx) => (
                                    <TableCard key={idx} {...table} />
                                ))}
                            </div>

                            <div className="flex justify-between w-full mt-10">
                                <button onClick={() => {
                                    if (!selectedTable) {
                                        triggerToast("error", "Please select a table first");
                                        return;
                                    };
                                    document.getElementById("empty_table_modal").show()
                                }} className="btn btn-primary w-[185px]">Kosongkan Meja</button>
                                <button onClick={() => document.getElementById("reprint_modal").show()} className="btn btn-primary w-[185px]">Print Ulang Document</button>
                                <button onClick={() => {
                                    if (!selectedTable) {
                                        triggerToast("error", "Please select a table first");
                                        return;
                                    };
                                    document.getElementById("move_table_modal").show()
                                }} className="btn btn-primary w-[185px]">Pindah Meja</button>
                            </div>
                            <EmptyTableModal />
                            <ReprintModal />
                            <MoveTableModal />
                            <PrintSuccessModal />
                        </div>
                    </div>
                </div>
            </SelectedTableContext.Provider>
        </OperationContext.Provider>
    )
}

const TableCard = (table) => {
    const people = table.Order?.reduce((acc, curr) => acc + curr.numberOfPeople, 0) || 0;
    const { setSelectedTable, selectedTable } = React.useContext(SelectedTableContext);
    const color = () => {
        if (selectedTable?.tableNumber === table?.tableNumber) {
            return "bg-[#E6E6E6]"
        } else if (people > table.paxs) {
            return "bg-[#F8CECC] border-gray-300";
        } else if (people === 0 && table.Order?.length === 0) {
            return "bg-[#D5E8D4] border-green-300";
        } else {
            return "bg-[#FFF2CC] border-yellow-300";
        }
    }

    return (
        <div onClick={() => {
            triggerToast("success", `Table ${table.tableNumber} is selected`);
            setSelectedTable(table);
        }} className={`p-5 cursor-pointer border-2 grid place-items-center rounded-lg text-white text-center ${color()}`}>
            <div className="text-black">
                <h1>Table {table.tableNumber}</h1>
                {table.Order && table.Order.map((order) => (
                    <div key={order.id}>
                        {order.id || "orderId"} - {order.customerName || "nama"} - {order.numberOfPeople || 0} pax
                    </div>
                ))}
                <p>{people}/{table.paxs} Pax</p>
            </div>
        </div>
    )
}