import { Drawer, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useContext, useEffect, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderContext } from '../../contexts/OrderContext';
import { AuthenticatedFetch } from '../../lib';
import { triggerToast } from '../../utils';
import { sortCategoriesByRank, sortMenusByRank } from '../../utils/categorySorter';

export function OrderMenu() {
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const { order, setOrder, highlightedMenu, setHighlightedMenu, total, setTotal, highlight, name, setName, tableNumber, setTableNumber, status, setStatus, activeTab, setActiveTab } = useContext(OrderContext);
  const { uid } = useParams();

  useEffect(() => {
    AuthenticatedFetch(`menus`)
      .then((res) => res.json())
      .then((data) => {
        setMenus(sortMenusByRank(data, true));
      });

    AuthenticatedFetch(`categories`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(sortCategoriesByRank(data, true));
      });

    fetch(`${process.env.REACT_APP_API_URL}/order/${uid}`)
      .then((res) => {
        if (!res.ok) {
          res.text().then(err => triggerToast('error', err));
          navigate('/order');
        } else {
          res.json().then(data => {
            setName(data.customerName),
              setTableNumber(data.tableNumber),
              setStatus(data.status)
          })
        }
      })
  }, []);

  useEffect(() => {
    setTotal(
      order.reduce(
        (total, order) =>
          total +
          order.quantity *
          (order.offlinePrice + order?.addons?.reduce((total, addon) => total + addon.price, 0)),
        0
      )
    );
  }, [order]);

  const IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });

  const navigate = useNavigate();
  if (status === 'PAID') {
    return (
      <div className="flex items-center justify-center h-screen w-screen font-inter bg-white">
        Order Sudah Selesai
      </div>
    )
  }

  function handleChange(orderId, newQuantity) {
    newQuantity = newQuantity || 0;
    if (newQuantity < 0) newQuantity = 0;
    let newOrder = order
      .filter((order) => order.orderId !== orderId || newQuantity > 0)
      .map((order) => {
        if (order.orderId === orderId) {
          return {
            ...order,
            quantity: newQuantity
          };
        }
        return order;
      });
    setOrder(newOrder);

    setTotal(
      newOrder.reduce(
        (total, order) =>
          total +
          order.quantity *
          (order.offlinePrice + order?.addons?.reduce((total, addon) => total + addon.price, 0)),
        0
      )
    );
  }

  return (
    <div className="w-full overflow-x-hidden bg-gray-500 relative flex justify-center h-full font-inter">
      <div className="min-h-screen relative bg-white max-w-[425px] h-full flex flex-col items-center p-5">
        <nav>
          <div className="relative">
            <h1 className="font-bold text-sm mmd:text-[20px] text-center mb-5">{name} - {tableNumber ? `Table ${tableNumber}` : "Waiting List"}</h1>
            <IoDocumentTextOutline
              onClick={() => navigate(`/order/${uid}/bill`)}
              className="absolute top-1 cursor-pointer text-base mmd:text-xl text-[#686B6F] right-2 mmd:right-5"
            />
          </div>
          <Tabs color="#6888B2" defaultValue="all" w={'100%'} value={activeTab} onChange={setActiveTab}>
            <Tabs.List
              className="w-screen !max-w-[425px] !overflow-x-scroll !flex-nowrap"
              justify="start">
              <Tabs.Tab value="all">
                <p className="text-xs flex items-center gap-4">
                  All{' '}
                  {order.reduce((acc, curr) => acc + curr.quantity, 0) !== 0 && (
                    <div className="text-xs py-1 rounded-full bg-[#6888B2] w-8 text-center text-white">
                      {order.reduce((acc, curr) => acc + curr.quantity, 0)}
                    </div>
                  )}
                </p>
              </Tabs.Tab>
              {categories.map((category) => (
                <Tabs.Tab className='!min-w-[100px]' key={category.id} value={`${category.id}`}>
                  <p className="text-xs flex items-center gap-4">
                    {category.name}{' '}
                    {order
                      .filter((order) => order?.category?.id === category?.id)
                      .reduce((acc, curr) => acc + curr.quantity, 0) !== 0 && (
                        <div className="text-xs py-1 rounded-full bg-[#6888B2] w-8 text-center text-white">
                          {order
                            .filter((order) => order?.category?.id === category?.id)
                            .reduce((acc, curr) => acc + curr.quantity, 0)}
                        </div>
                      )}
                  </p>
                </Tabs.Tab>
              ))}
            </Tabs.List>
            <Tabs.Panel value="all">
              <div className="flex items-stretch justify-around my-5 flex-wrap">
                {menus
                  .filter((m) => !m.deleted)
                  .map((menu, i) => (
                    <MenuCard key={i} menu={menu} open={open} />
                  ))}
                {menus.length % 2 === 1 && <div className="w-1/2"></div>}
              </div>
            </Tabs.Panel>
            {categories.map((category) => (
              <Tabs.Panel key={category.id} value={`${category.id}`}>
                <div className="flex justify-around items-stretch my-5 flex-wrap">
                  {menus
                    .filter((m) => !m.deleted)
                    .filter((menu) => menu?.category?.id === category?.id)
                    .map((menu) => (
                      <MenuCard key={menu.id} menu={menu} open={open} />
                    ))}
                  {menus.length % 2 === 1 && <div className="w-1/2"></div>}
                </div>
              </Tabs.Panel>
            ))}
          </Tabs>
        </nav>
        {total !== 0 && (
          <footer className="fixed bottom-0 p-5 flex justify-around items-center gap-5">
            <button
              onClick={() => navigate(`/order/${uid}/tally`)}
              className="btn border-none bg-[#6888B2] text-white">
              Total Pesanan {IDR.format(total)}
            </button>
          </footer>
        )}
        <Drawer
          offset={0}
          radius="md"
          size="50%"
          opened={opened}
          onClose={close}
          position="bottom"
          title="">
          {order
            .filter((o) => o.id === highlight)
            .map((o, i) => (
              <div key={i}>
                <h1 className="my-2 font-bold text-[#333131]">{o.name}</h1>
                <div className="w-full border-b-[3px]"></div>
                <div className="flex mt-2 justify-between text-xs text-[#333131]">
                  <p className="w-1/2 text-wrap mmd:text-base text-xs">
                    {o.addons.map((addon) => addon.name).join(', ')}
                    { }
                  </p>
                  <p className="font-bold mmd:text-base text-xs">
                    {IDR.format(
                      o.quantity * (o.offlinePrice + o?.addons?.reduce((total, addon) => total + addon.price, 0)),
                      0
                    )}
                  </p>
                </div>
                <p className="text-xs">notes: {o.notes || 'N/A'}</p>
                {o.takeAway && <p className="text-xs">TAKE AWAY</p>}
                <div className="flex justify-between my-3">
                  <div></div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        navigate(`${o.id}`, {
                          state: {
                            menu: {
                              ...o,
                              updating: true,
                              addons:
                                o?.allAddon?.map((addon) => ({
                                  ...addon,
                                  options: addon.options.map((option) => ({
                                    ...option,
                                    default: !!o.addons.find((a) => a.id === option.id)
                                  }))
                                })) || []
                            }
                          }
                        });
                      }}
                      className="btn mmd:text-base text-xs text-gray-500 btn-sm btn-outline btn-neutral">
                      Edit <FaPen className='mmd:text-base text-xs' />
                    </button>
                    <div className="flex gap-2">
                      <button
                        className="font-bold mmd:text-base text-xs text-white bg-[#6888B2] mmd:w-7 w-4 rounded-full text-center mmd:h-7 h-4"
                        onClick={() => handleChange(o.orderId, o.quantity - 1)}
                      >
                        -
                      </button>
                      <input
                        type="int"
                        style={{ width: "3rem" }}
                        className="border-[#6888B2] mmd:text-base text-xs border rounded-md text-center"
                        value={o.quantity}
                        onChange={(e) => {
                          console.log("asd")
                          handleChange(o.orderId, parseInt(e.target.value));
                        }}
                      />
                      <button
                        className="font-bold mmd:text-base text-xs text-white bg-[#6888B2] mmd:w-7 w-4 rounded-full text-center mmd:h-7 h-4"
                        onClick={() => handleChange(o.orderId, o.quantity + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="w-full border-b-[3px]"></div>
              </div>
            ))}
          <div className="w-full flex justify-center mt-3">
            <button
              onClick={() => navigate(`${highlightedMenu.id}`, {
                state: {
                  menu: {
                    ...highlightedMenu,
                    quantity: 1
                  }
                }
              })}
              className="text-white mmd:text-base text-xs font-bold w-5/6 btn bg-[#6888B2]">
              Tambah Pesanan Baru
            </button>
          </div>
        </Drawer>
      </div>
    </div>
  );
}

const MenuCard = ({ menu, open }) => {
  const IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });
  const navigate = useNavigate();
  const { order, setHighlight, setHighlightedMenu } = useContext(OrderContext);
  const [quantity, setQuantity] = useState(0);
  useEffect(() => {
    setQuantity(
      order.reduce((acc, curr) => {
        if (curr.id === menu.id) {
          return acc + curr.quantity;
        }
        return acc;
      }, 0)
    );
  }, [order]);

  const disabled = !menu.available;
  return (
    <div
      onClick={disabled ? null : () => {
        if (quantity !== 0) {
          setHighlight(menu.id);
          setHighlightedMenu(menu);
          return open();
        }
        navigate(`${menu.id}`, {
          state: {
            menu: {
              ...menu,
              quantity: 1
            }
          }
        });
      }}
      className="w-full flex-[1,0,auto] mmd:w-1/2 p-5 overlay-wrapper">
      <div
        className={`${quantity !== 0 ? 'bg-gray-200' : 'bg-white'
          } h-full w-full rounded-lg ${disabled ? null : 'hover:bg-gray-200'} transition-all cursor-pointer`}>
        {disabled && <div className='overlay'></div>}
        <img
          loading='lazy'
          className="w-full aspect-square object-cover rounded-lg"
          src={`${process.env.REACT_APP_API_URL}/${menu.image}`}
          alt="Menu"
        />
        <div className="flex p-1 justify-between gap-2 items-center ml-1">
          <div className=" w-3/4">
            <h1 className="font-bold text-xs sm:text-sm">{menu.name}</h1>
            <p className="text-xs mb-2">{IDR.format(menu.offlinePrice)}</p>
          </div>
          {quantity !== 0 && (
            <div className="text-xs mlg:py-2 py-1 px-1 rounded-full bg-[#6888B2] mlg:w-8 w-7 text-center text-white mr-1">
              {quantity}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
