import { DocumentQueueApiUrls, DocumentQueueType } from "../../../printer/PrinterEnum";
import { renderCaptainOrderDocument } from "../../../printer/documents/captain-order";
import { renderCheckerDocument } from '../../../printer/documents/checker';
import { renderBillDocument } from '../../../printer/documents/bill';
import { renderQRDocument } from '../../../printer/documents/qr';
import { triggerToast } from "../../../utils";
import { AuthenticatedFetch } from "../../../lib";

export async function printDocument(document, printerType, portManager, usbManager) {
  let dataToPrint;
  switch (document.type) {
    case DocumentQueueType.PAYMENT:
      dataToPrint = await renderBillDocument({ bill: document });
      break;

    case DocumentQueueType.CAPTAIN_ORDER:
      dataToPrint = await renderCaptainOrderDocument({ captainOrder: document });
      break;

    case DocumentQueueType.CHECKER:
      dataToPrint = await renderCheckerDocument({ checker: document });
      break;

    case DocumentQueueType.QR:
      dataToPrint = await renderQRDocument({ qr: document });
      break;
    default:
      console.log('Unknown type:', document);
  }

  if (dataToPrint !== null) {
    try {
      let isSuccess = false;
      if (printerType === 'Bluetooth') {
        isSuccess = portManager.handleBluetoothData(dataToPrint);
      } else if (printerType === 'USB') {
        isSuccess = usbManager.handleUsbData(dataToPrint);
      }
      if (isSuccess) return document.queueId;
      else return null;
    } catch (e) {
      triggerToast('error', `gagal print document id : ${dataToPrint.orderId}`);
    }
  }
  return undefined;
}

export async function printerForEachAsync(array, asyncCallback) {
  const newArray = [];
  for (const item of array) {
    let result = await asyncCallback(item);
    if (result !== null) newArray.push(result);
  }
  return newArray;
}

export function getPrinterDeviceName(printerType, usbData, bluetoothData) {
  let deviceName = '';
  
  if (printerType === 'USB') {
    deviceName = usbData.name;
  } else if (printerType === 'Bluetooth') {
    deviceName = bluetoothData.id;
  }
  return deviceName;
}

export async function printDocumentQueue(printerType, printerLocationId, portManager, usbManager, playAudio) {
  try {
    if (printerLocationId === null) return triggerToast('error', `Harap pilih lokasi`);
    if (printerType === null || printerType === '') return triggerToast('error', `Harap pilih printer device`);

    const res = await AuthenticatedFetch(
      DocumentQueueApiUrls.GET_BY_PRINTER_LOCATION_ID(printerLocationId)
    , null, true);

    if (!res.ok) {
      throw new Error(`${res.status} - ${res.statusText}`);
    }

    const data = await res.json();
    if (printerType === null) return;
    const successIds = await printerForEachAsync(
      data.data,
      async (document) => await printDocument(document, printerType, portManager, usbManager)
    );

    if (successIds.length > 0) {
      playAudio();
      const deleteRes = await AuthenticatedFetch(DocumentQueueApiUrls.BASE, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids: successIds })
      });

      if (deleteRes.ok) {
        triggerToast('success', `printing ${successIds}`);
      } else {
        triggerToast('error', `gagal menghapus ${successIds}`);
      }
    }
  } catch (e) {
    triggerToast('error', e.toString());
  }
};