import React, { useContext } from 'react';
import WaitingBox from './WaitingBox';
import { CustomerContext } from '../../../context/CustomerProvider';

export default function WaitingListBox() {
  const { waitingLists } = useContext(CustomerContext);

    return (
    <div className="flex flex-col md:gap-4 gap-2 justify-center items-start overflow-x-hidden hover:overflow-x-auto">
      <h2 className="text-lg font-bold">Waiting List</h2>
      <div className="flex flex-row gap-8 p-2 basis-1/5">
        {waitingLists &&
          waitingLists.map((waitingList) => (
            <WaitingBox key={`waiting-list-${waitingList.id}`} waitingList={waitingList} />
          ))}
      </div>
    </div>
  );
}
