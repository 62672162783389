import React, { useContext, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { handleInputChange } from '../../utils/handleInputChange';
import { triggerToast } from '../../utils/toast';
import { AuthContext } from '../../context/AuthProvider';
import { FaTimes } from "react-icons/fa";

export default function AddonCreateModal() {
  const { setAddons } = useContext(MenuContext);
  const [options, setOptions] = useState([]);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);
  const [option, setOption] = useState({
    name: '',
    price: 0,
    priceOnline: 0,
    default: false
  });
  const [selected, setSelected] = useState(0);
  const [addon, setAddon] = useState({
    name: '',
    multiple: false
  });

  const resetModalData = () => {
    setOptions([]);
    setOption({
      name: '',
      price: 0,
      priceOnline: 0,
      default: false
    });
    setSelected(0)
    setAddon({
      name: '',
      multiple: false
    })
  }

  return (
    <dialog id="addon_modal" className="modal">
      <div className="modal-box">
        <div className="modal-action mt-0">
          <form method="dialog">
            <button className="btn" onClick={() => { resetModalData() }}><FaTimes /></button>
          </form>
        </div>
        <h3 className="font-bold text-lg">Create Addon</h3>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!decideIfRoleHasAccess(scopesEnum.CREATE_ADDONS)) {
              triggerToast('error', 'You are not allowed to create addon!');
              return;
            }
            if (!options.reduce((acc, curr) => acc && curr.name !== '', true)) {
              return;
            }
          
            if (selected != null) {
              options[selected].default = true;
            }
            const res = await AuthenticatedFetch(`addons`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                ...addon
              })
            });
            if (!res.ok) {
              const data = await res.text();
              triggerToast('error', data);
              return;
            }
            const data = await res.json();
            await Promise.all(options.map(async (option) => {
              const res = await AuthenticatedFetch(`options`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  ...option,
                  addonId: data.id
                })
              });
              if (!res.ok) {
                const data = await res.text();
                triggerToast('error', data);
                return;
              }
            }))
            AuthenticatedFetch('addons')
              .then((res) => res.json())
              .then((data) => setAddons(data))
              .catch((err) => triggerToast(err.message, 'error'));
            setAddon({
              name: ''
            });
            document.getElementById('addon_modal').close();

            resetModalData();
          }}
          className="form-control m-3">
          <label className="label-text" htmlFor="name">
            Name
          </label>
          <input
            value={addon.name}
            onChange={(e) => handleInputChange(e, setAddon)}
            className="mb-3 input input-bordered"
            type="text"
            name="name"
            id="name"
          />
          <div className="mb-10 flex items-center">
            <input
              onChange={(e) => {
                setAddon({ ...addon, multiple: e.target.checked });
              }}
              className="mr-3 checkbox"
              type="checkbox"
              name="available"
              id="available"
            />
            <span>
              <label htmlFor="available">Multiple?</label>
            </span>
          </div>
          <form className="form-control">
            <div
              onClick={() => {
                if (!decideIfRoleHasAccess(scopesEnum.CREATE_ADDONS)) {
                  triggerToast('error', 'You are not allowed to create addon!');
                  return;
                }
                setOptions([...options, option]);
                setOption({
                  name: '',
                  price: 0,
                  priceOnline: 0,
                  default: false
                });
              }}
              className="btn btn-secondary mb-3">
              Add Option
            </div>
          </form>
          <div className="overflow-x-scroll w-full">
            <table className="table">
              <thead>
                <tr>
                  <th>Default?</th>
                  <th>Name</th>
                  <th>Offline Price</th>
                  <th>Online Price</th>
                </tr>
              </thead>
              <tbody>
                {options.map((option, idx) => (
                  <tr key={option.id}>
                    <td>
                      <input
                        checked={selected == idx}
                        onChange={() => setSelected(idx)}
                        type="radio"
                        name="default"
                        id="default"
                        value={idx}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="name"
                        value={options[idx].name}
                        onChange={(e) => {
                          setOptions(
                            options.map((option, i) => {
                              if (idx === i) {
                                return {
                                  ...option,
                                  name: e.target.value
                                };
                              }
                              return option;
                            })
                          );
                        }}
                        className="px-2 py-1 w-24  border border-gray-500 rounded-md"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="price"
                        value={options[idx].price}
                        onChange={(e) => {
                          setOptions(
                            options.map((option, i) => {
                              if (idx === i) {
                                return {
                                  ...option,
                                  price: parseInt(e.target.value)
                                };
                              }
                              return option;
                            })
                          );
                        }}
                        className="px-2 py-1 w-24 border border-gray-500 rounded-md"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="priceOnline"
                        value={options[idx].priceOnline}
                        onChange={(e) => {
                          setOptions(
                            options.map((option, i) => {
                              if (idx === i) {
                                return {
                                  ...option,
                                  priceOnline: parseInt(e.target.value)
                                };
                              }
                              return option;
                            })
                          );
                        }}
                        className="px-2 py-1 w-24 border border-gray-500 rounded-md"
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setOptions(options.filter((_, i) => idx !== i));
                        }}
                        className="btn btn-error btn-sm">
                        <FaTrashAlt className="text-white" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-primary mt-20">Create</button>
        </form>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn" onClick={() => { resetModalData() }}>Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
}
