/* eslint-disable */
import React from 'react';
import { documentHeader, DocumentHeaderProps } from "../components/document-header"
import { render, Printer, Text, Line, Cut, QRCode, Br } from 'react-thermal-printer';

export const renderQRDocument = async ({ qr }) => {
  return render(<Printer type="epson" width={48}>
    {qrDocument({ qr })}
  </Printer>);
}

const qrDocument = ({ qr }) => {
  let title;
  if (qr.waitingListId !== null && qr.waitingListId !== undefined) {
    title = `Waiting List ${qr.waitingListId}`
  } else {
    title = `Meja ${qr.tableNumber}`
  }

  const documentHeaderData = new DocumentHeaderProps({
    title: title,
    orderId: qr.orderId,
    tableNumber: null,
    date: null,
    contentTableRightVar: null,
    showIdentity: null,
  });

  return (
    <>
      {documentHeader(documentHeaderData)}
      {content(qr)}
      {footer()}
    </>
  );
};

const footer = () => {
  return (
    <>
      <Line character='=' />
      <Text align="center" bold={true}>SCAN QR ini Untuk Memesan Makanan</Text>
      <Br />
      <Cut partial={true} lineFeeds={4} />
    </>
  );
}

const content = (qr) => {
  const url = `${window.location.origin}/order/${qr.orderId}`;
  return (
    <>
      <Br />
      <QRCode align="center" content={url} correction={'H'} cellSize={6} />
    </>
  );
}