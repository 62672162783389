import React from 'react';
import { useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { AuthenticatedFetch, TypeTab, scopesEnum } from '../../../lib';
import { triggerToast } from '../../../utils';
import { AuthContext } from '../../../context/AuthProvider';
import { CircularProgress } from '@mui/material';

export default function ModalBox() {
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      type: undefined,
      customerName: undefined
    }
  });

  async function onSubmit(data) {
    try {
      if (isLoading) return;
      setIsLoading(true);

      if (!decideIfRoleHasAccess(scopesEnum.CREATE_ORDER)) {
        triggerToast('error', 'You are not allowed to create order!');
        return;
      }

      const { type, ...rest } = data;

      const body = {
        ...rest,
        type,
        state: 'UNPLACED'
      };

      const res = await AuthenticatedFetch(`order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (res.status < 400) {
        const dt = await res.json();
        window.open(`/cashier/${dt.id}`, '_blank', 'noopener,noreferrer');

        triggerToast('success', `Order added successfully!`);
        document.querySelector('#modal-cashier').close();
      } else {
        const errMessage = await res.text();

        triggerToast('error', errMessage);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <dialog id="modal-cashier" className="modal">
      <div className="modal-box">
        <MdClose
          className="float-right cursor-pointer sm:w-6 sm:h-6 w-4 h-4"
          onClick={() => {
            document.querySelector('#modal-cashier').close();
          }}
        />
        <h3 className="font-bold text-lg text-center">Buat Pesanan</h3>
        <div className="divider"></div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="form-control w-full">
            <div className="label w-fit">
              <span className="label-text font-bold">Order Type</span>
            </div>
            <div className="grid grid-cols-3  w-full flex flex-row justify-center gap-2 items-center">
              {TypeTab.filter((type) => { return type.value != 'ALL' }).map((type) => (
                <button
                  key={type.label}
                  style={{ height: '4rem' }}
                  className="btn btn-outline border border-gray-400 hover:bg-gray-200/60 hover:border-gray-200 hover:text-black rounded-xl p-4 hover font-medium cursor-pointer"
                  onClick={async () => {
                    setValue('type', type.value);
                  }}>
                  <p>{type.label}</p>
                </button>
              ))}
            </div>
            {/* <select
              defaultValue={'Please select the order type'}
              className={`dropdown input input-bordered w-full font-semibold ${watch('type') ? '' : ''
                }`}
              {...register('type', {
                required: true
              })}>
              <option value="" hidden>
                Please select order type
              </option>
              {TypeTab.map((orderType) => {
                if (orderType.value == 'ALL') return;
                return (
                  <option key={`order-type-${orderType.label}`} value={orderType.value}>
                    {orderType.label}
                  </option>
                );
              })}
            </select> */}
          </label>
          <div className="label">
            <span className="label-text-alt text-red-500 font-semibold">
              {' '}
              {errors.type && 'Order type is required!'}
            </span>
          </div>
          <div className="modal-action">
            {/* <button
              disabled={isLoading}
              className="btn button-with-loading"
              type="submit">
              {isLoading && <CircularProgress />}
              Tambah
            </button> */}
          </div>
        </form>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button></button>
      </form>
    </dialog>
  );
}
