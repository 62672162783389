import React, { useContext } from 'react';
import { MenuContext, UpdateMenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { triggerToast } from '../../utils/toast';
import { AuthContext } from '../../context/AuthProvider';

export default function AddonDeleteModal() {
  const { addons, setAddons } = useContext(MenuContext);
  const { addon } = useContext(UpdateMenuContext);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  return (
    <dialog id="addon_delete_modal" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Delete {addon?.name}</h3>
        <p>Are you sure you want to delete {addon?.name}?</p>
        <div className="modal-action">
          <button
            className="btn btn-primary"
            onClick={() => document.getElementById('addon_delete_modal').close()}>
            Cancel
          </button>
          <button
            className="btn btn-error text-white"
            onClick={async () => {
              if (!decideIfRoleHasAccess(scopesEnum.DELETE_ADDONS)) {
                triggerToast('error', 'You are not allowed to delete addon!');
                return;
              }
              const res = await AuthenticatedFetch(`addons/${addon.id}`, {
                method: 'DELETE'
              });
              if (!res.ok) {
                const data = await res.text();
                triggerToast("error", data);
                return;
              }
              const data = await res.json();
              setAddons(
                addons.map((a) => {
                  if (a.id === addon.id) {
                    return data;
                  }
                  return a;
                })
              );
              document.getElementById('addon_delete_modal').close();
            }}>
            Delete
          </button>
        </div>
      </div>
    </dialog>
  );
}
