import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ACLContext } from '../../../context/ACLProvider';
import { triggerToast } from '../../../utils';
import { AuthenticatedFetch } from '../../../lib';
import { AuthContext } from '../../../context/AuthProvider';

export default function UserForm() {
  const { roles, setUsers, userToBeUpdated, setUserToBeUpdated, setIsDeleteModalOpen } =
    React.useContext(ACLContext);
  const { jwtScopes } = React.useContext(AuthContext);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      confirmPassword: '',
      role: ''
    }
  });

  useEffect(() => {
    if (!userToBeUpdated) {
      reset({
        username: '',
        password: '',
        confirmPassword: '',
        role: ''
      });
      return;
    }

    reset({
      username: userToBeUpdated.username,
      role: userToBeUpdated.roles[0].id
    });
  }, [userToBeUpdated]);

  async function onSubmit(data) {
    const { username, password, role } = data;

    const roleArray = [
      {
        roleId: role
      }
    ];

    const res = await AuthenticatedFetch(
      `users${userToBeUpdated ? `/${userToBeUpdated.id}` : ''}`,
      {
        method: userToBeUpdated ? 'PATCH' : 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password,
          roles: roleArray
        })
      }
    );

    if (res.status < 400) {
      const result = await res.json();
      if (userToBeUpdated) {
        setUsers((prev) => {
          return prev.map((user) =>
            user.id == userToBeUpdated.id ? { ...user, ...result } : user
          );
        });
        setUserToBeUpdated(result);
      } else {
        setUsers((prev) => [...prev, result]);
      }
      triggerToast('success', `User ${userToBeUpdated ? 'updated' : 'created'} successfully!`);
    } else {
      const errMessage = await res.text();
      triggerToast('error', errMessage);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="form-control w-full">
        <div className="label w-fit">
          <span className="label-text font-bold">Username</span>
        </div>
        <input
          type="text"
          placeholder="Enter Username"
          className="input input-bordered w-full font-semibold"
          {...register('username', {
            required: true
          })}
        />
      </label>
      <div className="label">
        <span className="label-text-alt text-red-500 font-semibold">
          {' '}
          {errors.username && 'Username is required!'}
        </span>
      </div>
      <label className="form-control w-full">
        <div className="label w-fit">
          <span className="label-text font-bold">Role</span>
        </div>
        <select
          className={`dropdown input input-bordered w-full font-semibold ${
            watch('role') ? '' : 'text-gray-400'
          }`}
          {...register('role', {
            valueAsNumber: true,
            required: true
          })}>
          <option value="" hidden>
            Please select a role
          </option>
          {roles.map((role) => (
            <option key={`role-${role.id}`} value={role.id}>
              {role.name}
            </option>
          ))}
        </select>
      </label>
      <div className="label">
        <span className="label-text-alt text-red-500 font-semibold">
          {' '}
          {errors.role && 'Role is required!'}
        </span>
      </div>
      {!userToBeUpdated && (
        <>
          <label className="form-control w-full">
            <div className="label w-fit">
              <span className="label-text font-bold">Password</span>
            </div>
            <input
              type="password"
              placeholder="Enter Password"
              className="input input-bordered w-full font-semibold"
              {...register('password', {
                required: true
              })}
            />
          </label>
          <div className="label">
            <span className="label-text-alt text-red-500 font-semibold">
              {' '}
              {errors.password && 'Password is required!'}
            </span>
          </div>
          <label className="form-control w-full">
            <div className="label w-fit">
              <span className="label-text font-bold">Confirm Password</span>
            </div>
            <input
              type="password"
              placeholder="Enter Password"
              className="input input-bordered w-full font-semibold"
              {...register('confirmPassword', {
                required: true,
                validate: {
                  isSame: (value) => {
                    if (value !== watch('password')) {
                      return 'Password does not match';
                    }
                  }
                }
              })}
            />
          </label>
          <div className="label">
            <span className="label-text-alt text-red-500 font-semibold">
              {' '}
              {errors.confirmPassword && errors.confirmPassword.message}
            </span>
          </div>
        </>
      )}

      {!jwtScopes.includes('Access Control List') && (
        <div className="flex flex-row justify-between items-center">
          {userToBeUpdated && (
            <button
              className="btn btn-info float-right text-white font-semibold"
              type="button"
              onClick={() => {
                setIsDeleteModalOpen(false);
                document.getElementById('modal-acl').showModal();
              }}>
              Change Passowrd
            </button>
          )}
          <div className="flex flex-row items-end float-right gap-2">
            <button
              className="btn float-right text-black font-semibold w-full max-w-20"
              type="submit">
              {userToBeUpdated ? 'Update' : 'Add'}
            </button>
            {userToBeUpdated && (
              <button
                className="btn btn-error w-full max-w-20 float-right text-white font-semibold"
                type="button"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  document.getElementById('modal-acl').showModal();
                }}>
                Delete
              </button>
            )}
          </div>
        </div>
      )}
    </form>
  );
}
