import React, { useContext, useState } from 'react';
import { MenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { handleInputChange } from '../../utils/handleInputChange';
import { AuthContext } from '../../context/AuthProvider';
import { triggerToast } from '../../utils/toast';
import { FaTimes } from "react-icons/fa";

export default function CategoryCreateModal() {
  const { categories, setCategories } = useContext(MenuContext);
  const [category, setCategory] = useState({
    name: '',
    rank: 0
  });
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  async function resetForm() {
    setCategory({
      name: '',
      rank: 0
    });
  }

  return (
    <dialog id="category_modal" className="modal">
      <div className="modal-box">
        <div className="modal-action mt-0">
          <form method="dialog">
            <button className="btn" onClick={() => { resetForm() }}><FaTimes /></button>
          </form>
        </div>
        <h3 className="font-bold text-lg">Create Category</h3>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!decideIfRoleHasAccess(scopesEnum.CREATE_CATEGORY)) {
              triggerToast('error', 'You are not allowed to create category!');
              return;
            }
            
            const res = await AuthenticatedFetch(`categories`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                ...category,
                rank: parseInt(category.rank)
              })
            });
            if (!res.ok) {
              const data = await res.text();
              triggerToast('error', data);
              return;
            }
            const data = await res.json();
            setCategories([...categories, data]);
            document.getElementById('category_modal').close();
          }}
          className="form-control m-3">
          <label className="label-text" htmlFor="name">
            Name
          </label>
          <input
            value={category.name}
            onChange={(e) => handleInputChange(e, setCategory)}
            className="mb-10 input input-bordered"
            type="text"
            name="name"
            id="name"
          />
          <label className="label-text" htmlFor="rank">
            Rank
          </label>
          <input
            value={category.rank}
            onChange={(e) => handleInputChange(e, setCategory)}
            className="mb-10 input input-bordered"
            type="number"
            name="rank"
            id="rank"
          />
          <button className="btn btn-primary">Create</button>
        </form>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn" onClick={() => { resetForm() }}>Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
}
