import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { AuthenticatedFetch } from '../../lib';
import { triggerToast } from '../../utils';

export default function OrderBill() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const IDR = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR'
  });

  const [order, setOrder] = useState({});
  const [menus, setMenus] = useState([]);

  function getMenuName(menuId) {
    const menu = menus.find((menu) => menu.id == menuId);

    return menu.name;
  }

  function getAddonOptionName(addonOptionId) {
    const addonOption = menus.reduce((result, menu) => {
      const foundOption = menu.addons.reduce((res, addon) => {
        return res || addon.options.find((option) => option.id === addonOptionId);
      }, null);

      return result || foundOption;
    }, null);

    return addonOption?.name;
  }

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/order/${uid}`)
      if (!res.ok) {
        triggerToast('error', await res.text());
        navigate('/order');
      }

      const [orderResponse, menusResponse] = await Promise.all([
        AuthenticatedFetch(`order/${uid}`),
        AuthenticatedFetch(`menus`)
      ]);

      const [orderData, menusData] = await Promise.all([
        orderResponse.json(),
        menusResponse.json()
      ]);

      setOrder(orderData);
      setMenus(menusData);
    }

    fetchData();
  }, []);

  if (Object.keys(order).length === 0) {
    return (
      <div className="w-full overflow-x-hidden bg-gray-500 relative flex justify-center h-full font-inter">
        <div className="min-h-screen relative bg-white max-w-[425px] h-full flex flex-col items-center p-5">
          <div style={{ width: '100vw' }}></div>
        </div>
      </div>
    )
  }

  const combinedOrderItems = order.orderItems.reduce((acc, orderItem) => {
    const existingItem = acc.find(item => {
      return (
        item.menuId === orderItem.menuId &&
        item.type === orderItem.type &&
        item.notes === orderItem.notes &&
        JSON.stringify(item.OrderAddon) === JSON.stringify(orderItem.OrderAddon)
      );
    });

    if (existingItem) {
      existingItem.quantity += orderItem.quantity;
      existingItem.price += orderItem.price;
    } else {
      acc.push(orderItem);
    }

    return acc.filter((item) => item.quantity > 0);
  }, []);

  return (
    <div className="w-full overflow-x-hidden bg-gray-500 relative flex justify-center h-full font-inter">
      <div className="min-h-screen relative bg-white max-w-[425px] h-full flex flex-col items-center p-5">
        {order && order.id && menus && menus.length > 0 && (
          <>
            <div className="relative w-full mb-5">
              <FaChevronLeft
                onClick={() => {
                  navigate(`/order/${uid}/menu`);
                }}
                className="absolute cursor-pointer ml-2 mt-1 left-0 text-2xl text-[#6888B2]"
              />
              <h1 className="text-[20px] font-bold text-center">Bill</h1>
              <h1 className="text-[20px] font-bold text-center">
                {order.customerName} - Table {order.tableNumber}
              </h1>
              <table className="table">
                <thead>
                  <tr>
                    <th>Menu</th>
                    <th className='text-center'>Qty</th>
                    <th className='text-center'>Harga</th>
                  </tr>
                </thead>
                <tbody>
                  {combinedOrderItems.map((orderItem, i) => (
                    <tr key={i}>
                      <td>
                        <h1 className="text-sm text-wrap">{getMenuName(orderItem.menuId)}</h1>
                        {orderItem.type == "TAKE_AWAY" &&
                          <p className="text-xs text-wrap">
                            {orderItem.type}
                          </p>
                        }
                        <p className="text-xs text-wrap">
                          {orderItem.OrderAddon.map((addon) =>
                            getAddonOptionName(addon.optionId)
                          ).join(', ')}
                        </p>
                      </td>
                      <td className="text-center">{orderItem.quantity}</td>
                      <td className="text-center">{IDR.format(orderItem.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-between font-bold mt-5">
                <p>Total</p>
                <p>
                  {IDR.format(
                    order.orderItems.reduce((acc, orderItem) => acc + orderItem.price, 0)
                  )}
                </p>
              </div>
              <p className="mt-10 text-center font-bold">{`Hi ${order.customerName}, jika ingin melakukan pembayaran silakan tunjukan halaman ini ke kasir`}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
