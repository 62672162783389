import React, { useContext, useEffect, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { MenuContext, UpdateMenuContext } from '../../contexts/MenuContext';
import { AuthenticatedFetch, scopesEnum } from '../../lib';
import { handleInputChange } from '../../utils/handleInputChange';
import { triggerToast } from '../../utils/toast';
import { AuthContext } from '../../context/AuthProvider';
import { FaTimes } from "react-icons/fa";

export default function MenuUpdateModal() {
  const { menu, setMenu } = useContext(UpdateMenuContext);
  const { menus, setMenus, categories, addons, printers } = useContext(MenuContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { decideIfRoleHasAccess } = React.useContext(AuthContext);

  useEffect(() => {
    setSelectedCategory(menu?.category?.id || null);
  }, [menu]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    console.log(file);
    setMenu({
      ...menu,
      image: file,
    });
  };

  return (
    <dialog id="menu_update_modal" className="modal">
      <div className="modal-box">
        <div className="modal-action mt-0">
          <form method="dialog">
            <button className="btn"><FaTimes /></button>
          </form>
        </div>
        <h3 className="font-bold text-lg">Update {menu.name}</h3>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            if (!decideIfRoleHasAccess(scopesEnum.UPDATE_MENU)) {
              triggerToast('error', 'You are not allowed to update menu!');
              return;
            }

            const { id, category, addons, ...rest } = menu;
            const menuFormData = new FormData();
            menuFormData.append("kitchenId", menu.kitchenId);
            menuFormData.append("image", menu.image);
            menuFormData.append("name", menu.name);
            menuFormData.append("info", menu.info);
            menuFormData.append("available", menu.available);
            menuFormData.append("offlinePrice", menu.offlinePrice || '');
            menuFormData.append("onlinePrice", menu.onlinePrice || '');
            menuFormData.append("rank", menu.rank || '');

            if (menu.categoryId) {
              menuFormData.append("categoryId", menu.categoryId);
            } else {
              menuFormData.append("categoryId", null);
            }

            menuFormData.append("deleted", false);
            menuFormData.append("deletedAt", null);
            menuFormData.append("default", menu.default || false);

            const res = await AuthenticatedFetch(`menus/${menu.id}`, {
              method: 'PUT',
              headers: {
                "enctype": "multipart/form-data"
              },
              body: menuFormData
            });
            if (!res.ok) {
              const data = await res.text();
              triggerToast('error', data);
              return;
            }
            const data = await res.json();
            setMenus(
              menus.map((menu) => {
                if (menu.id === data.id) {
                  return data;
                }
                return menu;
              })
            );
            const file = document.getElementById(`update_image`);
            file.value = file.defaultValue;
            document.getElementById(`menu_update_modal`).close();
          }}
          className="form-control m-3">
          <label className="label-text" htmlFor="name">
            Name
          </label>
          <input
            value={menu.name}
            onChange={(e) => handleInputChange(e, setMenu)}
            className="mb-3 input input-bordered"
            type="text"
            name="name"
            id="name"
          />
          <label className="label-text" htmlFor="update_image">
            Image
          </label>
          <input
            onChange={handleImageChange}
            className="file-input file-input-bordered"
            type="file"
            name="image"
            id="update_image"
          />
          <label className="label-text" htmlFor="info">
            Description
          </label>
          <textarea
            value={menu.info}
            onChange={(e) => handleInputChange(e, setMenu)}
            className="mb-3 h-20 textarea textarea-bordered"
            type="text"
            name="info"
            id="info"
          />
          <label className="label-text" htmlFor="offlinePrice">
            Price (Offline)
          </label>
          <input
            value={menu.offlinePrice}
            onChange={(e) => handleInputChange(e, setMenu)}
            className="mb-3 input input-bordered"
            type="number"
            name="offlinePrice"
            id="offlinePrice"
          />
          <label className="label-text" htmlFor="onlinePrice">
            Price (Online)
          </label>
          <input
            value={menu.onlinePrice}
            onChange={(e) => handleInputChange(e, setMenu)}
            className="mb-5 input input-bordered"
            type="number"
            name="onlinePrice"
            id="onlinePrice"
          />

          <label className="label-text" htmlFor="rank">
            Rank
          </label>
          <input
            value={menu.rank}
            onChange={(e) => handleInputChange(e, setMenu)}
            className="mb-5 input input-bordered"
            type="number"
            name="rank"
            id="rank"
          />

          <label className="label-text" htmlFor="kitchenId">
            Designated Printer Location
          </label>
          <select
            value={menu.kitchenId === null ? "" : menu.kitchenId}
            className="select select-bordered mb-3"
            name="kitchenId"
            id="kitchenId"
            onChange={(e) => {
              const kitchenId = parseInt(e.target.value);
              if (!kitchenId) return;
              setMenu({
                ...menu,
                kitchenId
              });
            }}>
            <option disabled selected hidden value={null}>
              Select a Printer Location
            </option>
            {printers.map((printer) => (
              <option key={printer.id} value={printer.id}>
                {printer.name}
              </option>
            ))}
          </select>
          <label htmlFor="category" className="label-text">
            Category
          </label>
          <select
            value={menu.categoryId === null ? "" : menu.categoryId}
            name="category"
            id="category"
            onChange={async (e) => {
              setMenu({ ...menu, categoryId: +e.target.value });
              setSelectedCategory(+e.target.value);
            }}
            className="select select-bordered mb-5">
            <option disabled selected hidden value="">
              Select a Category
            </option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <label htmlFor="addons" className="label-text">
            Add-ons
          </label>
          <select
            onChange={async (e) => {
              const addon = addons.find((addon) => addon.id === parseInt(e.target.value));
              if (!addon) return;
              setMenu({
                ...menu,
                addons: [...menu.addons, addon]
              });
              e.target.value = '';
              const res = await AuthenticatedFetch(`addons/connect/${addon.id}/${menu.id}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              if (!res.ok) {
                const data = await res.text();
                triggerToast('error', data);
                return;
              }
            }}
            name="addons"
            id="addons"
            className="select select-bordered mb-5">
            <option disabled selected hidden value="">
              Select Addons
            </option>
            {addons.filter(addon => !addon.deleted).map((addon) => (
              <option key={addon.id} value={addon.id}>
                {addon.name}
              </option>
            ))}
          </select>
          <label className="label-text" htmlFor="available">
            Selected Add-ons
          </label>
          {menu?.addons?.length === 0 && (
            <p className="text-sm text-gray-500 mb-10">No Add-ons selected</p>
          )}
          <div className="flex flex-wrap gap-2 mb-5">
            {menu?.addons?.map((addon) => (
              <div key={addon.id} className="flex items-center gap-2 btn btn-neutral">
                <p className="text-sm">{addon.name}</p>
                <FaTrashAlt
                  className="cursor-pointer"
                  onClick={async () => {
                    const res = await AuthenticatedFetch(
                      `addons/disconnect/${addon.id}/${menu.id}`,
                      {
                        method: 'DELETE',
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      }
                    );
                    if (!res.ok) {
                      const data = await res.text();
                      triggerToast('error', data);
                      return;
                    }
                    setMenu({
                      ...menu,
                      addons: menu.addons.filter((a) => a.id !== addon.id)
                    });
                  }}
                />
              </div>
            ))}
          </div>

          <button className="btn btn-primary">Update</button>
        </form>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn">Close</button>
          </form>
        </div>
      </div>
    </dialog>
  );
}
